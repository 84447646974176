import axios from 'axios';
import config from '../config';
const user = JSON.parse(localStorage.getItem('currentUser'));

export const knowTheWord = (levelName, wordId) => {
  const right = true;
  const payload = getPayload(right, levelName, wordId);

  axios
    .post(
      `${config.REACT_APP_NODE_API_URL}/api/algo/post-leitner-algorithm-data`,
      payload
    )
    .then(res => console.log(res))
    .catch(err => console.log(err));
};

export const dontKnowTheWord = (levelName, wordId) => {
  let right = false;
  // eslint-disable-next-line no-unused-vars
  const payload = getPayload((right = false), levelName, wordId);
  axios
    .post(
      `${config.REACT_APP_NODE_API_URL}/api/algo/post-leitner-algorithm-data`,
      payload
    )
    .then(res => console.log(res))
    .catch(err => console.log(err));
};

export const initializeLeitner = (levelName, vocalList) => {
  const payload = {
    user: user._id,
    languageCode: localStorage.getItem('languageData').split('-')[1],
    data: {
      levelName,
      master: vocalList,
      learning: [],
      reviewing: [],
      mastered: [],
    },
  };

  axios
    .post(
      `${config.REACT_APP_NODE_API_URL}/api/algo/post-initial-leitner-data`,
      payload
    )
    .then(res => console.log(res.data))
    .catch(err => console.log(err));
};

export const getPayload = (right, levelName, wordId) => {
  if (right) {
    return {
      _id: user._id,
      languageCode: localStorage.getItem('languageData').split('-')[1],
      data: {
        levelName,
        status: 1,
        wordId,
      },
    };
  }
  return {
    _id: user._id,
    languageCode: localStorage.getItem('languageData').split('-')[1],
    data: {
      levelName,
      status: 0,
      wordId,
    },
  };
};
