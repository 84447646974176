import axios from 'axios';
import config from '../config';
const user = JSON.parse(localStorage.getItem('currentUser'));

// eslint-disable-next-line camelcase
export const postResultData = (score, quizData, question_level) => {
  const payload = {
    _id: user._id,
    data: {
      question_level,
      score,
      quizData,
    },
  };

  axios
    .post(
      `${config.REACT_APP_NODE_API_URL}/api/quiz/post-quiz-result-data`,
      payload
    )
    .then(res => console.log(res.data))
    .catch(err => console.log(err));
};
