/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import config from '../../config';
import {Link} from 'react-router-dom';

const Analytics = ({match}) => {
  const [languageData, setLanguageData] = useState({});
  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    axios
      .post(
        `${config.REACT_APP_NODE_API_URL}/api/admin/analytics/sessions`,
        {
          session: match.params.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(x => {
        console.log(x.data.data);
        setLanguageData(x.data.data);
      })
      .catch(x => console.log(x));
  }, [match.params.id]);
  return (
    <div
      style={{
        overflow: 'flex',
        fontSize: '1.3rem',
        resize: 'contain',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        paddingTop: '10%',
        height: '100%',
        width: 'fit-content',
      }}
    >
      <Link
        to="/admin/sessions"
        className="btn btn-sm"
        style={{marginBottom: '10px'}}
      >
        Back
      </Link>
      <br />
      <hr />
      <br />
      <h1 style={{textAlign: 'center'}}>Session Analytics: </h1>
      <br />
      <hr />
      <br />
      <div style={{textAlign: 'center'}}>
        <h3>{`Users Associated: ${languageData?.user?.length}`}</h3>
        <h3>{`Unique SessionID: ${languageData?.sessionID}`}</h3>
        <h3>{`Created AT: ${new Date(languageData?.createdAt)
          .toLocaleDateString('en-us', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })
          .replace(',', '')} ${new Date(
          languageData.createdAt
        ).toLocaleTimeString('en-US')}`}</h3>
        <h3>{`Session Expiry: ${new Date(languageData?.sessionExpiry)
          .toLocaleDateString('en-us', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })
          .replace(',', '')} ${new Date(
          languageData?.sessionExpiry
        ).toLocaleTimeString('en-US')}`}</h3>
        <h3>
          {`Expired: ${(
            new Date(languageData?.sessionExpiry).getTime() < Date.now()
          ).toString()}`}
        </h3>
      </div>
      <br />
      <hr />
      <br />
      <h2 style={{textAlign: 'center'}}>Users Associated:</h2>
      <br />
      <hr />
      <br />
      <table className="managewords__table" style={{width: '100%'}}>
        <thead>
          <tr className="managewords__tr">
            <th className="managewords__th">#</th>
            <th className="managewords__th">Name</th>
            <th className="managewords__th">Email</th>
            <th className="managewords__th">User ID</th>
          </tr>
        </thead>
        <tbody>
          {languageData?.user?.map(
            (x, i) =>
              (
                <tr className="managewords__tr" key={i}>
                  <td className="managewords__td">{i + 1}</td>
                  <td className="managewords__td">{x.name}</td>
                  <td className="managewords__td">{x.email}</td>
                  <td className="managewords__td">{x._id}</td>
                </tr>
              ) || (
                <tr key={i}>
                  <td colSpan={4} style={{textAlign: 'center'}}>
                    No users associated...
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
      <br />
      <hr />
      <br />
      <h2 style={{textAlign: 'center'}}>Paths Accessed:</h2>
      <br />
      <hr />
      <br />
      <table className="managewords__table" style={{width: '100%'}}>
        <thead>
          <tr className="managewords__tr">
            <th className="managewords__th">#</th>
            <th className="managewords__th">Path</th>
            <th className="managewords__th">Time</th>
            <th className="managewords__th">Unique Access ID</th>
            <th className="managewords__th">Requesting IP`</th>
            <th className="managewords__th">Request HostName</th>
            <th className="managewords__th">Origin Country</th>
            <th className="managewords__th">Origin TimeZone</th>
            <th className="managewords__th">IP Identifier</th>
            <th className="managewords__th">User Agent</th>
          </tr>
        </thead>
        <tbody>
          {languageData?.sessionRoutes?.map((x, i) => (
            <tr key={i} className="managewords__tr">
              <td className="managewords__td">{i + 1}</td>
              <td className="managewords__td">{decodeURI(x.path)}</td>
              <td className="managewords__td">
                {new Date(x.timeStamp)
                  .toLocaleDateString('en-us', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  })
                  .replace(',', '')}{' '}
                {new Date(x.timeStamp).toLocaleTimeString('en-US')}
              </td>
              <td className="managewords__td">{x._id}</td>
              <td className="managewords__td">
                {x.requestingIp?.ip || 'No Data'}
              </td>
              <td className="managewords__td">
                {x.requestingIp?.hostName || 'No Data'}
              </td>
              <td className="managewords__td">
                {x.requestingIp?.originCountry || 'No Data'}
              </td>
              <td className="managewords__td">
                {x.requestingIp?.originTz || 'No Data'}
              </td>
              <td className="managewords__td">
                {x.requestingIp?._id || 'No Data'}
              </td>
              <td className="managewords__td">{x.requestUA || 'No Data'}</td>
            </tr>
          )) || (
            <tr>
              <td colSpan={10} style={{textAlign: 'center'}}>
                No routes accessed (Yet)...
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Analytics;
