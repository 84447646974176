/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

const EditTF = ({match}) => {
  const [question, setQuestion] = useState('');
  const [right_ans, setRight_ans] = useState(false);
  const [message, setMessage] = useState('');
  useEffect(() => {
    axios
      .get(
        `${config.REACT_APP_NODE_API_URL}/api/quiz/get/t-f-id/${match.params.questionId}`
      )
      .then(res => {
        setQuestion(res.data.data.question);
        setRight_ans(res.data.data.right_ans);
      })
      .catch(err => console.log(err));
  }, [match.params.questionId]);
  const handleSubmit = e => {
    e.preventDefault();
    axios
      .put(
        `${config.REACT_APP_NODE_API_URL}/api/quiz/update/t-f/${match.params.questionId}`,
        {
          question,
          right_ans,
        }
      )
      .then(res => {
        setMessage('Question updated');
      })
      .catch(err => {
        console.log(err);
        setMessage(err.response.data.message);
      });
  };

  return (
    <div
      style={{marginTop: '11.4rem', textAlign: 'center', fontSize: '1.5rem'}}
    >
      <form className="add_question__form" onSubmit={handleSubmit}>
        <div>
          <label className="add_question__form__label">Question: </label>
          <input
            type="text"
            onChange={e => setQuestion(e.target.value)}
            className="add_question__form__input"
            value={question}
          />
        </div>

        <div>
          <input
            type="radio"
            name="right_ans"
            value={true}
            checked={right_ans ? true : false}
            id="true"
            onChange={e => setRight_ans(true)}
          />
          <label htmlFor="true">True</label>

          <input
            type="radio"
            name="right_ans"
            value={false}
            id="false"
            checked={right_ans === false ? true : false}
            onChange={e => setRight_ans(false)}
          />
          <label htmlFor="false">False</label>
          <br />
        </div>
        <br />
        {message && <h2>{message}</h2>}
        <br />
        <div>
          <button className="btn btn-sm" type="submit">
            Submit
          </button>

          <Link
            to="/moderator/manage-quiz"
            className="btn btn-sm"
            style={{
              marginLeft: '0.4rem',
            }}
          >
            Back
          </Link>
        </div>
      </form>
    </div>
  );
};

export default EditTF;
