/* eslint-disable react/prop-types */
import React from 'react';
import {GrClose} from 'react-icons/gr';

/**
 * @author NevetsKuro <stevenfernz96@gmail.com>
 * @function AlertBox
 * @description Alert Dialog box
 * @param {{toggleShowModal: Function, message:string}} toggleShowModal - to toggle modal show/hide value
 * @returns {import('react').ReactElement} -
 */
const AlertBox = ({toggleShowModal, message}) => {
  return (
    <div className="modal-container">
      <div className="backdrop">
        <div className="alert-body-container">
          <div className="close" onClick={() => toggleShowModal()}>
            <GrClose size={20} />
          </div>
          <div>
            <img
              src="https://image.freepik.com/free-vector/tiny-people-examining-operating-system-error-warning-web-page-isolated-flat-illustration_74855-11104.jpg"
              alt=""
              width="240"
              height="160"
            />
          </div>
          <div className="alert-header">Message</div>
          <div className="alert-body">
            <p>{message}</p>
          </div>
          <div className="alert-button" onClick={() => toggleShowModal()}>
            OK!
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertBox;
