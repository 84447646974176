/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, {Component} from 'react';
import {Beforeunload} from 'react-beforeunload';
import axios from 'axios';
import {connect} from 'react-redux';
import config from '../config';
import QuizCard from '../components/QuizCard';
import QuizResult from '../components/QuizResult';

/**
 * @class Quiz
 * @augments Component
 */
class Quiz extends Component {
  /**
   * @class Quiz
   * @param {any} props - Props passed to the component
   */
  constructor(props) {
    super(props);
    this.checkAnswer = this.checkAnswer.bind(this);
    this.calculateTime = this.calculateTime.bind(this);
    this.state = {
      quizQuestions: [],
      currentQuestion: 0,
      userOptions: [],
      score: 0,
      timePerQuestion: 0,
      time: [],
    };
  }

  /**
   * @function componentDidMount
   */
  componentDidMount() {
    const payload = {
      language: this.props.match.params.language,
      question_level: this.props.match.params.question_level,
    };
    console.log(payload);
    axios
      .post(`${config.REACT_APP_NODE_API_URL}/api/quiz/generate`, payload)
      .then(res => {
        this.setState({quizQuestions: res.data});
      })
      .catch(err => console.log(err));
  }

  /**
   * @function checkAnswer
   * @param {any} user_answer - The user's answer
   * @param {any} right_answer - The correct answer
   */
  checkAnswer(user_answer, right_answer) {
    if (user_answer === right_answer) {
      this.setState(prevState => ({score: prevState.score + 1}));
    }
    this.setState(prevState => ({
      currentQuestion: prevState.currentQuestion + 1,
    }));
    if (user_answer === true || user_answer === '') {
      user_answer = 'null';
    }
    this.setState({
      userOptions: [...this.state.userOptions, user_answer],
      time: [...this.state.time, this.state.timePerQuestion],
      timePerQuestion: 0,
    });
  }
  /**
   * @function calculateTime
   * @param {number} time - Time taken
   */
  calculateTime(time) {
    this.setState(prevState => ({
      timePerQuestion: prevState.timePerQuestion + time,
    }));
  }
  /**
   * @function populateQuizCard
   * @param {any} questionData - Question Data
   * @param {any} index - Index
   * @returns {import('react').JSXElementConstructor} JSX BODY
   */
  populateQuizCard(questionData, index) {
    const {question, options, right_ans, _id} = questionData;
    return (
      <Beforeunload onBeforeunload={() => "You'll lose your data!"}>
        <QuizCard
          key={_id}
          index={index}
          checkAnswerFn={this.checkAnswer}
          calTimeFn={this.calculateTime}
          question={question}
          options={options}
          right_ans={right_ans}
          duration={20}
          questionLevel={this.props.match.params.question_level}
          totalQuestions={this.state.quizQuestions.length}
        />
      </Beforeunload>
    );
  }
  /**
   * @function showResult
   * @returns {import('react').JSXElementConstructor} JSX Body
   */
  showResult() {
    return (
      <div style={{marginBottom: '2rem'}}>
        <QuizResult
          questions={this.state.quizQuestions}
          options={this.state.userOptions}
          score={this.state.score}
          questionLevel={this.props.match.params.question_level}
          timeTaken={this.state.time}
          language={this.props.match.params.language}
        />
      </div>
    );
  }
  /**
   * @function render
   * @returns {import('react').JSXElementConstructor} JSX BODY
   */
  render() {
    const {quizQuestions, currentQuestion} = this.state;
    return (
      <div>
        {quizQuestions && currentQuestion < quizQuestions.length
          ? this.populateQuizCard(
              quizQuestions[currentQuestion],
              currentQuestion
            )
          : this.showResult()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;

  return {
    id,
  };
};

export default connect(mapStateToProps, null)(Quiz);
