/* eslint-disable react/prop-types */
import React, {Fragment, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Footer from '../layout/Footer';
import LoggedInFooter from '../layout/LoggedInFooter';
import Navbar from '../layout/Navbar';
import axios from 'axios';
import config from '../config';
import {Redirect} from 'react-router';

const WhyLearningLanguage = props => {
  const {id} = props;
  const [reason, setReason] = useState('');
  const [source, setSource] = useState('');
  const [alert, setAlert] = useState(false);
  const [btnClick, setBtnClick] = useState(false);

  useEffect(() => {
    setSource(localStorage.getItem('source'));
  }, [source]);
  const handleLearningReason = e => {
    e.preventDefault();
    if (reason) {
      setAlert(false);
      axios
        .post(`${config.REACT_APP_NODE_API_URL}/api/user/referrer/${id}`, {
          referrer: source,
          learningReason: reason,
        })
        .then(() => {
          setBtnClick(true);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2000);
    }
  };

  if (btnClick) {
    return <Redirect to="/learn" />;
  }
  return (
    <Fragment>
      <Navbar />
      <section className="why-learning-language">
        {alert ? (
          <h1 className="why-learning-language-alert">
            * Please select a reason for learning a language
          </h1>
        ) : (
          <></>
        )}
        <h1>Why are you learning a language?</h1>
        <div className="why-learning-language-content">
          <div className="why-learning-language-content-card">
            {/* 1 */}
            <div
              onClick={() => setReason('family')}
              className={`${
                reason === 'family'
                  ? 'why-learning-language-content-card-content active-reason'
                  : 'why-learning-language-content-card-content'
              } `}
            >
              <h5>Family &amp; Friends </h5>
            </div>
            <hr />
            {/* 2 */}
            <div
              onClick={() => setReason('job')}
              className={`${
                reason === 'job'
                  ? 'why-learning-language-content-card-content active-reason'
                  : 'why-learning-language-content-card-content'
              } `}
            >
              <h5>Job Opportunities</h5>
            </div>
            <hr />
            {/* 3 */}
            <div
              onClick={() => setReason('school')}
              className={`${
                reason === 'school'
                  ? 'why-learning-language-content-card-content active-reason'
                  : 'why-learning-language-content-card-content'
              } `}
            >
              <h5>School</h5>
            </div>
            <hr />
            {/* 4 */}
            <div
              onClick={() => setReason('brain')}
              className={`${
                reason === 'brain'
                  ? 'why-learning-language-content-card-content active-reason'
                  : 'why-learning-language-content-card-content'
              } `}
            >
              <h5>Brain Training</h5>
            </div>
            <hr />
            {/* 5 */}
            <div
              onClick={() => setReason('travel')}
              className={`${
                reason === 'travel'
                  ? 'why-learning-language-content-card-content active-reason'
                  : 'why-learning-language-content-card-content'
              } `}
            >
              <h5>Travel</h5>
            </div>
            <hr />
            {/* 6 */}
            <div
              onClick={() => setReason('culture')}
              className={`${
                reason === 'culture'
                  ? 'why-learning-language-content-card-content active-reason'
                  : 'why-learning-language-content-card-content'
              } `}
            >
              <h5>Culture</h5>
            </div>
            <hr />
            {/* 7 */}
            <div
              onClick={() => setReason('other')}
              className={`${
                reason === 'other'
                  ? 'why-learning-language-content-card-content active-reason'
                  : 'why-learning-language-content-card-content'
              } `}
            >
              <h5>Other</h5>
            </div>
          </div>
          <button onClick={handleLearningReason}>Continue</button>
        </div>
      </section>
      {id ? <LoggedInFooter /> : <Footer />}
    </Fragment>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;

  return {
    id,
    error: state.auth.authError,
  };
};

export default connect(mapStateToProps)(WhyLearningLanguage);
