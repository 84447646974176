/* eslint-disable react/prop-types */
import {Component} from 'react';
import {createPortal} from 'react-dom';

const modalRoot = document.getElementById('modal');
/**
 * @author NevetsKuro <stevenfernz96@gmail.com>
 * @class Modal
 * @augments Component
 * @description Used to create a modal container to display other components
 * @returns {import('react-dom').ReactPortal} JSX element
 */
class Modal extends Component {
  /**
   * @class Modal
   * @param {{children:import('react').ReactElement}} props - To add a React Element as the modal's child element
   */
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }
  /**
   * @function componentDidMount
   * @description to append an element to modal div
   */
  componentDidMount() {
    modalRoot.appendChild(this.el);
  }
  /**
   * @function componentWillUnmount
   * @description to remove the element attached from modal div
   */
  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }
  /**
   * @function render
   * @returns {import('react-dom').ReactPortal} -
   */
  render() {
    return createPortal(this.props.children, this.el);
  }
}
export default Modal;
