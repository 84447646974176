/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {sendEmailForOtp} from '../../store/actions/authActions';

const ForgotPassword = props => {
  const [email, setEmail] = useState('');
  const [senOtpClicked, setSenOtpClicked] = useState(false);
  const handleEmail = e => {
    setEmail(e.target.value);
  };
  const sendOtpToEmail = e => {
    e.preventDefault();
    setTimeout(() => {
      setSenOtpClicked(true);
    }, 1500);
    props.sendEmailForOtp(email);
  };
  const {id} = props;
  if (id) return <Redirect to="/learn" />;
  return (
    <section className="forgotpassword">
      <div className="forgotpassword__header">
        <h1 className="forgotpassword__header__heading">
          Forgot Your Password?
        </h1>
      </div>
      <div className="forgotpassword__content">
        <form className="forgotpassword__content__form">
          <input
            type="email"
            className="forgotpassword__content__form__email"
            name="email"
            onChange={handleEmail}
            placeholder="Enter your email"
          />
          <button
            className="btn forgotpassword__content__form__button"
            onClick={sendOtpToEmail}
          >
            Send Password Reset Link
          </button>
        </form>
        {senOtpClicked ? (
          <h1 className="forgotpassword__content__message">
            Check the email and update the password by provided URL.
          </h1>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;

  return {
    id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendEmailForOtp: email => dispatch(sendEmailForOtp(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
