import React, {useEffect, useState} from 'react';
import {Redirect, Link} from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

const AddWord = () => {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [, setEnglishInEnglish] = useState('');
  const [hindiInHindi, setHindiInHindi] = useState('');
  const [, setMundariInEnglish] = useState('');
  const [mundariInHindi, setMundariInHindi] = useState('');
  const [, setKurukInEnglish] = useState('');
  const [kurukInHindi, setKurukInHindi] = useState('');
  const [, setSanthaliInEnglish] = useState('');
  const [santhaliInHindi, setSanthaliInHindi] = useState('');
  const [, setHoInEnglish] = useState('');
  const [hoInHindi, setHoInHindi] = useState('');
  const [, setKhariaInEnglish] = useState('');
  const [khariaInHindi, setKhariaInHindi] = useState('');
  const [, setBhiliInEnglish] = useState('');
  const [bhiliInHindi, setBhiliInHindi] = useState('');
  const [, setGondiInEnglish] = useState('');
  const [gondiInHindi, setGondiInHindi] = useState('');
  const [, setTuluInEnglish] = useState('');
  const [tuluInHindi, setTuluInHindi] = useState('');
  const [message, setMessage] = useState('');
  const [isAddWordClicked, setIsAddWordClicked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState('');
  const [modLang] = useState(
    localStorage.getItem('modLang') ? localStorage.getItem('modLang') : null
  );
  const moderator = localStorage.getItem('currentAdmin');
  if (!modLang) {
    setMessage('Something went wrong, Please login again');
  }
  // Get All Categories
  useEffect(() => {
    axios({
      method: 'get',
      url: `${config.REACT_APP_NODE_API_URL}/api/vocab/get-category/${modLang}`,
    })
      .then(res => {
        setCategories(res.data.data.categories.map(ct => ct.categoryName));
      })
      .catch(err => {
        console.log(err);
      });
  }, [modLang]);

  // Set timeout for message
  useEffect(() => {
    setTimeout(() => {
      setMessage(null);
    }, 2000);
  }, [message]);

  const submitAddWord = e => {
    e.preventDefault();
    setIsAddWordClicked(true);
    const regx = /[a-zA-Z]+/g;
    if (modLang === 'mun') {
      if (regx.test(hindiInHindi) || regx.test(mundariInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }
      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/add-word-by-language`,
        data: {
          lang: modLang,
          category: category,
          // englishInEnglish,
          hindiInHindi,
          // mundariInEnglish,
          mundariInHindi,
          moderator,
        },
      })
        .then(res => {
          // console.log(res.data);
          setIsAddWordClicked(false);
          setMessage(res.data.message);
        })
        .catch(err => {
          console.log(err);
        });
    } else if (modLang === 'kur') {
      if (regx.test(hindiInHindi) || regx.test(kurukInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }

      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/add-word-by-language`,
        data: {
          lang: modLang,
          category: category,
          // englishInEnglish,
          hindiInHindi,
          // kurukInEnglish,
          kurukInHindi,
          moderator,
        },
      })
        .then(res => {
          // console.log(res.data);
          setIsAddWordClicked(false);
          setMessage(res.data.message);
        })
        .catch(err => {
          console.log(err);
        });
    } else if (modLang === 'san') {
      if (regx.test(hindiInHindi) || regx.test(santhaliInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }

      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/add-word-by-language`,
        data: {
          lang: modLang,
          category: category,
          // englishInEnglish,
          hindiInHindi,
          // santhaliInEnglish,
          santhaliInHindi,
          moderator,
        },
      })
        .then(res => {
          // console.log(res.data);
          setIsAddWordClicked(false);
          setMessage(res.data.message);
        })
        .catch(err => {
          console.log(err);
        });
    } else if (modLang === 'hoc') {
      if (regx.test(hindiInHindi) || regx.test(hoInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }

      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/add-word-by-language`,
        data: {
          lang: modLang,
          category: category,
          // englishInEnglish,
          hindiInHindi,
          // santhaliInEnglish,
          hoInHindi,
          moderator,
        },
      })
        .then(res => {
          // console.log(res.data);
          setIsAddWordClicked(false);
          setMessage(res.data.message);
        })
        .catch(err => {
          console.log(err);
        });
    } else if (modLang === 'khr') {
      if (regx.test(hindiInHindi) || regx.test(khariaInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }

      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/add-word-by-language`,
        data: {
          lang: modLang,
          category: category,
          // englishInEnglish,
          hindiInHindi,
          // santhaliInEnglish,
          khariaInHindi,
          moderator,
        },
      })
        .then(res => {
          // console.log(res.data);
          setIsAddWordClicked(false);
          setMessage(res.data.message);
        })
        .catch(err => {
          console.log(err);
        });
    } else if (modLang === 'bhb') {
      if (regx.test(hindiInHindi) || regx.test(bhiliInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }

      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/add-word-by-language`,
        data: {
          lang: modLang,
          category: category,
          // englishInEnglish,
          hindiInHindi,
          // santhaliInEnglish,
          bhiliInHindi,
          moderator,
        },
      })
        .then(res => {
          // console.log(res.data);
          setIsAddWordClicked(false);
          setMessage(res.data.message);
        })
        .catch(err => {
          console.log(err);
        });
    } else if (modLang === 'gon') {
      if (regx.test(hindiInHindi) || regx.test(gondiInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }

      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/add-word-by-language`,
        data: {
          lang: modLang,
          category: category,
          // englishInEnglish,
          hindiInHindi,
          // santhaliInEnglish,
          gondiInHindi,
          moderator,
        },
      })
        .then(res => {
          // console.log(res.data);
          setIsAddWordClicked(false);
          setMessage(res.data.message);
        })
        .catch(err => {
          console.log(err);
        });
    } else if (modLang === 'tcy') {
      if (regx.test(hindiInHindi) || regx.test(tuluInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }

      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/add-word-by-language`,
        data: {
          lang: modLang,
          category: category,
          // englishInEnglish,
          hindiInHindi,
          // santhaliInEnglish,
          tuluInHindi,
          moderator,
        },
      })
        .then(res => {
          // console.log(res.data);
          setIsAddWordClicked(false);
          setMessage(res.data.message);
        })
        .catch(err => {
          console.log(err);
        });
    }

    setEnglishInEnglish('');
    setHindiInHindi('');
    setMundariInHindi('');
    setMundariInEnglish('');
    setSanthaliInEnglish('');
    setSanthaliInHindi('');
    setKurukInEnglish('');
    setKurukInHindi('');
    setHoInHindi('');
    setKhariaInHindi('');
    setBhiliInHindi('');
    setGondiInHindi('');
    setTuluInHindi('');
    console.log(buttonClicked);
    if (buttonClicked === 'add') {
      setCategory('Choose a category');
    }
  };

  const handleInput = e => {
    const {name, value} = e.currentTarget;
    switch (name) {
      case 'englishInEnglish':
        setEnglishInEnglish(value);
        break;
      case 'hindiInHindi':
        setHindiInHindi(value);
        break;
      case 'mundariInEnglish':
        setMundariInEnglish(value);
        break;
      case 'mundariInHindi':
        setMundariInHindi(value);
        break;
      case 'kurukInEnglish':
        setKurukInEnglish(value);
        break;
      case 'kurukInHindi':
        setKurukInHindi(value);
        break;
      case 'santhaliInEnglish':
        setSanthaliInEnglish(value);
        break;
      case 'santhaliInHindi':
        setSanthaliInHindi(value);
        break;
      case 'hoInEnglish':
        setHoInEnglish(value);
        break;
      case 'hoInHindi':
        setHoInHindi(value);
        break;
      case 'khariaInEnglish':
        setKhariaInEnglish(value);
        break;
      case 'khariaInHindi':
        setKhariaInHindi(value);
        break;
      case 'bhiliInEnglish':
        setBhiliInEnglish(value);
        break;
      case 'bhiliInHindi':
        setBhiliInHindi(value);
        break;
      case 'gondiInEnglish':
        setGondiInEnglish(value);
        break;
      case 'gondiInHindi':
        setGondiInHindi(value);
        break;
      case 'tuluInEnglish':
        setTuluInEnglish(value);
        break;
      case 'tuluInHindi':
        setTuluInHindi(value);
        break;
      default:
        break;
    }
  };

  // console.log(message);

  if (!moderator) return <Redirect to="/moderator" />;

  const language =
    modLang === 'mun'
      ? ' Mundari'
      : modLang === 'kur'
      ? ' Kuruk'
      : modLang === 'san'
      ? ' Santhali'
      : modLang === 'hoc'
      ? ' Ho'
      : modLang === 'khr'
      ? ' Kharia'
      : modLang === 'bhb'
      ? ' Bhili'
      : modLang === 'gon'
      ? ' Gondi'
      : modLang === 'tcy'
      ? ' Tulu'
      : modLang;

  return (
    <section className="editwords">
      <div className="editwords__header">
        <h1 className="editwords__header__heading heading-primary--main u-center-text">
          Add Word
        </h1>
        <h2>
          Language:
          {language}
        </h2>
      </div>
      <div className="editwords__content">
        <form className="editwords__content__form" onSubmit={submitAddWord}>
          <select
            value={category}
            className="modauth__content__grid__form__select"
            id="modLanguage"
            onChange={e => setCategory(e.target.value)}
          >
            <option
              value={null}
              className="modauth__content__grid__form__select__option"
            >
              Choose a category
            </option>
            {categories.map((cat, key) => {
              return (
                <option
                  value={cat}
                  className="modauth__content__grid__form__select__option"
                  key={key}
                >
                  {cat}
                </option>
              );
            })}
          </select>
          {/* <label className="editwords__content__form__label">English</label>
          <input
            type="text"
            className="editwords__content__form__input"
            name="englishInEnglish"
            onChange={handleInput}
            value={englishInEnglish || ""}
            required={true}
          /> */}

          <label className="editwords__content__form__label">हिंदी</label>
          <input
            type="text"
            className="editwords__content__form__input"
            name="hindiInHindi"
            onChange={handleInput}
            value={hindiInHindi || ''}
            required={true}
          />

          {modLang === 'mun' ? (
            <>
              {/* <label className="editwords__content__form__label">Mundari</label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="mundariInEnglish"
                onChange={handleInput}
                value={mundariInEnglish || ""}
                required={true}
              /> */}

              <label className="editwords__content__form__label">मुंडारी</label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="mundariInHindi"
                onChange={handleInput}
                value={mundariInHindi || ''}
                required={true}
              />
            </>
          ) : (
            ''
          )}

          {modLang === 'kur' ? (
            <>
              {/* <label className="editwords__content__form__label">
                Kuruk meaning in English
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="kurukInEnglish"
                onChange={handleInput}
                value={kurukInEnglish || ""}
              /> */}

              <label className="editwords__content__form__label">
                Kuruk meaning in Hindi
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="kurukInHindi"
                onChange={handleInput}
                value={kurukInHindi || ''}
              />
            </>
          ) : (
            ''
          )}

          {modLang === 'san' ? (
            <>
              {/* <label className="editwords__content__form__label">
                Santhali meaning in English
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="santhaliInEnglish"
                onChange={handleInput}
                value={santhaliInEnglish || ""}
              /> */}

              <label className="editwords__content__form__label">
                Santhali meaning in Hindi
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="santhaliInHindi"
                onChange={handleInput}
                value={santhaliInHindi || ''}
              />
            </>
          ) : (
            ''
          )}

          {modLang === 'hoc' ? (
            <>
              {/* <label className="editwords__content__form__label">
                Santhali meaning in English
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="santhaliInEnglish"
                onChange={handleInput}
                value={santhaliInEnglish || ""}
              /> */}

              <label className="editwords__content__form__label">
                Ho meaning in Hindi
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="hoInHindi"
                onChange={handleInput}
                value={hoInHindi || ''}
              />
            </>
          ) : (
            ''
          )}

          {modLang === 'khr' ? (
            <>
              {/* <label className="editwords__content__form__label">
                Santhali meaning in English
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="santhaliInEnglish"
                onChange={handleInput}
                value={santhaliInEnglish || ""}
              /> */}

              <label className="editwords__content__form__label">
                Kharia meaning in Hindi
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="khariaInHindi"
                onChange={handleInput}
                value={khariaInHindi || ''}
              />
            </>
          ) : (
            ''
          )}

          {modLang === 'bhb' ? (
            <>
              {/* <label className="editwords__content__form__label">
                Santhali meaning in English
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="santhaliInEnglish"
                onChange={handleInput}
                value={santhaliInEnglish || ""}
              /> */}

              <label className="editwords__content__form__label">
                Bhili meaning in Hindi
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="bhiliInHindi"
                onChange={handleInput}
                value={bhiliInHindi || ''}
              />
            </>
          ) : (
            ''
          )}

          {modLang === 'gon' ? (
            <>
              {/* <label className="editwords__content__form__label">
                Santhali meaning in English
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="santhaliInEnglish"
                onChange={handleInput}
                value={santhaliInEnglish || ""}
              /> */}

              <label className="editwords__content__form__label">
                Gondi meaning in Hindi
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="gondiInHindi"
                onChange={handleInput}
                value={gondiInHindi || ''}
              />
            </>
          ) : (
            ''
          )}

          {modLang === 'tcy' ? (
            <>
              {/* <label className="editwords__content__form__label">
                Santhali meaning in English
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="santhaliInEnglish"
                onChange={handleInput}
                value={santhaliInEnglish || ""}
              /> */}

              <label className="editwords__content__form__label">
                Tulu meaning in Hindi
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="tuluInHindi"
                onChange={handleInput}
                value={tuluInHindi || ''}
              />
            </>
          ) : (
            ''
          )}
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <button
              type="submit"
              name="save"
              className="btn btn-md editwords__content__form__submit"
              onClick={() => setButtonClicked('save')}
            >
              Save
            </button>

            <button
              type="submit"
              name="add"
              className="btn btn-md editwords__content__form__submit"
              style={{marginLeft: '1rem'}}
              onClick={() => setButtonClicked('add')}
            >
              {isAddWordClicked ? 'Adding Word' : 'Add Word'}
            </button>

            <Link
              to="/moderator/dashboard"
              className="btn btn-md btn-dark mb-3"
              style={{marginLeft: '1rem'}}
            >
              Back
            </Link>
          </div>
        </form>
        {message ? (
          <h1 className="heading-primary--sub u-center-text">{message}</h1>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

export default AddWord;
