/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import axios from 'axios';
import config from '../../config';

const UpdatePassword = props => {
  const [updatedPassword, setUpdatedPassword] = useState('');
  const [confirmUpdatedPassword, setConfirmUpdatedPassword] = useState('');
  const [checkEqualPassword, setCheckEqualPassword] = useState(true);
  const [message, setMessage] = useState('');
  const handlePassword = e => {
    if (e.currentTarget.name === 'password') {
      setUpdatedPassword(e.currentTarget.value);
    } else if (e.currentTarget.name === 'confirm-password') {
      setConfirmUpdatedPassword(e.currentTarget.value);
    }
  };
  const sendOtpToEmail = e => {
    e.preventDefault();
    if (updatedPassword !== confirmUpdatedPassword) {
      setCheckEqualPassword(false);
    } else {
      setCheckEqualPassword(true);
      axios
        .post(`${config.REACT_APP_NODE_API_URL}/api/auth/update-password`, {
          token: props.match.params.id,
          newPwd: updatedPassword,
        })
        .then(x => {
          setMessage(x.data?.msg);
          if (x.data.success) {
            setTimeout(x => window.location.replace('/signin'), 1000);
          }
        })
        .catch(x => setMessage(x.response?.data?.msg));
    }
  };
  return (
    <section className="updatepassword">
      <div className="updatepassword__header">
        <h1 className="updatepassword__header__heading">
          Update Your Password
        </h1>
      </div>
      <h1 className="updatepassword__content__message">{message}</h1>
      <div className="updatepassword__content">
        <form className="updatepassword__content__form">
          <input
            type="password"
            className="updatepassword__content__form__email"
            name="password"
            onChange={handlePassword}
            placeholder="Enter Your Password"
          />
          <input
            type="password"
            className="updatepassword__content__form__email"
            name="confirm-password"
            onChange={handlePassword}
            placeholder="Confirm Your Password"
          />
          <button
            className="btn updatepassword__content__form__button"
            onClick={sendOtpToEmail}
          >
            Update Password
          </button>
        </form>
        {!checkEqualPassword ? (
          <h1 className="updatepassword__content__message">
            Your Passwords Don{"'"}t Match.
          </h1>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

export default UpdatePassword;
