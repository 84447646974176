import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import config from '../../config';

const AdminAuth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitClicked] = useState(false);
  const [isSignedin, setIsSignedin] = useState(false);
  const [error, setError] = useState('');
  useEffect(() => {
    if (localStorage.getItem('currentAdmin')) {
      setIsSignedin(true);
    }
  }, []);
  const handleSignin = e => {
    e.preventDefault();
    axios({
      method: 'post',
      url: `${config.REACT_APP_NODE_API_URL}/api/admin/auth/signin`,
      data: {
        email,
        password,
      },
    })
      .then(res => {
        localStorage.setItem('currentAdmin', res.data.user._id);
        localStorage.setItem('adminToken', res.data.token);
        setIsSignedin(true);
      })
      .catch(err => {
        setError(err.response.data.message);
      });
  };
  const handleInput = e => {
    const {name, value} = e.currentTarget;
    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        break;
    }
  };
  if (isSignedin) return <Redirect to="/admin/dashboard" />;
  return (
    <section className="adminauth">
      <div className="adminauth__header">
        <h1 className="adminauth__header__heading heading-primary--main u-center-text">
          Welcome to Admin
        </h1>
      </div>
      <div className="adminauth__content">
        <div className="adminauth__content__grid">
          <div className="adminauth__content__grid__form">
            <h1 className="adminauth__content__grid__heading heading-secondary--main u-center-text">
              Sign in
            </h1>
            <form
              className="adminauth__content__grid__form"
              onSubmit={handleSignin}
            >
              <input
                type="email"
                className="adminauth__content__grid__form__input"
                name="email"
                required={true}
                onChange={handleInput}
                placeholder="Email"
              />
              <input
                type="password"
                className="adminauth__content__grid__form__input"
                name="password"
                required={true}
                onChange={handleInput}
                placeholder="Password"
              />
              <button className="btn btn-md adminauth__content__grid__form__submit">
                {submitClicked ? 'Signing in' : 'Sign in'}
              </button>
            </form>
            {error ? (
              <h1 className="adminauth__content__grid__form__error">{error}</h1>
            ) : (
              ''
            )}
            <a href="/moderator" className="adminauth__link">
              Are you an Instructor?
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminAuth;
