/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Footer from '../layout/Footer';
import LoggedInFooter from '../layout/LoggedInFooter';
import Navbar from '../layout/Navbar';
import axios from 'axios';
import config from '../config';
import {AiFillCheckCircle} from 'react-icons/ai';
import {Redirect} from 'react-router';

const Courses = props => {
  const {id} = props;
  const [courseLangs, setLanguages] = useState([]);
  const [selected, setSelected] = useState([]);
  const [btnClick, setBtnClick] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const handleCardClick = lang => {
    if (!id || !isNew) {
      return;
    }
    setSelected([lang]);

    //
    // Use for multiple languages later
    //
    /* if (selected.includes(lang) && selected.length !== 1 && id) {
        const temp = selected.filter(x => {
          return x !== lang;
        });
        setSelected(temp);
      }
      if (!selected.includes(lang)) setSelected([...selected, lang]);
    */
  };
  const handleButtonClick = () => {
    if (!selected.length) {
      return;
    }
    //
    // Use for multiple languages later
    //
    // const tempLangs = selected.map(x => `hin-${x}`);
    axios
      .post(`${config.REACT_APP_NODE_API_URL}/api/user/update/${id}`, {
        languages: selected.map(lang => `hin-${lang}`),
      })
      .then(() => {
        setBtnClick(true);
      })
      .catch(err => console.log(err));
  };
  useEffect(() => {
    axios
      .get(`${config.REACT_APP_NODE_API_URL}/api/vocab/public/language-data`)
      .then(x => {
        setLanguages(x.data.data);
        return axios.get(`${config.REACT_APP_NODE_API_URL}/api/user/${id}`);
      })
      .then(res => {
        console.log(res.data.data?.languages?.length);
        if (!res.data.data?.languages?.length) {
          return setIsNew(true);
        }
        setSelected(res.data.data?.languages?.map(y => y.split('-')[1]));
      })
      .catch(console.log);
  }, [id]);
  if (btnClick) {
    return <Redirect to="/hear-about-us" />;
  }
  return (
    <>
      <Navbar />
      <section className="courses">
        <h1>Trilingo Courses</h1>
        <div className="courses-content">
          {courseLangs?.map((x, i) => (
            <div
              className="courses-content-card"
              key={i}
              onClick={() => {
                handleCardClick(x.languageCode);
              }}
            >
              <span
                className={`checked-icon ${
                  selected.includes(x.languageCode) && id ? '' : 'not-checked'
                } `}
              >
                <AiFillCheckCircle />
              </span>
              <h2>{`${x.name} (${x.status})`}</h2>
              <p>
                {x.details}
                <br />
                <span style={{fontWeight: 'bold'}}>{x.tagLine}</span>
              </p>
            </div>
          ))}
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          {id && isNew ? (
            <div className="btn" onClick={handleButtonClick}>
              Learn Language
            </div>
          ) : (
            <></>
          )}
        </div>
      </section>
      {props.id ? <LoggedInFooter /> : <Footer />}
    </>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;
  return {
    id,
    error: state.auth.authError,
  };
};

export default connect(mapStateToProps)(Courses);
