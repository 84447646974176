/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import axios from 'axios';
import config from '../../config';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [senOtpClicked, setSenOtpClicked] = useState(false);
  const handleEmail = e => {
    setEmail(e.target.value);
  };
  const sendOtpToEmail = e => {
    e.preventDefault();
    setTimeout(() => {
      setSenOtpClicked(true);
    }, 1500);
    axios
      .post(`${config.REACT_APP_NODE_API_URL}/api/auth/request-mod-pwd-reset`, {
        email,
      })
      .then(console.log);
  };
  return (
    <section className="forgotpassword">
      <div className="forgotpassword__header">
        <h1 className="forgotpassword__header__heading">
          Forgot Your Password?
        </h1>
      </div>
      <div className="forgotpassword__content">
        <form className="forgotpassword__content__form">
          <input
            type="email"
            className="forgotpassword__content__form__email"
            name="email"
            onChange={handleEmail}
            placeholder="Enter your email"
          />
          <button
            className="btn forgotpassword__content__form__button"
            onClick={sendOtpToEmail}
          >
            Request Reset
          </button>
        </form>
        {senOtpClicked ? (
          <h1 className="forgotpassword__content__message">
            Check the email and update the password by provided URL.
          </h1>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

export default ForgotPassword;
