import React, {Fragment, useEffect, useState} from 'react';
import AccountSidebar from '../../components/AccountSidebar';
import LoggedInFooter from '../../layout/LoggedInFooter';
import axios from 'axios';
import config from '../../config';

const ChangePassword = () => {
  const [oldPwd, setOldPwd] = useState('');
  const [newPwd, setNewPwd] = useState('');
  const [msg, setMsg] = useState('');
  const [btnEnabled, setEnabled] = useState(false);

  useEffect(() => {
    if (!oldPwd.trim().length) {
      setEnabled(false);
      return false;
    }
    if (newPwd.trim().length < 8) {
      setEnabled(false);
      return false;
    }
    if (newPwd.length > 128) {
      setMsg('Passwords should be less than 128 characters');
      setEnabled(false);
      return false;
    }
    if (!/\d/.test(newPwd)) {
      setEnabled(false);
      return false;
    }
    if (!/[A-Z]/.test(newPwd)) {
      setEnabled(false);
      return false;
    }
    if (!/[a-z]/.test(newPwd)) {
      setEnabled(false);
      return false;
    }
    if (/\s/.test(newPwd)) {
      setMsg('Password should not contain spaces');
      setEnabled(false);
      return false;
    }
    if (!/\W/.test(newPwd)) {
      setEnabled(false);
      return false;
    }
    setEnabled(true);
    return true;
  }, [newPwd, oldPwd]);

  return (
    <Fragment>
      <section className="change-password">
        <div className="change-password-settings">
          <div className="change-password-form">
            <div className="change-password-form-heading">
              <h1>Password</h1>
              <button
                disabled={!btnEnabled}
                className={`${
                  btnEnabled
                    ? 'change-password-btn-active'
                    : 'change-password-btn'
                }`}
                onClick={() => {
                  if (!(oldPwd.trim() && newPwd.trim())) {
                    setMsg(
                      `${
                        !oldPwd.trim() ? 'Current password' : 'New password'
                      } is empty`
                    );
                    setTimeout(() => {
                      setMsg('');
                    }, 5000);
                    return;
                  }
                  const id = JSON.parse(
                    localStorage.getItem('currentUser')
                  )._id;
                  axios
                    .post(
                      `${config.REACT_APP_NODE_API_URL}/api/user/profile/password-update`,
                      {
                        id,
                        oldPwd,
                        newPwd,
                      }
                    )
                    .then(x => setMsg(x.data.msg))
                    .catch(x => {
                      setMsg(x.response.data?.msg || 'Something went wrong!');
                      setTimeout(() => {
                        setMsg('');
                      }, 5000);
                    });
                }}
              >
                Update Password
              </button>
            </div>
            <div className="change-password-form-content">
              <form>
                <div className="change-password-form-group">
                  <label htmlFor="currentPassword">Current Password</label>
                  <input
                    type="password"
                    name="currentPassword"
                    placeholder="Current Password"
                    onChange={e => setOldPwd(e.target.value)}
                  />
                </div>
                <div className="change-password-form-group">
                  <label htmlFor="newPassword">New Password</label>
                  <div>
                    <input
                      type="password"
                      name="newPassword"
                      placeholder="New Password"
                      onChange={e => setNewPwd(e.target.value)}
                    />
                    <div className="password-conditions">
                      Password should have atleast:
                      <div
                        style={
                          newPwd.trim().length >= 8
                            ? {color: 'green'}
                            : {color: 'red'}
                        }
                      >
                        8 characters
                      </div>
                      <div
                        style={
                          /[A-Z]/.test(newPwd)
                            ? {color: 'green'}
                            : {color: 'red'}
                        }
                      >
                        one uppercase character
                      </div>
                      <div
                        style={
                          /[a-z]/.test(newPwd)
                            ? {color: 'green'}
                            : {color: 'red'}
                        }
                      >
                        one lowercase character
                      </div>
                      <div
                        style={
                          /\d/.test(newPwd) ? {color: 'green'} : {color: 'red'}
                        }
                      >
                        one digit
                      </div>
                      <div
                        style={
                          /\W/.test(newPwd) && !/\s/.test(newPwd)
                            ? {color: 'green'}
                            : {color: 'red'}
                        }
                      >
                        one special character
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <h3>{msg}</h3>
          </div>
          <AccountSidebar />
        </div>
      </section>
      <LoggedInFooter />
    </Fragment>
  );
};

export default ChangePassword;
