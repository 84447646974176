import React, {Fragment} from 'react';
import {FaDesktop, FaAndroid, FaHeadphones} from 'react-icons/fa';

const MeetTrilingo = () => {
  return (
    <Fragment>
      <section className="meet-trilingo">
        <div className="meet-trilingo-content">
          <div className="meet-trilingo-content-img">
            <h1>Meet TriLingo</h1>
            <p>
              {
                "India's 1st mother-tongue based learning platform that enables you to learn in your native tongue."
              }
            </p>
            <img src="/assets/about-m-screen.png" alt="about" />
          </div>
          <div className="meet-trilingo-content-features">
            <h2>What is Language?</h2>
            <p>
              Language is what makes us human. It shapes the way you think and
              helps us communicate. It allows us to share our ideas, thoughts,
              and feelings with others.
            </p>
            <h2>What is Mother Tongue Education?</h2>
            <p>
              Mother tongue education is a form of schooling that makes use of
              the languages that children are most familiar with. It is
              important because:
            </p>
            <div className="meet-trilingo-content-features-list">
              {/* 1 */}
              <div className="meet-trilingo-content-features-list-item">
                <div className="features-icon">
                  <FaDesktop />
                </div>
                <div className="features-text">
                  <h5>Knowledge</h5>
                  <h6>
                    Each language carry a set of unique knowledge specific which
                    enable a perspective.
                  </h6>
                </div>
              </div>

              {/* 2 */}
              <div className="meet-trilingo-content-features-list-item">
                <div className="features-icon">
                  <FaAndroid />
                </div>
                <div className="features-text">
                  <h5>Identity</h5>
                  <h6>
                    Language provide each individual about his origin. It{"'"}s
                    not just a communication tool.
                  </h6>
                </div>
              </div>
              {/* 3 */}
              <div className="meet-trilingo-content-features-list-item">
                <div className="features-icon">
                  <FaHeadphones />
                </div>
                <div className="features-text">
                  <h5>Future</h5>
                  <h6>
                    The rate of death of a language is very fast as much as one
                    every two week.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default MeetTrilingo;
