/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

const EditWord = ({match, location}) => {
  const [modLang] = useState(
    localStorage.getItem('modLang') ? localStorage.getItem('modLang') : null
  );
  const [message, setMessage] = useState('');
  const [categoryName] = useState(match.params.categoryName);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [longDesc, setLongDesc] = useState(location.state.longDesc);
  const [shortDesc, setShortDesc] = useState(location.state.shortDesc);
  const moderator = localStorage.getItem('currentAdmin');
  const submitEditCat = e => {
    const token = localStorage.getItem('adminToken')
      ? localStorage.getItem('adminToken')
      : null;
    e.preventDefault();
    setMessage('');
    axios
      .put(
        `${config.REACT_APP_NODE_API_URL}/api/admin/mod/update-category/${modLang}`,
        {
          categoryName,
          newCategoryName,
          longDesc,
          shortDesc,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setMessage('Category updated..');
      });
  };
  if (!moderator) return <Redirect to="/moderator" />;
  return (
    <section className="editwords">
      <div className="editwords__header">
        <h1 className="editwords__header__heading heading-primary--main u-center-text">
          Edit Categories
        </h1>
      </div>
      <div className="editwords__content">
        <form className="editwords__content__form" onSubmit={submitEditCat}>
          <input
            type="text"
            className="editwords__content__form__input"
            name="categoryName"
            value={categoryName}
          />
          <input
            placeholder="New Category Name"
            type="text"
            className="editwords__content__form__input"
            name="newCategoryName"
            onChange={e => setNewCategoryName(e.currentTarget.value)}
            value={newCategoryName}
            required={true}
          />
          <input
            placeholder="Short Description"
            type="text"
            className="editwords__content__form__input"
            name="shortDesc"
            onChange={e => setShortDesc(e.currentTarget.value)}
            value={shortDesc}
            required={true}
          />
          <input
            placeholder="Long Description"
            type="text"
            className="editwords__content__form__input"
            name="longDesc"
            onChange={e => setLongDesc(e.currentTarget.value)}
            value={longDesc}
            required={true}
          />
          <h3>
            Ps: Copy the exact same categoryName in newCategory if you just want
            to edit long and short desc...{' '}
          </h3>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <button className="btn btn-md editwords__content__form__submit">
              Edit Category
            </button>
            <Link
              to="/moderator/add-category"
              className="btn btn-md "
              style={{marginLeft: '1rem'}}
            >
              Back
            </Link>
          </div>
        </form>
        {message ? (
          <h1 className="heading-primary--sub u-center-text">{message}</h1>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

export default EditWord;
