import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Navbar from './layout/Navbar';
import Signin from './pages/auth/Signin';
import Homepage from './pages/Homepage';
import Learn from './pages/Learn';
import EditProfile from './pages/user/EditProfile';
import VocabCards from './pages/VocabCards';
import ForgotPassword from './pages/auth/ForgotPassword';
import VerifyEmail from './pages/auth/VerifyEmail';
import ActivateAccount from './pages/auth/ActivateAccount';
import UpdatePassword from './pages/auth/UpdatePassword';
import ModAuth from './pages/mod/Auth';
import ModDashboard from './pages/mod/Dashboard';
import AdminAuth from './pages/admin/Auth';
import Quiz from './pages/Quiz';
import AddQuestion from './pages/mod/AddQuestion';
import AdminDashboard from './pages/admin/Dashboard';
import ApproveInstrutorRequests from './pages/admin/Actions/ApproveInstrutorRequests';
import EditWord from './pages/mod/EditWord';
import AddWord from './pages/mod/AddWord';
import AddCategory from './pages/mod/AddCategory';
import ManageWords from './pages/mod/ManageWords';
import AddLanguage from './pages/admin/Actions/AddLanguage';
import Goal from './pages/Goal';
import ChooseGoal from './pages/ChooseGoal';
import Account from './pages/user/Account';
import ChangePassword from './pages/user/ChangePassword';
import LearningLanguage from './pages/user/LearningLanguage';
import Profile from './pages/user/Profile';
import ManageQuestions from './pages/mod/ManageQuestions';
import EditQuestion from './pages/mod/EditQuestion';
import EditCategory from './pages/mod/EditCategory';
import ManageQuiz from './pages/mod/ManageQuiz';
import NotFound from './pages/NotFound';
import TermsAndConditions from './pages/TermsAndConditions';
import Privacy from './pages/Privacy';
import About from './pages/About';
import HearAboutUs from './pages/HearAboutUs';
import AddTF from './pages/mod/AddTF';
import EditTF from './pages/mod/EditTF';
import WhyLearningLanguage from './pages/WhyLearningLanguage';
import Courses from './pages/Courses';
import ReshuffleCategory from './pages/mod/ReShuffleCategory';
import ManageReports from './pages/mod/ManageReports';
import ContactUs from './pages/ContactUs';
import Tickets from './pages/admin/Tickets';
import Analytics from './pages/admin/Analytics';
import UserSpecAnalytics from './pages/admin/UserSpecAnalytics';
import config from './config';
import {StopWatch} from './components/StopWatch';
import EditCourse from './pages/admin/EditCourses';
import UserSessionAnalytics from './pages/admin/UserSessionAnalytics';
import UserSession from './pages/admin/UserSession';
import WhoIS from './pages/admin/WhoIS';
import ModForgotPassword from './pages/mod/ForgotPassword';
import ModUpdatePassword from './pages/mod/UpdatePassword';
import CookiePreferences from './components/CookiePreferences';
import Notifications from './pages/user/Notifications';
import AllNotifications from './pages/user/AllNotifications';
// import AboutUs from './pages/AboutUs';

const App = () => {
  const [uid] = React.useState(
    JSON.parse(localStorage.getItem('currentUser')) &&
      JSON.parse(localStorage.getItem('currentUser'))._id
  );

  /*
  const { addToast } = useToasts();

  
    @author NevetsKuro <stevenfernz96@gmail.com>
    @description fetch realtime notification from backend
  React.useEffect(() => {
    if (JSON.parse(localStorage.getItem('currentUser'))._id) {
      const notifyApi = setInterval(() => {
        axios({
          method: 'get',
          url: `${config.REACT_APP_NODE_API_URL}/api/user/notifications/${JSON.parse(localStorage.getItem('currentUser'))?._id}`,
        })
          .then(res => {
            addToast('Reminder:Test Notifications', {
              appearance: 'info',
              autoDismiss: true,
            })
            console.log(res.data.data)
          })
          .catch(err => {
            console.log(err);
          });
      }, 7000);
      return () => clearInterval(notifyApi);
    }
  }, [])
*/

  React.useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem('cookiePermission'));
    if (uid && permissions?.analytics) {
      const canvas = document.getElementById('glCanvas');
      let gl;
      let debugInfo;
      let vendor;
      let renderer;
      try {
        gl =
          canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
      } catch (e) {
        console.log('cannot use webgl!');
      }
      if (gl) {
        debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
        if (debugInfo) {
          vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
          renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        } else {
          vendor = gl.getParameter(gl.VENDOR);
          renderer = gl.getParameter(gl.RENDERER);
        }
      }
      const ips = [];
      const MyPeerConnection = window.RTCPeerConnection;
      if (MyPeerConnection) {
        const pc = new MyPeerConnection({
          iceServers: [
            {
              urls: 'stun:stun.l.google.com:19302',
            },
          ],
        });
        const localIPs = {};
        pc.createDataChannel('');
        pc.createOffer(
          sdp => {
            sdp.sdp
              .split('\n')
              .forEach(line =>
                line.indexOf('candidate') !== -1
                  ? line
                      .match(
                        /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g
                      )
                      .forEach(
                        ip =>
                          (localIPs[ip] = localIPs[ip] ? true : !!ips.push(ip))
                      )
                  : undefined
              );
            pc.setLocalDescription(
              sdp,
              () => {},
              () => {}
            );
          },
          () => {}
        );
        pc.onicecandidate = ice =>
          ice.candidate?.candidate
            ?.match(
              /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g
            )
            ?.forEach(
              ip => (localIPs[ip] = localIPs[ip] ? true : !!ips.push(ip))
            );
      }
      const stopwatch = new StopWatch();
      stopwatch.start();
      window.addEventListener('blur', () => stopwatch.stop());
      window.addEventListener('focus', () => stopwatch.start());
      /**
       * @author SoulHarsh007 <harshtheking@hotmail.com>
       * @description sends request to /api/analytics before unload
       */
      document.addEventListener('visibilitychange', () => {
        stopwatch.stop();
        if (document.visibilityState === 'visible') {
          stopwatch.reset();
          stopwatch.start();
        }
        if (document.visibilityState === 'hidden') {
          const dp =
            `${window?.screen?.width}x${window?.screen?.height}x${window?.screen?.colorDepth}` ||
            'unknown';
          console.log(ips);
          navigator.sendBeacon(
            `${
              config.REACT_APP_NODE_API_URL
            }/api/user/analytics/update?uid=${encodeURIComponent(
              uid
            )}&data=${encodeURIComponent(
              stopwatch.duration
            )}&hwc=${encodeURIComponent(
              navigator.hardwareConcurrency || '0'
            )}&gv=${encodeURIComponent(
              vendor || 'unknown'
            )}&gr=${encodeURIComponent(renderer || 'unknown')}&dnt=${
              navigator.doNotTrack || 'o'
            }&pf=${encodeURIComponent(navigator.platform || 'unknown')}&mem=${
              navigator.deviceMemory || '0'
            }&tz=${encodeURIComponent(
              // eslint-disable-next-line new-cap
              Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'unknown'
            )}&dp=${encodeURIComponent(dp)}&lip=${encodeURIComponent(
              ips.filter(x => x !== '0.0.0.0')
            )}`
          );
        }
      });
    }
    const reminder = JSON.parse(localStorage.getItem('Daily Reminder'));
    // const reminder = {remind: true, hours: 9, minutes: 43, lastShown: 26};
    // console.log(reminder);
    if (!reminder) {
      return;
    }
    const d = new Date();
    if (
      reminder.lastShown !== undefined &&
      reminder.lastShown === d.getDate()
    ) {
      return;
    }
    const rTime =
      new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        reminder.hours,
        reminder.minutes,
        0,
        0
      ) - d;
    if (rTime < 0) {
      new Notification('Learning Reminder', {
        body: 'Hi there!, Just a small reminder to make sure that you keep learning.',
      });
      reminder.lastShown = d.getDate();
      localStorage.setItem('Daily Reminder', JSON.stringify(reminder));
    } else {
      setTimeout(() => {
        new Notification('Learning Reminder', {
          body: 'Hi there!, Just a small reminder to make sure that you keep learning.',
        });
        reminder.lastShown = d.getDate();
        localStorage.setItem('Daily Reminder', JSON.stringify(reminder));
      }, rTime);
    }
  }, [uid]);
  return (
    <BrowserRouter>
      <Navbar />
      <main id="page-content">
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/learn" component={Learn} />
          <Route exact path="/learn/goal" component={Goal} />
          <Route exact path="/choose-goals" component={ChooseGoal} />
          <Route
            exact
            path="/quiz/:language/:question_level"
            component={Quiz}
          />
          <Route exact path="/account" component={Account} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/settings/password" component={ChangePassword} />
          <Route exact path="/settings/language" component={LearningLanguage} />
          <Route exact path="/edit" component={EditProfile} />
          <Route exact path="/notifications" component={Notifications} />
          <Route
            exact
            path="/notifications-summary"
            component={AllNotifications}
          />
          <Route
            exact
            path="/vocab/category/:category"
            component={VocabCards}
          />
          <Route
            exact
            path="/user/forgot-password"
            component={ForgotPassword}
          />
          <Route exact path="/verify" component={VerifyEmail} />
          <Route
            exact
            path={`/activate-account/${uid}`}
            component={ActivateAccount}
          />
          <Route
            exact
            path={`/activate-account/:id`}
            component={ActivateAccount}
          />
          <Route
            exact
            path={'/user/update-password/:id'}
            component={UpdatePassword}
          />
          <Route exact path="/moderator" component={ModAuth} />
          <Route exact path="/moderator/dashboard" component={ModDashboard} />
          <Route exact path="/moderator/manage-words" component={ManageWords} />
          <Route exact path="/moderator/reports" component={ManageReports} />
          <Route
            exact
            path="/moderator/manage-questions/:word"
            component={ManageQuestions}
          />
          <Route exact path="/moderator/manage-quiz" component={ManageQuiz} />
          <Route exact path="/moderator/add-word" component={AddWord} />
          <Route
            exact
            path="/moderator/edit-word/:wordId"
            component={EditWord}
          />
          <Route
            exact
            path="/moderator/reshuffle-category"
            component={ReshuffleCategory}
          />
          <Route exact path="/moderator/add-category" component={AddCategory} />
          <Route
            exact
            path="/moderator/add-question/:word"
            component={AddQuestion}
          />
          <Route
            exact
            path="/moderator/edit-question/:questionId"
            component={EditQuestion}
          />
          <Route exact path="/moderator/add-tf/:word" component={AddTF} />
          <Route
            exact
            path="/moderator/update/tf-quiz/:questionId"
            component={EditTF}
          />
          <Route
            exact
            path="/moderator/edit-category/:categoryName"
            component={EditCategory}
          />
          <Route exact path="/admin" component={AdminAuth} />
          <Route exact path="/admin/dashboard" component={AdminDashboard} />
          <Route exact path="/admin/add-new-language" component={AddLanguage} />
          <Route
            exact
            path="/admin/approve-instructor-requests"
            component={ApproveInstrutorRequests}
          />
          <Route exact path="/admin/tickets" component={Tickets} />
          <Route exact path="/admin/user-analytics" component={Analytics} />
          <Route
            exact
            path="/admin/user-analytics/:id"
            component={UserSpecAnalytics}
          />
          <Route exact path="/admin/sessions" component={UserSession} />
          <Route
            exact
            path="/admin/sessions/:id"
            component={UserSessionAnalytics}
          />
          <Route
            exact
            path="/admin/user-analytics/run-whois/:id"
            component={WhoIS}
          />
          <Route exact path="/admin/edit-courses" component={EditCourse} />
          <Route
            exact
            path="/moderator/forgot-password"
            component={ModForgotPassword}
          />
          <Route
            exact
            path="/moderator/update-password/:id"
            component={ModUpdatePassword}
          />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route exact path="/privacy-policy" component={Privacy} />
          <Route exact path="/about" component={About} />
          {/* <Route exact path="/about-us" component={AboutUs} />*/}
          <Route exact path="/hear-about-us" component={HearAboutUs} />
          <Route
            exact
            path="/why-learning-language"
            component={WhyLearningLanguage}
          />
          <Route exact path="/courses" component={Courses} />
          <Route exact path="/contact" component={ContactUs} />
          <Route component={NotFound} />
        </Switch>
      </main>
      <CookiePreferences />
    </BrowserRouter>
  );
};

export default App;
