import React from 'react';
import {Link} from 'react-router-dom';

const NotFound = () => {
  return (
    <div style={{padding: '14rem'}}>
      <h1>Seems like you are lost..</h1>
      <h3>Error: 404 page not found</h3>
      <Link to="/">HomePage</Link>
    </div>
  );
};

export default NotFound;
