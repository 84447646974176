/**
 * @author NevetsKuro <stevenfernz96@gmail.com>
 * @function notifyUser
 * @param {import('react-toast-notifications')} addToast - addToast package
 * @param {{type: string, title: string, body: string, seen: boolean, date: string}[]} notifications - All Unseen Notifications
 * @description fetch realtime notification from backend
 */
export const notifyUser = (addToast, notifications) => {
  // console.log(notifications);
  let appearanceValue = 'info';
  for (const item of notifications) {
    if (item.type === 'Achievement' || item.type === 'Announcement') {
      appearanceValue = 'error';
    } else if (item.type === 'Lessons' || item.type === 'Courses') {
      // Exercise
      appearanceValue = 'success';
    } else {
      appearanceValue = 'info';
    }
    addToast(item.title, {
      appearance: appearanceValue,
      autoDismiss: true,
    });
    const audio = new Audio('/assets/audio/mixkit-happy-bell-alert-601.wav');
    if (audio) {
      audio.play();
    }
  }
};
