import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import config from '../../../config';

const AddLanguage = () => {
  const [language, setLanguage] = useState('');
  const [message, setMessage] = useState('');
  const [languageData, setLanguageData] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    axios
      .get(`${config.REACT_APP_NODE_API_URL}/api/admin/analytics/mods`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(x => {
        console.log(x.data.data);
        setLanguageData(x.data.data);
      });
  }, []);
  const addLanguage = e => {
    const token = localStorage.getItem('adminToken');
    e.preventDefault();
    setMessage('');
    axios
      .post(
        `${config.REACT_APP_NODE_API_URL}/api/admin/post-category`,
        {
          language_code: language,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setLanguage('');
        setMessage('Language added');
      })
      .catch(err => {
        setMessage(
          err.response.data.msg
            ? err.response.data.msg
            : 'Something went wrong, Try again'
        );
      });
  };
  return (
    <div style={{padding: '20rem'}}>
      <h1>Add Language</h1>
      <div style={{display: 'flex', flexDirection: 'column', width: '80rem'}}>
        <form onSubmit={addLanguage}>
          <input
            type="text"
            placeholder="Language"
            required={true}
            value={language}
            className="homepage__auth__content__form__input"
            onChange={e => setLanguage(e.target.value)}
          />
          <span
            style={{
              fontWeight: 'bold',
              fontSize: '1.5rem',
              color: 'orangered',
            }}
          >
            Note: Write 3 digit language code instead of whole name{' '}
          </span>
          <br />
          <button type="submit" className="btn btn-md">
            Submit
          </button>
          <Link
            to="/admin/dashboard"
            className="btn btn-sm"
            style={{marginLeft: '5rem'}}
          >
            Exit
          </Link>
        </form>
        {message && <h2>{message}</h2>}
      </div>
      <br />
      <hr />
      <br />
      <h1>Language Analytics: </h1>
      <br />
      <hr />
      <br />
      <table className="managewords__table" style={{width: '100%'}}>
        <thead>
          <tr className="managewords__tr">
            <th className="managewords__th">#</th>
            <th className="managewords__th">Name</th>
            <th className="managewords__th">Mods</th>
            <th className="managewords__th">Categories</th>
          </tr>
        </thead>
        <tbody>
          {languageData.map((x, i) => {
            return (
              <tr key={i} className="managewords__tr">
                <td className="managewords__td">{i + 1}</td>
                <td className="managewords__td">{x.name}</td>
                <td className="managewords__td">
                  {[...new Set(x.mods)].length}
                </td>
                <td className="managewords__td">{x.categories}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      <hr />
      <br />
      <h1>Moderator Analytics: </h1>
      <br />
      <hr />
      <br />
      <table className="managewords__table" style={{width: '100%'}}>
        <thead>
          <tr className="managewords__tr">
            <th className="managewords__th">#</th>
            <th className="managewords__th">Name</th>
            <th className="managewords__th">Email</th>
            <th className="managewords__th">Language</th>
            <th className="managewords__th">Words Added</th>
          </tr>
        </thead>
        <tbody>
          {languageData
            .map(x => x.mods)
            .flat()
            .map((x, i) => {
              return (
                <tr key={i} className="managewords__tr">
                  <td className="managewords__td">{i + 1}</td>
                  <td className="managewords__td">{x?.name || 'Unknown'}</td>
                  <td className="managewords__td">{x?.email || 'Unknown'}</td>
                  <td className="managewords__td">
                    {x?.language || 'Unknown'}
                  </td>
                  <td className="managewords__td">{x?.count || 0}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default AddLanguage;
