/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import config from '../../config';
import {Link} from 'react-router-dom';

const WhoIS = ({match}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem('adminToken')
      ? localStorage.getItem('adminToken')
      : null;
    axios
      .post(
        `${config.REACT_APP_NODE_API_URL}/api/admin/whois-lookup/`,
        {
          ip: decodeURIComponent(match.params.id),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(console.error);
  }, [match.params.id]);
  return (
    <section style={{marginTop: '150px'}}>
      <Link
        to="/admin/dashboard"
        className="btn btn-sm"
        style={{marginBottom: '10px'}}
      >
        Back
      </Link>
      <hr />
      <div className="admindashboard__header">
        <h1 className="admindashboard__header__heading heading-primary--main u-center-text">
          WHOIS Report For IP: {decodeURIComponent(match.params.id)}
        </h1>
      </div>
      <hr />
      <div
        style={{
          backgroundColor: '#f99529',
          width: '100%',
        }}
      >
        <pre className="heading-secondary--sub admindashboard__content__actioncards__item__subheading">
          {data}
        </pre>
      </div>
    </section>
  );
};

export default WhoIS;
