/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import config from '../../config';
import {Link} from 'react-router-dom';

const Analytics = ({match}) => {
  const [languageData, setLanguageData] = useState({});
  const [partialData, setPartialData] = useState({});
  const [browser, setBrowser] = useState({});
  const [sessionData, setSessionData] = useState({});
  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    axios
      .get(
        `${config.REACT_APP_NODE_API_URL}/api/admin/analytics/users/${match.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(x => {
        setLanguageData(x.data.data);
        setPartialData(x.data.partialData);
        setSessionData(x.data.sessionData);
        setBrowser(
          x.data.data?.browsers?.sort((x, y) =>
            x.effectiveTimeSpent < y.effectiveTimeSpent
              ? 1
              : y.effectiveTimeSpent < x.effectiveTimeSpent
              ? -1
              : 0
          )?.[0]
        );
      });
  }, [match.params.id]);
  return (
    <div
      style={{
        overflow: 'flex',
        fontSize: '1.3rem',
        resize: 'contain',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100%',
        width: 'fit-content',
      }}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Link
        to="/admin/user-analytics"
        className="btn btn-sm"
        style={{marginBottom: '10px'}}
      >
        Back
      </Link>
      <hr />
      <br />
      <h1 style={{textAlign: 'center'}}>User ID: {match.params.id}</h1>
      <br />
      <hr />
      <br />
      <div
        style={{
          width: 'fit-content',
          textAlign: 'center',
          overflow: 'flex',
        }}
      >
        <h3>Name: {languageData.name}</h3>
        <h3>Email: {languageData.email}</h3>
        <h3>
          Account Status:{' '}
          {languageData?.isAccountActive
            ? 'Active'
            : languageData?.isEmailSent
            ? 'Not Verified'
            : 'Inactive'}
        </h3>
        <h3>Language: {languageData?.languageData || 'Unknown'}</h3>
        <h3>Days Practiced: {partialData?.total || 'Unknown'}</h3>
        <h3>Average Words/Day: {partialData?.average || 'Unknown'}</h3>
        <h3>Effective Time Spent: {languageData?.effectiveTimeSpent}</h3>
        <h3>
          Learning Reason: {languageData?.learningReason || 'Not Specified'}
        </h3>
        <h3>Referrer: {languageData?.referrer || 'Not Specified'}</h3>
        <h3>Daily Goal: {languageData?.dailyGoal || 0}</h3>
        <h3>
          Favorite Browser:{' '}
          {browser?._id
            ? `${browser?._id} (${browser?.name} for ${browser?.os} [${browser?.realOS}])`
            : 'None (Yet)...'}
        </h3>
        <h3>Active Sessions: {sessionData.length}</h3>
        <br />
        <hr />
        <br />
        <h2>Browsers Used:</h2>
        <br />
        <hr />
        <br />
        <table
          className="managewords__table"
          style={{
            width: 'fit-content',
            textAlign: 'center',
            position: 'relative',
            overflow: 'flex',
            resize: 'contain',
            fontSize: '1.0rem',
          }}
        >
          <thead>
            <tr className="managewords__tr">
              <th className="managewords__th">#</th>
              <th className="managewords__th">Name</th>
              <th className="managewords__th">Version</th>
              <th className="managewords__th">Vendor</th>
              <th className="managewords__th">Model</th>
              <th className="managewords__th">Type</th>
              <th className="managewords__th">Cpu Cores</th>
              <th className="managewords__th">OS</th>
              <th className="managewords__th">OS Version</th>
              <th className="managewords__th">GPU Vendor</th>
              <th className="managewords__th">GPU Renderer</th>
              <th className="managewords__th">Effective Time Spent</th>
              <th className="managewords__th">Real OS</th>
              <th className="managewords__th">CPU Arch</th>
              <th className="managewords__th">DNT</th>
              <th className="managewords__th">TimeZone</th>
              <th className="managewords__th">Approx Memory</th>
              <th className="managewords__th">Resists Fingerprinting</th>
              <th className="managewords__th">Display Info</th>
              <th className="managewords__th">Browser ID</th>
            </tr>
          </thead>
          <tbody>
            {languageData.browsers?.length ? (
              languageData.browsers.map((x, i) => (
                <tr key={i} className="managewords__tr">
                  <td className="managewords__td">{i + 1}</td>
                  <td className="managewords__td">{x?.name || 'Unknown'}</td>
                  <td className="managewords__td">{x?.version || 'Unknown'}</td>
                  <td className="managewords__td">{x?.vendor || 'Unknown'}</td>
                  <td className="managewords__td">{x?.model || 'Unknown'}</td>
                  <td className="managewords__td">{x?.type || 'Unknown'}</td>
                  <td className="managewords__td">
                    {x?.cpuCores || 'Unknown'}
                  </td>
                  <td className="managewords__td">{x?.os || 'Unknown'}</td>
                  <td className="managewords__td">
                    {x?.osVersion || 'Unknown'}
                  </td>
                  <td className="managewords__td">
                    {x?.gpuVendor || 'Unknown'}
                  </td>
                  <td className="managewords__td">
                    {x?.gpuRenderer || 'Unknown'}
                  </td>
                  <td className="managewords__td">
                    {x?.effectiveTimeSpent || 'Unknown'}
                  </td>
                  <td className="managewords__td">{x?.realOS || 'Unknown'}</td>
                  <td className="managewords__td">{x?.cpuArch || 'Unknown'}</td>
                  <td className="managewords__td">{x?.useDNT || 'Unknown'}</td>
                  <td className="managewords__td">{x?.tz || 'Unknown'}</td>
                  <td className="managewords__td">
                    {x?.approxMem || 'Unknown'}
                  </td>
                  <td className="managewords__td">
                    {x?.resistsFingerprinting ? 'Yes' : 'No'}
                  </td>
                  <td className="managewords__td">
                    {x?.displayInfo || 'Unknown'}
                  </td>
                  <td className="managewords__td">{x?._id || 'Unknown'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={20}>No Data Available (Yet)...</td>
              </tr>
            )}
          </tbody>
        </table>
        <br />
        <hr />
        <br />
        <h2>IP Addresses Used:</h2>
        <br />
        <hr />
        <br />
        <table
          className="managewords__table"
          style={{
            width: '100%',
            textAlign: 'center',
            fontSize: '1.5rem',
          }}
        >
          <thead>
            <tr className="managewords__tr">
              <th className="managewords__th" style={{textAlign: 'center'}}>
                #
              </th>
              <th className="managewords__th" style={{textAlign: 'center'}}>
                IP Address
              </th>
              <th className="managewords__th" style={{textAlign: 'center'}}>
                IP Version
              </th>
              <th className="managewords__th" style={{textAlign: 'center'}}>
                Host Name
              </th>
              <th className="managewords__th" style={{textAlign: 'center'}}>
                Unique User IP Tag
              </th>
              <th className="managewords__th" style={{textAlign: 'center'}}>
                Action
              </th>
            </tr>
          </thead>
          <tbody
            style={{
              width: '100%',
              textAlign: 'center',
              fontSize: '1.5rem',
            }}
          >
            {languageData?.ipAddresses?.length ? (
              languageData?.ipAddresses?.map((x, i) => (
                <tr key={i}>
                  <td className="managewords__td">{i + 1}</td>
                  <td className="managewords__td">{x?.ip}</td>
                  <td className="managewords__td">
                    {x?.ip?.includes(':') ? 'IPv6' : 'IPv4'}
                  </td>
                  <td className="managewords__td">{x?.hostName}</td>
                  <td className="managewords__td">{x?._id}</td>
                  <td
                    className="managewords__td"
                    style={{
                      backgroundColor: '#fffff',
                      resize: 'inherit',
                    }}
                  >
                    <Link
                      to={`/admin/user-analytics/run-whois/${encodeURIComponent(
                        x.ip
                      )}`}
                    >
                      <button
                        type="button"
                        style={{
                          backgroundColor: '#fffff',
                          padding: '5px',
                        }}
                      >
                        WHOIS
                      </button>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>No Data (Yet)...</td>
              </tr>
            )}
          </tbody>
        </table>
        {languageData?.ipAddresses?.length ? (
          languageData?.ipAddresses?.map((y, i) => {
            return y?.localIps?.length ? (
              <div key={i}>
                <br />
                <hr />
                <br />
                <h2>Local IPS (WebRTC Leaks): (Main: {y.ip})</h2>
                <br />
                <hr />
                <br />
                <table
                  className="managewords__table"
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '1.5rem',
                  }}
                >
                  <thead>
                    <tr className="managewords__tr">
                      <th
                        className="managewords__th"
                        style={{textAlign: 'center'}}
                      >
                        #
                      </th>
                      <th
                        className="managewords__th"
                        style={{textAlign: 'center'}}
                      >
                        Local IP Address
                      </th>
                      <th
                        className="managewords__th"
                        style={{textAlign: 'center'}}
                      >
                        IP Version
                      </th>
                      <th
                        className="managewords__th"
                        style={{textAlign: 'center'}}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      fontSize: '1.5rem',
                    }}
                  >
                    {y.localIps?.map((x, i) => (
                      <tr className="managewords__tr" key={i}>
                        <td className="managewords__td">{i + 1}</td>
                        <td className="managewords__td">{x}</td>
                        <td className="managewords__td">
                          {x?.includes(':') ? 'IPv6' : 'IPv4'}
                        </td>
                        <td
                          className="managewords__td"
                          style={{
                            backgroundColor: '#fffff',
                            resize: 'inherit',
                          }}
                        >
                          <Link
                            to={`/admin/user-analytics/run-whois/${encodeURIComponent(
                              x
                            )}`}
                          >
                            <button
                              type="button"
                              style={{
                                backgroundColor: '#fffff',
                                padding: '5px',
                              }}
                            >
                              WHOIS
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <br />
                <hr />
                <br />
              </div>
            ) : (
              <>
                <br />
                <hr />
                <br />
                <h2>No Local IPS Exposed (WebRTC Leaks) (Yet)...</h2>
                <br />
                <hr />
                <br />
              </>
            );
          })
        ) : (
          <>
            <br />
            <hr />
            <br />
            <h2>No Local IPS Exposed (WebRTC Leaks) (Yet)...</h2>
            <br />
            <hr />
            <br />
          </>
        )}
        <h2>Session Data:</h2>
        <br />
        <hr />
        <br />
        <table
          className="managewords__table"
          style={{
            width: '100%',
            textAlign: 'center',
            fontSize: '1.5rem',
          }}
        >
          <thead>
            <tr className="managewords__tr">
              <th className="managewords__th" style={{textAlign: 'center'}}>
                #
              </th>
              <th className="managewords__th" style={{textAlign: 'center'}}>
                Users Associated
              </th>
              <th className="managewords__th" style={{textAlign: 'center'}}>
                Unique SessionID
              </th>
              <th className="managewords__th" style={{textAlign: 'center'}}>
                Created AT
              </th>
              <th className="managewords__th" style={{textAlign: 'center'}}>
                Session Expiry
              </th>
              <th className="managewords__th" style={{textAlign: 'center'}}>
                Expired
              </th>
            </tr>
          </thead>
          <tbody
            style={{
              width: '100%',
              textAlign: 'center',
              fontSize: '1.5rem',
            }}
          >
            {sessionData.length ? (
              sessionData.map((x, i) => (
                <tr className="managewords__tr" key={i}>
                  <td className="managewords__td">{i + 1}</td>
                  <td className="managewords__td">{x.user.length}</td>
                  <td className="managewords__td">
                    <Link to={`/admin/sessions/${x.sessionID}`}>
                      {x.sessionID}
                    </Link>
                  </td>
                  <td className="managewords__td">
                    {new Date(x.createdAt)
                      .toLocaleDateString('en-us', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })
                      .replace(',', '')}{' '}
                    {new Date(x.createdAt).toLocaleTimeString('en-US')}
                  </td>
                  <td className="managewords__td">
                    {new Date(x.sessionExpiry)
                      .toLocaleDateString('en-us', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })
                      .replace(',', '')}{' '}
                    {new Date(x.sessionExpiry).toLocaleTimeString('en-US')}
                  </td>
                  <td className="managewords__td">
                    {(
                      new Date(x.sessionExpiry).getTime() < Date.now()
                    ).toString()}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>No active sessions (Yet)...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Analytics;
