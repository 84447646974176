import React, {useState, Fragment, useEffect} from 'react';
import AccountSidebar from '../components/AccountSidebar';
// import 'bootstrap/dist/css/bootstrap.min.css';

import axios from 'axios';
import config from '../config';
import LoggedInFooter from '../layout/LoggedInFooter';

const Goal = () => {
  const [message, setMessage] = useState('');
  const [goal, setGoal] = useState(0);
  const [activeGoal, setActiveGoal] = useState('');
  const [currentGoal, setCurrentGoal] = useState(null);
  useEffect(() => {
    const currentUser = localStorage.getItem('currentUser')
      ? localStorage.getItem('currentUser')
      : null;
    const id = JSON.parse(currentUser)._id;
    axios
      .get(`${config.REACT_APP_NODE_API_URL}/api/user/${id}`)
      .then(res => {
        const goalNo = res.data.data.dailyGoal;
        setCurrentGoal(res.data.data.dailyGoal);
        if (goalNo === 1) {
          setActiveGoal('basic');
        } else if (goalNo === 10) {
          setActiveGoal('casual');
        } else if (goalNo === 20) {
          setActiveGoal('regular');
        } else if (goalNo === 30) {
          setActiveGoal('serious');
        } else if (goalNo === 40) {
          setActiveGoal('intense');
        }
      })
      .catch(err => console.log(err));
  }, []);
  const handleOnClickGoal = (goalNumber, goalName) => {
    setGoal(goalNumber);
    setActiveGoal(goalName);
  };
  const setGoalFnc = e => {
    const currentUser = localStorage.getItem('currentUser')
      ? localStorage.getItem('currentUser')
      : null;
    const id = JSON.parse(currentUser)._id;
    e.preventDefault();
    axios
      .post(`${config.REACT_APP_NODE_API_URL}/api/user/update/${id}`, {
        dailyGoal: goal,
      })
      .then(res => {
        setMessage(`Goal: ${goal} word(s) updated`);
        setCurrentGoal(goal);

        setTimeout(() => {
          setMessage('');
        }, 2000);
      })
      .catch(err =>
        setMessage(
          err.response.data.message
            ? err.response.data.message
            : 'Something went wrong, Try again'
        )
      );
  };

  return (
    <Fragment>
      <section className="goal">
        <div className="goal-settings">
          {/* 1 */}
          <div className="daily-goals">
            <div className="daily-goals-heading">
              <h1>Daily Goal Settings</h1>
              <button
                className={`${
                  goal === 0 || goal === currentGoal
                    ? 'save-btn'
                    : 'save-btn save-btn-changed'
                }`}
                onClick={setGoalFnc}
              >
                SAVE CHANGES
              </button>
            </div>
            <p style={{color: '#6c757d', fontSize: '17px'}}>
              Coach here! Selecting a daily goal will help you stay motivated
              while learning a language. You can change your goal at any time.
            </p>
            <br />
            {message && <h2>{message}</h2>}
            <div className="daily-goals-card">
              <div
                style={{paddingTop: '1.6rem'}}
                // className="daily-goals-card-content"
                className={`${
                  activeGoal === 'basic'
                    ? 'daily-goals-card-content active-goal'
                    : 'daily-goals-card-content '
                } `}
                // onClick={() => setGoal(1)}
                onClick={() => handleOnClickGoal(1, 'basic')}
              >
                <h5>Basic</h5>
                <p>1 word per day</p>
              </div>
              <hr />

              <div
                // className="daily-goals-card-content"
                className={`${
                  activeGoal === 'casual'
                    ? 'daily-goals-card-content active-goal'
                    : 'daily-goals-card-content '
                } `}
                // onClick={() => setGoal(10)}
                onClick={() => handleOnClickGoal(10, 'casual')}
              >
                <h5>Casual</h5>
                <p>10 words per day</p>
              </div>
              <hr />

              <div
                // className="daily-goals-card-content"
                className={`${
                  activeGoal === 'regular'
                    ? 'daily-goals-card-content active-goal'
                    : 'daily-goals-card-content '
                } `}
                // onClick={() => setGoal(20)}
                onClick={() => handleOnClickGoal(20, 'regular')}
              >
                <h5>Regular</h5>
                <p>20 words per day</p>
              </div>
              <hr />

              <div
                // className="daily-goals-card-content"
                className={`${
                  activeGoal === 'serious'
                    ? 'daily-goals-card-content active-goal'
                    : 'daily-goals-card-content '
                } `}
                // onClick={() => setGoal(30)}
                onClick={() => handleOnClickGoal(30, 'serious')}
              >
                <h5>Serious</h5>
                <p>30 words per day</p>
              </div>
              <hr />

              <div
                // className="daily-goals-card-content"
                className={`${
                  activeGoal === 'intense'
                    ? 'daily-goals-card-content active-goal'
                    : 'daily-goals-card-content '
                } `}
                // onClick={() => setGoal(40)}
                onClick={() => handleOnClickGoal(40, 'intense')}
              >
                <h5>Intense</h5>
                <p>40 words per day</p>
              </div>
            </div>
          </div>

          {/* 2 */}
          <AccountSidebar />
        </div>
      </section>
      <LoggedInFooter />
    </Fragment>
  );
};

export default Goal;
