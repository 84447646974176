import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import {FaEnvelope, FaHome, FaPhoneAlt} from 'react-icons/fa';
import axios from 'axios';
import config from '../config';
import Footer from '../layout/Footer';
import LoggedInFooter from '../layout/LoggedInFooter';
import Navbar from '../layout/Navbar';

const ContactUs = props => {
  // eslint-disable-next-line react/prop-types
  const {id} = props;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleInput = e => {
    const {name, value} = e.currentTarget;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'contact':
        setContact(value);
        break;
      case 'subject':
        setSubject(value);
        break;
      case 'message':
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const handleContactUs = e => {
    e.preventDefault();
    axios
      .post(`${config.REACT_APP_NODE_API_URL}/api/user/tickets/create`, {
        name: name,
        email: email,
        contact: contact,
        subject: subject,
        message: message,
      })
      .then(() => {
        setAlert(true);
        setTimeout(() => {
          setAlert(false);
          window.location = '/contact';
        }, 2000);
      })
      .catch(() => {
        setErrorMessage(true);
        setTimeout(() => {
          setErrorMessage(false);
        }, 2000);
      });
  };

  return (
    <Fragment>
      <Navbar />
      <section className="contact-us">
        <div className="contact-us-content">
          <div className="contact-us-content-card">
            <h1>Contact Us</h1>
            <div className="contact-us-content-card-content">
              {/* Address */}
              <div className="contact-us-content-card-content-address">
                <h5>Address</h5>
                <p>We look-forward to learn about your language and culture.</p>
                {/* 1. Address */}
                <div className="address-content">
                  <div className="address-icon">
                    <span>
                      <FaHome />
                    </span>
                  </div>
                  <div className="address-text">
                    <p>
                      Trilingo (Alfred Technologies OPC Pvt. Ltd), Sushma
                      Niketan 613, Road 03, Ward 13, Lobin Bagaan, Niche Chowk,
                      Khunti 835210,Jharkhand
                    </p>
                  </div>
                </div>

                {/* 2. Phone */}
                <div className="address-content">
                  <div className="address-icon">
                    <span>
                      <FaPhoneAlt />
                    </span>
                  </div>
                  <div className="address-text">
                    <p>+91 91997 69697</p>
                  </div>
                </div>

                {/* 3. Email */}
                <div className="address-content">
                  <div className="address-icon">
                    <span>
                      <FaEnvelope />
                    </span>
                  </div>
                  <div className="address-text">
                    <p>info.trilingo@gmail.com</p>
                  </div>
                </div>
              </div>
              {/* Contact Form */}
              <div className="contact-us-content-card-content-form">
                <form onSubmit={handleContactUs}>
                  {alert ? (
                    <h5 style={{color: 'green'}}>Successfully Submitted</h5>
                  ) : (
                    <></>
                  )}
                  {errorMessage ? (
                    <h5 style={{color: 'red'}}>Error! Please submit again</h5>
                  ) : (
                    <></>
                  )}
                  <h5>Get In Touch</h5>
                  <input
                    className="contact-form-input"
                    type="text"
                    name="name"
                    placeholder="Name"
                    onChange={handleInput}
                    required
                  />
                  <input
                    className="contact-form-input"
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleInput}
                    required
                  />
                  <input
                    className="contact-form-input"
                    type="text"
                    name="contact"
                    placeholder="Contact"
                    onChange={handleInput}
                    required
                  />
                  <input
                    className="contact-form-input"
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    onChange={handleInput}
                    required
                  />
                  <textarea
                    className="contact-form-input"
                    name="message"
                    placeholder="Message"
                    onChange={handleInput}
                    required
                  ></textarea>
                  <button>Send</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {id ? <LoggedInFooter /> : <Footer />}
    </Fragment>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;

  return {
    id,
    error: state.auth.authError,
  };
};

export default connect(mapStateToProps)(ContactUs);
