/* eslint-disable react/prop-types */
import React, {useState, useEffect, Fragment} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {signOutUser} from '../store/actions/authActions';
import config from '../config';
import i18n from 'i18next';
import {BiTestTube} from 'react-icons/bi';
import {FaBell, FaUserGraduate} from 'react-icons/fa';
import {useToasts} from 'react-toast-notifications';
import {Link} from 'react-router-dom';
import {notifyUser} from '../service/helper/userHelper';

const Navbar = props => {
  // eslint-disable-next-line no-unused-vars
  const [, setCurrLang] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [__, setNavbarAvatar] = useState(
    'https://s3.amazonaws.com/37assets/svn/765-default-avatar.png'
  );
  const [profileName, setProfileName] = useState('');
  const isAdmin =
    window.location.pathname.includes('admin') ||
    window.location.pathname.includes('moderator');
  const signOut = () => {
    props.signOutUser();
  };
  const {id} = props;
  useEffect(() => {
    const languageData = localStorage.getItem('languageData');
    if (languageData && id) {
      axios({
        method: 'get',
        url: `${config.REACT_APP_NODE_API_URL}/api/user/${id}`,
      })
        .then(res => {
          localStorage.setItem('languageData', res.data.data.languages[0]);
          console.log(languageData);
          setCurrLang(res.data.data.languages[0]);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setCurrLang(languageData);
    }
  }, [id]);
  const [tabData, setTabData] = useState([]);
  /**
   * @author NevetsKuro <stevenfernz96@gmail.com>
   * @description Fetch profile data for navbar
   */
  useEffect(() => {
    const currentLanguage = localStorage.getItem('changeLanguage');
    // 2. Selecting default value for language
    if (document.getElementById('changeLanguage')) {
      document.getElementById('changeLanguage').value = currentLanguage
        ? currentLanguage
        : 'hin';
    }
    if (id) {
      axios({
        method: 'get',
        url: `${config.REACT_APP_NODE_API_URL}/api/user/${id}`,
      })
        .then(res => {
          // console.log(res.data.data)
          setProfileName(res.data.data.name);
          if (res.data.data.photoUrl) {
            setNavbarAvatar(res.data.data.photoUrl);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [id]);
  const {addToast} = useToasts();
  /**
   * @author NevetsKuro <stevenfernz96@gmail.com>
   * @description fetch notification from backend and pop up new notifications
   */
  useEffect(() => {
    if (id) {
      axios({
        method: 'get',
        url: `${config.REACT_APP_NODE_API_URL}/api/user/notifications/${id}`,
      })
        .then(res => {
          setTabData(res.data.data?.notificationsData);
          if (res.data.data?.notificationsData?.some(x => x.seen === false)) {
            notifyUser(
              addToast,
              res.data.data?.notificationsData.filter(x => x.seen === false)
            );
            axios({
              method: 'post',
              url: `${config.REACT_APP_NODE_API_URL}/api/user/notifications/${
                JSON.parse(localStorage.getItem('currentUser'))?._id
              }`,
              data: {
                notificationsData: res.data.data?.notificationsData?.map(x => ({
                  ...x,
                  seen: true,
                })),
              },
            }).then(res => {
              console.log('Updated notifications', res);
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [id, addToast]);
  const changeLanguage = e => {
    // console.log(
    //   document.getElementById("changeLanguage").selectedOptions[0].value
    // );
    localStorage.setItem(
      'changeLanguage',
      document.getElementById('changeLanguage').selectedOptions[0].value
    );
    i18n.changeLanguage(
      document.getElementById('changeLanguage').selectedOptions[0].value
    );
    window.location.reload();
  };
  return (
    <div>
      {/* Navigation starts now */}
      <div
        style={{height: !id ? 'auto' : '', padding: !id ? 0 : ''}}
        className="nav"
        id="nav"
      >
        {!id ? (
          <Link className="nav__logo" to="/">
            <img
              src="/assets/logo-black.png"
              className="nav__logo__image"
              alt="trilingo logo"
            />
          </Link>
        ) : (
          <> </>
        )}
        {id ? (
          <>
            {/* <a className="nav__logo nav__learn" href="/learn"> */}
            <Link className="nav__logo nav__learn" to="/learn">
              {/* <img src="/assets/logo-black.png" className="nav__logo__image" />{' '} */}
              {window.location.pathname === '/learn' ? (
                <span style={{color: '#000'}}>
                  <FaUserGraduate className="navigation_icon" />
                  Learn
                </span>
              ) : (
                <Fragment>
                  <FaUserGraduate className="navigation_icon" />
                  Learn
                </Fragment>
              )}
            </Link>
            <Link to="" className="nav__logo nav__quiz">
              {window.location.pathname === '/quiz' ? (
                <span style={{display: 'none'}}>
                  {/* <GrTestDesktop className="navigation_icon" /> */}
                  <BiTestTube className="navigation_icon" />
                  Quiz
                </span>
              ) : (
                <Fragment>
                  {/* <GrTestDesktop
                className="navigation_icon"
              /> */}
                  <span style={{display: 'none'}}>
                    <BiTestTube className="navigation_icon" />
                    Quiz
                  </span>
                </Fragment>
              )}
            </Link>
          </>
        ) : (
          <></>
        )}
        <nav className="navigation" id="myNav">
          {!isAdmin && id ? (
            <ul className="navigation__nav">
              {id && !isAdmin ? (
                <div className="navigation__auth">
                  {/* <li className="navigation_item">
                    <AiFillCrown className="navigation_icon" />
                  </li>
                  <li className="navigation_item">
                    <AiFillFire className="navigation_icon" />
                  </li>
                  <li className="navigation_item">
                    <RiVipDiamondFill className="navigation_icon" />
                  </li> */}
                  {/* Right icons */}
                  {/* <span
                    className="right-nav-icons
                  "
                  >
                    <li>
                      <FiThumbsUp />
                      <span>65</span>
                    </li>
                    <li>
                      <FiBook />
                      <span>65</span>
                    </li>
                    <li>
                      <GiPencilRuler />
                      <span>65</span>
                    </li>
                    <li>
                      <FiHeadphones />
                      <span>65</span>
                    </li>
                    <li>
                      <FiMic />
                      <span>65</span>
                    </li>
                  </span> */}
                  {/* Right icons end */}
                  {/* <li>
                    <AiFillCrown /> 65
                  </li> */}
                  <span className="profile-detail">
                    <span className="profile-name">
                      {profileName ? (
                        <li>{profileName.split(' ')[0]}</li>
                      ) : (
                        <li>...</li>
                      )}
                    </span>
                    <div className="dropdown">
                      <li className="navigation_profile">
                        {profileName ? (
                          <Fragment>{profileName.charAt(0)}</Fragment>
                        ) : (
                          <Fragment>...</Fragment>
                        )}
                      </li>
                      <div className="dropdown-content">
                        {/* <a href="/edit">{i18n.t('Edit Profile')}</a>
                      <hr /> */}
                        <span className="large-device-dropdown-content">
                          <Link to="/profile">{i18n.t('Profile')}</Link>
                          <hr />
                          <Link to="/account">{i18n.t('Settings')}</Link>
                          <hr />
                        </span>
                        <span className="small-device-dropdown-content">
                          <Link to="/account">{i18n.t('Account')}</Link>
                          <hr />
                          <Link to="/settings/language">
                            {i18n.t('Language Learning')}
                          </Link>
                          <hr />
                          <Link to="/profile">{i18n.t('Profile')}</Link>
                          <hr />
                          <Link to="/settings/password">
                            {i18n.t('Password')}
                          </Link>
                          <hr />
                          <Link to="/learn/goal">{i18n.t('Goal')}</Link>
                          <hr />
                        </span>
                        <a href="/" onClick={signOut}>
                          {i18n.t('Sign out')}
                        </a>
                        <hr />
                        {/* <a href="/activity">{i18n.t('Activity')}</a> */}
                      </div>
                    </div>
                    <div className="notification-bell">
                      <FaBell className="notification-bell-icon" />
                      <div className="notification-dropdown">
                        {tabData.length > 0 ? (
                          tabData.slice(0, 5).map((v, i) => (
                            <div key={i} className="notification-item">
                              <div>
                                <FaBell color={'#fcfcfc'} />
                              </div>
                              <div>{v.title}</div>
                            </div>
                          ))
                        ) : (
                          <div className="notification-title">
                            <FaBell /> 0 notifications
                          </div>
                        )}
                        <div className="notification-all">
                          <Link to="/notifications-summary">
                            <p>See all notifications</p>
                          </Link>
                        </div>
                      </div>
                      {/* )} */}
                    </div>
                  </span>
                </div>
              ) : (
                ''
              )}
            </ul>
          ) : (
            // Hide language change for now (may need in the future)
            <ul style={{visibility: 'hidden'}} className="navigation__nav">
              <h1 className="navigation__nav__select__label">
                Website Language
              </h1>
              <select
                className="navigation__nav__select"
                id="changeLanguage"
                onChange={changeLanguage}
              >
                <option value="hin" className="navigation__nav__select__option">
                  Hindi
                </option>
                <option value="en" className="navigation__nav__select__option">
                  English
                </option>
              </select>
            </ul>
          )}
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;
  return {
    id,
    isLangDataExist: state.auth.isLangDataExist,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOutUser: () => dispatch(signOutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
