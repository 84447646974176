/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

/* Default lang is set to hindi do change on other quiz files aswell if needed*/

const ManageQuestions = props => {
  const [search, setSearch] = useState('');
  const [questions, setQuestions] = useState([]);
  const [tfquestions, setTfQuestions] = useState([]);
  const [fbQuestions, setfbQuestions] = useState([]);
  const [mpQuestions, setMpQuestions] = useState([]);
  const word = props.match.params.word;
  const [modLang] = useState(
    localStorage.getItem('modLang') ? localStorage.getItem('modLang') : null
  );

  useEffect(() => {
    axios
      .get(
        `${config.REACT_APP_NODE_API_URL}/api/quiz/get/t-f/hin-${modLang}/${word}`
      )
      .then(res => {
        // console.log(res.data);
        setTfQuestions(res.data.data);
      })
      .catch(err => console.log(err));

    axios
      .get(
        `${config.REACT_APP_NODE_API_URL}/api/quiz/get-quiz-word-lang-type/hin-${modLang}/${word}/3`
      )
      .then(res => {
        setfbQuestions(res.data.data);
      })
      .catch(err => console.log(err));
  }, [modLang, word]);

  useEffect(() => {
    axios
      .get(
        `${config.REACT_APP_NODE_API_URL}/api/quiz/get-quiz-word-lang-type/hin-${modLang}/${word}/4`
      )
      .then(res => {
        console.log(res.data.data);
        setMpQuestions(res.data.data);
      })
      .catch(err => console.log(err));

    axios
      .get(
        `${config.REACT_APP_NODE_API_URL}/api/quiz/get-quiz-word-lang-type/hin-${modLang}/${word}/1`
      )
      .then(res => {
        // console.log(res.data.data);
        setQuestions(res.data.data);
      })
      .catch(err => console.log(err));
  }, [modLang, word]);

  const searchBar = () => {
    return (
      <div
        style={{
          display: 'flex',
          width: '80rem',
          margin: '2rem auto',
          height: '3rem',
        }}
      >
        <input
          type="text"
          className="form-control"
          name="search_query"
          id="search_query"
          style={{flex: 1, fontSize: '1.5rem'}}
          placeholder="Search for word"
          onChange={e => setSearch(e.target.value)}
        />
      </div>
    );
  };

  const filteredQuestions = questions.filter(qt => {
    if (typeof qt.question === 'string') {
      return qt.question.toLowerCase().includes(search.toLowerCase());
    }
    return word;
  });

  const deleteTfQuestion = qstId => {
    axios
      .delete(`${config.REACT_APP_NODE_API_URL}/api/quiz/delete/t-f/${qstId}`)
      .then(res => {
        console.log('word deleted');
        window.location.reload();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const language =
    modLang === 'mun'
      ? ' Mundari'
      : modLang === 'kur'
      ? ' Kuruk'
      : modLang === 'san'
      ? ' Santhali'
      : modLang === 'hoc'
      ? ' Ho'
      : modLang === 'khr'
      ? ' Kharia'
      : modLang === 'bhb'
      ? ' Bhili'
      : modLang === 'gon'
      ? ' Gondi'
      : modLang === 'tcy'
      ? ' Tulu'
      : modLang;

  return (
    <div style={{padding: '18rem', fontSize: '1.2rem'}}>
      <Link to="/moderator/manage-quiz" className="btn btn-sm">
        Back
      </Link>
      <Link
        to={{
          pathname: `/moderator/add-question/${word}`,
          state: {category: props.location.state.category},
        }}
        className="btn btn-sm"
        style={{float: 'right'}}
      >
        Add Question
      </Link>
      <Link
        to={{
          pathname: `/moderator/add-tf/${word}`,
          state: {category: props.location.state.category},
        }}
        className="btn btn-sm"
        style={{float: 'right', marginRight: '1rem'}}
      >
        Add True/False
      </Link>
      <h1 style={{textAlign: 'center'}}>Language :{language}</h1>
      <h2 style={{textAlign: 'center'}}>Word : {word}</h2>
      <br />
      <h3 style={{textAlign: 'center', color: 'red'}}>
        Ps: refresh the page if question are not visible
      </h3>
      <br />
      {searchBar()}
      <div>
        <h2>MCQs</h2>
        <table className="managewords__table">
          <thead>
            <tr className="managewords__tr">
              <th className="managewords__th">#</th>
              <th className="managewords__th">Question</th>
              <th className="managewords__th">Option 1</th>
              <th className="managewords__th">Option 2</th>
              <th className="managewords__th">Option 3</th>
              <th className="managewords__th">Option 4</th>
              <th className="managewords__th">Right answer</th>
              <th className="managewords__th">Category</th>
              <th className="managewords__th">Language</th>
              <th className="managewords__th">Word</th>
              <th className="managewords__th">Actions</th>
            </tr>
          </thead>
          <tbody>
            {questions.length > 0 ? (
              filteredQuestions.map((question, idx) => (
                <tr key={question._id} className="managewords__tr">
                  <td className="managewords__td">{idx + 1}</td>
                  <td className="managewords__td">{question.question}</td>
                  <td className="managewords__td">{question.options[0]}</td>
                  <td className="managewords__td">{question.options[1]}</td>
                  <td className="managewords__td">{question.options[2]}</td>
                  <td className="managewords__td">{question.options[3]}</td>
                  <td className="managewords__td">{question.right_ans}</td>
                  <td className="managewords__td">{question.question_level}</td>
                  <td className="managewords__td">{question.language}</td>
                  <td className="managewords__td">{question.word}</td>
                  <td className="managewords__td">
                    <Link to={`/moderator/edit-question/${question._id}`}>
                      Edit
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>No questions found..</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <div>
        <h2>True False</h2>
        <table>
          <thead>
            <tr className="managewords__tr">
              <th className="managewords__th">#</th>
              <th className="managewords__th">Question</th>
              <th className="managewords__th">Answer</th>
              <th className="managewords__th">Level/Category</th>
              <th className="managewords__th">Action</th>
            </tr>
          </thead>
          <tbody>
            {tfquestions && tfquestions.length > 0 ? (
              tfquestions.map((qst, idx) => {
                return (
                  <tr className="managewords__tr" key={qst._id}>
                    <td className="managewords__td">{idx + 1}</td>
                    <td className="managewords__td">{qst.question}</td>
                    <td className="managewords__td">
                      {qst.right_ans ? 'true' : 'false'}
                    </td>
                    <td className="managewords__td">{qst.question_level}</td>
                    <td>
                      <Link to={`/moderator/update/tf-quiz/${qst._id}`}>
                        Edit
                      </Link>
                      <button onClick={() => deleteTfQuestion(qst._id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7}>No questions found..</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <div>
        <h2>Fill in the blanks</h2>
        <table className="managewords__table">
          <thead>
            <tr className="managewords__tr">
              <th className="managewords__th">#</th>
              <th className="managewords__th">Question</th>
              <th className="managewords__th">Option 1</th>
              <th className="managewords__th">Option 2</th>
              <th className="managewords__th">Option 3</th>
              <th className="managewords__th">Option 4</th>
              <th className="managewords__th">Right answer</th>
              <th className="managewords__th">Category</th>
              <th className="managewords__th">Language</th>
              <th className="managewords__th">Word</th>
              <th className="managewords__th">Actions</th>
            </tr>
          </thead>
          <tbody>
            {fbQuestions.length > 0 ? (
              fbQuestions.map((question, idx) => (
                <tr key={question._id} className="managewords__tr">
                  <td className="managewords__td">{idx + 1}</td>
                  <td className="managewords__td">{question.question}</td>
                  <td className="managewords__td">{question.options[0]}</td>
                  <td className="managewords__td">{question.options[1]}</td>
                  <td className="managewords__td">{question.options[2]}</td>
                  <td className="managewords__td">{question.options[3]}</td>
                  <td className="managewords__td">{question.right_ans}</td>
                  <td className="managewords__td">{question.question_level}</td>
                  <td className="managewords__td">{question.language}</td>
                  <td className="managewords__td">{question.word}</td>
                  <td className="managewords__td">
                    <Link to={`/moderator/edit-question/${question._id}`}>
                      Edit
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>No questions found..</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <div>
        <h2>Match the sets</h2>
        <table className="managewords__table">
          <thead>
            <tr className="managewords__tr">
              <th className="managewords__th">#</th>
              <th className="managewords__th">Question</th>
              <th className="managewords__th">Option 1</th>
              <th className="managewords__th">Option 2</th>
              <th className="managewords__th">Option 3</th>
              <th className="managewords__th">Option 4</th>
              <th className="managewords__th">Right answer</th>
              <th className="managewords__th">Category</th>
              <th className="managewords__th">Language</th>
              <th className="managewords__th">Word</th>
              <th className="managewords__th">Actions</th>
            </tr>
          </thead>
          <tbody>
            {mpQuestions.length > 0 ? (
              mpQuestions.map((question, idx) => (
                <tr key={question._id} className="managewords__tr">
                  <td className="managewords__td">{idx + 1}</td>
                  <td className="managewords__td">{question.question}</td>
                  <td className="managewords__td">{question.options[0]}</td>
                  <td className="managewords__td">{question.options[1]}</td>
                  <td className="managewords__td">{question.options[2]}</td>
                  <td className="managewords__td">{question.options[3]}</td>
                  <td className="managewords__td">{question.right_ans}</td>
                  <td className="managewords__td">{question.question_level}</td>
                  <td className="managewords__td">{question.language}</td>
                  <td className="managewords__td">{question.word}</td>
                  <td className="managewords__td">
                    <Link to={`/moderator/edit-question/${question._id}`}>
                      Edit
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>No questions found..</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageQuestions;
