/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {FaChevronRight, FaChevronLeft} from 'react-icons/fa';
import {GrClose} from 'react-icons/gr';

/**
 * @author NevetsKuro <stevenfernz96@gmail.com>
 * @function GreetUser
 * @description used as a child component Greeting Card for new users
 * @param {Function} closeModal - to close the Parent Modal
 * @returns {import('react').ReactFragment} JSX element
 */
const GreetUser = ({closeModal}) => {
  const [activeCarousel, setActiveCarousel] = useState(1);
  /**
   * @author NevetsKuro <stevenfernz96@gmail.com>
   * @function changeSlide
   * @param {string} slideTo - text for which side to slide the carousel
   * @description to change slide depending of the parameter provided
   */
  const changeSlide = slideTo => {
    if (slideTo === 'left' && activeCarousel > 1) {
      setActiveCarousel(activeCarousel - 1);
    } else if (slideTo === 'right' && activeCarousel < 5) {
      setActiveCarousel(activeCarousel + 1);
    }
  };
  /**
   * @function render
   * @returns {import('react').ReactFragment} -
   */
  return (
    <div className="modal-container">
      <div className="backdrop">
        <div className="greet-user-body">
          <div className="greet-user-top-body">
            <div className="close" onClick={closeModal}>
              <GrClose />
            </div>

            <img
              src="/assets/Gems/All-gems.gif"
              alt="introducing all new gems for trilingo"
              className="background-image"
            />

            <h2 className="greet-user-top-body-heading">
              {' '}
              Introducing New Gems!!
            </h2>
          </div>
          <div className="greet-user-bottom-body ">
            <div className="greet-user-text">
              {activeCarousel !== 1 && (
                <div
                  className="greet-user-bottom-body-left"
                  onClick={() => changeSlide('left')}
                >
                  <FaChevronLeft />
                </div>
              )}
              {activeCarousel !== 5 && (
                <div
                  className="greet-user-bottom-body-right"
                  onClick={() => changeSlide('right')}
                >
                  <FaChevronRight />
                </div>
              )}
              <div
                className={
                  'greet-user-text-item ' +
                  (activeCarousel === 1 ? 'active' : '')
                }
              >
                <img
                  src="/assets/Gems/Earth.png"
                  alt="earth"
                  width={56}
                  height={56}
                  className="noselect"
                />
                <p className="noselect">
                  Earth represents trust. It is heavy, thick and earthy yellow.
                  People rich in earth feature thick waist, big nose, bright
                  eyes, graceful eyebrows and loud voice; they are filial,
                  sincere, generous and decisive. People with too much earth are
                  rigid, stupid, clumsy, introverted and quiet while those in
                  lack of earth are vicious, grumpy, disloyal and unreasonable,
                  featuring low nose and flat face.
                </p>
              </div>
              <div
                className={
                  'greet-user-text-item ' +
                  (activeCarousel === 2 ? 'active' : '')
                }
              >
                <img
                  src="/assets/Gems/Water.png"
                  alt="water"
                  width={56}
                  height={56}
                  className="noselect"
                />
                <p className="noselect">
                  Water represents wisdom. It is kind, smart and black. People
                  rich in water feature blackish complexion, polite speech and
                  deep consideration; they are resourceful and excel others in
                  knowledge. Those with too much water carry tales and are
                  greedy and lustful while those in lack of water are short,
                  moody, timid and foolhardy, and change frequently.
                </p>
              </div>
              <div
                className={
                  'greet-user-text-item ' +
                  (activeCarousel === 3 ? 'active' : '')
                }
              >
                <img
                  src="/assets/Gems/Fire.png"
                  alt="fire"
                  width={56}
                  height={56}
                  className="noselect"
                />
                <p className="noselect">
                  Fire represents courtesy. It is hot-tempered, polite and red.
                  People rich in fire feature small head, long feet, thick
                  eyebrows and small ears; they are vigorous, courteous,
                  respectful, plain yet short-tempered. People lacking of fire,
                  with yellowish faces, are absurd, cunning, and jealous and
                  they tend to begin well but end badly.
                </p>
              </div>
              <div
                className={
                  'greet-user-text-item ' +
                  (activeCarousel === 4 ? 'active' : '')
                }
              >
                <img
                  src="/assets/Gems/Leaf.png"
                  alt="leaf"
                  width={56}
                  height={56}
                  className="noselect"
                />
                <p className="noselect">
                  Wood represents benevolence. It is straight, mild and green.
                  People rich in wood are beautiful, slim and sharp-toothed,
                  featuring pretty hair and off-white complexion; they are
                  compassionate, benevolent, amiable, easygoing, self-contained,
                  generous and plain. People lacking of wood are tall, thin,
                  intolerant, jealous, not benevolent and loose in hair.
                </p>
              </div>
              <div
                className={
                  'greet-user-text-item ' +
                  (activeCarousel === 5 ? 'active' : '')
                }
              >
                <img
                  src="/assets/Gems/Sky.png"
                  alt="sky"
                  width={56}
                  height={56}
                  className="noselect"
                />
                <p className="noselect">
                  Metal represents righteousness. It is firm and white. People
                  rich in metal feature square and white face, high eyebrows,
                  deep eyes, good health and spirit; they are resolute,
                  decisive, generous in aiding needy people and have a sense of
                  shame. Those with too much metal are greedy, heartless have
                  valor but lack strategy while those in lack of metal are thin,
                  sharp-tongued, lascivious, bloody, mean and greedy.
                </p>
              </div>
            </div>
            <div className="skip-button" onClick={closeModal}>
              {activeCarousel !== 5 ? (
                <>
                  Skip
                  <FaChevronRight />
                </>
              ) : (
                <>
                  Start <FaChevronRight />
                </>
              )}
            </div>
            <div className="carousel-dot-buttons">
              {[1, 2, 3, 4, 5].map((x, i) => (
                <div
                  key={i}
                  className={
                    'carousel-span ' + (activeCarousel === x ? 'active' : '')
                  }
                  onClick={() => {
                    setActiveCarousel(x);
                  }}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GreetUser;
