import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import config from '../../config';

const AdminDashboard = () => {
  const [isAllowed, setIsAllowed] = useState(false);
  const [data, setData] = useState([]);
  const id = localStorage.getItem('currentAdmin');
  useEffect(() => {
    const token = localStorage.getItem('adminToken')
      ? localStorage.getItem('adminToken')
      : null;
    axios
      .get(`${config.REACT_APP_NODE_API_URL}/api/admin/tickets`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        console.log(res.data.data);
        setData(res.data.data);
      })
      .catch(console.error);
  }, []);
  useEffect(() => {
    const adminID = localStorage.getItem('currentAdmin');
    axios({
      method: 'get',
      url: `${config.REACT_APP_NODE_API_URL}/api/admin/user/get-user/${adminID}`,
    })
      .then(res => {
        const {isApproved} = res.data.data;
        setIsAllowed(isApproved);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  const handleSignOut = () => {
    localStorage.removeItem('currentAdmin');
    localStorage.removeItem('adminToken');
    window.location.reload();
  };
  if (!id) return <Redirect to="/admin" />;
  return (
    <section className="admindashboard">
      <div className="admindashboard__header">
        <h1 className="admindashboard__header__heading heading-primary--main u-center-text">
          Welcome to Admin Dashboard
        </h1>
      </div>
      {isAllowed ? (
        <div className="admindashboard__content">
          <button className="btn btn-md" onClick={handleSignOut}>
            Logout
          </button>
          <div className="admindashboard__content__actioncards">
            <a
              href="/admin/approve-instructor-requests"
              className="admindashboard__content__link"
            >
              <div className="admindashboard__content__actioncards__item">
                <h3 className="heading-secondary--main admindashboard__content__actioncards__item__heading">
                  Approve Instructor Requests
                </h3>
                <p className="heading-secondary--sub admindashboard__content__actioncards__item__subheading">
                  You can approve instructor requests and add them into Trilingo
                  family.
                </p>
              </div>
            </a>
            <a
              href="/admin/add-new-language"
              className="admindashboard__content__link"
            >
              <div className="admindashboard__content__actioncards__item">
                <h3 className="heading-secondary--main admindashboard__content__actioncards__item__heading">
                  Languages
                </h3>
                <p className="heading-secondary--sub admindashboard__content__actioncards__item__subheading">
                  Add New Language
                </p>
              </div>
            </a>
            <a href="/admin/tickets" className="admindashboard__content__link">
              <div className="admindashboard__content__actioncards__item">
                <h3
                  className="heading-secondary--main admindashboard__content__actioncards__item__heading"
                  style={{textAlign: 'center'}}
                >
                  Check Tickets {data.length ? `(${data.length})` : ''}
                </h3>
                <p className="heading-secondary--sub admindashboard__content__actioncards__item__subheading">
                  Check tickets created by users
                </p>
              </div>
            </a>
            <a
              href="/admin/user-analytics"
              className="admindashboard__content__link"
            >
              <div className="admindashboard__content__actioncards__item">
                <h3
                  className="heading-secondary--main admindashboard__content__actioncards__item__heading"
                  style={{textAlign: 'center'}}
                >
                  User Analytics
                </h3>
                <p className="heading-secondary--sub admindashboard__content__actioncards__item__subheading">
                  Check information about users here
                </p>
              </div>
            </a>
            <a
              href="/admin/edit-courses"
              className="admindashboard__content__link"
            >
              <div className="admindashboard__content__actioncards__item">
                <h3
                  className="heading-secondary--main admindashboard__content__actioncards__item__heading"
                  style={{textAlign: 'center'}}
                >
                  Edit Courses
                </h3>
                <p className="heading-secondary--sub admindashboard__content__actioncards__item__subheading">
                  Edit courses and their information
                </p>
              </div>
            </a>
            <a href="/admin/sessions" className="admindashboard__content__link">
              <div className="admindashboard__content__actioncards__item">
                <h3
                  className="heading-secondary--main admindashboard__content__actioncards__item__heading"
                  style={{textAlign: 'center'}}
                >
                  Session Analytics
                </h3>
                <p className="heading-secondary--sub admindashboard__content__actioncards__item__subheading">
                  Check session related information
                </p>
              </div>
            </a>
          </div>
        </div>
      ) : (
        <h1 className="admindashboard__header__heading heading-secondary--main u-center-text">
          You{"'"}re not authorized to be an admin yet.
        </h1>
      )}
    </section>
  );
};

export default AdminDashboard;
