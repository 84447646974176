import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

const ModDashboard = () => {
  const [isAllowed, setIsAllowed] = useState(false);
  const [data, setData] = useState([]);
  const [id] = useState(localStorage.getItem('currentAdmin'));
  const [modName] = useState(localStorage.getItem('moderator'));
  const [modLang] = useState(localStorage.getItem('modLang'));
  useEffect(() => {
    const token = localStorage.getItem('adminToken')
      ? localStorage.getItem('adminToken')
      : null;
    axios
      .get(
        `${config.REACT_APP_NODE_API_URL}/api/admin/mod/reports/${modLang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(res => {
        console.log(res.data.data);
        setData(res.data.data);
      })
      .catch(console.error);
  }, [modLang]);
  useEffect(() => {
    axios({
      method: 'get',
      url: `${config.REACT_APP_NODE_API_URL}/api/admin/user/get-user/${id}`,
    })
      .then(res => {
        setIsAllowed(res.data.data.isApproved);
      })
      .catch(err => {
        console.log(err);
      });
  }, [id]);
  const handleSignout = () => {
    localStorage.removeItem('currentAdmin');
    localStorage.removeItem('adminToken');
    window.location.reload();
  };
  const language =
    modLang === 'mun'
      ? ' Mundari'
      : modLang === 'kur'
      ? ' Kuruk'
      : modLang === 'san'
      ? ' Santhali'
      : modLang === 'hoc'
      ? ' Ho'
      : modLang === 'khr'
      ? ' Kharia'
      : modLang === 'bhb'
      ? ' Bhili'
      : modLang === 'gon'
      ? ' Gondi'
      : modLang === 'tcy'
      ? ' Tulu'
      : modLang;
  if (!id) return <Redirect to="/moderator" />;
  return (
    <section className="moddashboard">
      <div className="moddashboard__header">
        <h1 className="moddashboard__header__heading heading-primary--main u-center-text">
          Welcome {modName}
        </h1>
        <h2 className="moddashboard__header__heading heading-secondary--main u-center-text">
          Language :{language}
        </h2>
      </div>
      {isAllowed ? (
        <>
          <div className="moddashboard__content">
            <div className="moddashboard__content__card">
              <a
                href="/moderator/add-category"
                className="moddashboard__content__link"
              >
                <div className="moddashboard__content__card__item">
                  <h3 className="heading-secondary--main moddashboard__content__card__item__heading">
                    Add Category
                  </h3>
                  <p className="heading-secondary--sub moddashboard__content__card__item__subheading">
                    Add levels to language
                  </p>
                </div>
              </a>
              <a
                href="/moderator/add-word"
                className="moddashboard__content__link"
              >
                <div className="moddashboard__content__card__item">
                  <h3 className="heading-secondary--main moddashboard__content__card__item__heading">
                    Add New Words
                  </h3>
                  <p className="heading-secondary--sub moddashboard__content__card__item__subheading">
                    Start adding new words to languages
                  </p>
                </div>
              </a>
              <a
                href="/moderator/manage-words"
                className="moddashboard__content__link"
              >
                <div className="moddashboard__content__card__item">
                  <h3 className="heading-secondary--main moddashboard__content__card__item__heading">
                    Edit Words
                  </h3>
                  <p className="heading-secondary--sub moddashboard__content__card__item__subheading">
                    Check and fix words issue here
                  </p>
                </div>
              </a>
            </div>
            <div className="moddashboard__content__card">
              <a
                href="/moderator/manage-quiz"
                className="moddashboard__content__link"
              >
                <div className="moddashboard__content__card__item">
                  <h3 className="heading-secondary--main moddashboard__content__card__item__heading">
                    Add Quiz
                  </h3>
                  <p className="heading-secondary--sub moddashboard__content__card__item__subheading">
                    Add or Fix questions here
                  </p>
                </div>
              </a>
              <a
                href="/moderator/reshuffle-category/"
                className="moddashboard__content__link"
              >
                <div className="moddashboard__content__card__item">
                  <h3
                    className="heading-secondary--main moddashboard__content__card__item__heading"
                    style={{textAlign: 'center'}}
                  >
                    Reshuffle Categories
                  </h3>
                  <p className="heading-secondary--sub moddashboard__content__card__item__subheading">
                    Check and fix category issues here
                  </p>
                </div>
              </a>
              <a
                href="/moderator/reports/"
                className="moddashboard__content__link"
              >
                <div className="moddashboard__content__card__item">
                  <h3
                    className="heading-secondary--main moddashboard__content__card__item__heading"
                    style={{textAlign: 'center'}}
                  >
                    Check Reports {data.length ? `(${data.length})` : ''}
                  </h3>
                  <p className="heading-secondary--sub moddashboard__content__card__item__subheading">
                    Check translation reports created by users here
                  </p>
                </div>
              </a>
            </div>
          </div>
          <button
            className="btn btn-sm"
            onClick={handleSignout}
            style={{margin: '0 0 5rem 18rem'}}
          >
            Logout
          </button>
        </>
      ) : (
        <h1 className="moddashboard__header__heading heading-secondary--main u-center-text">
          You are not authorized to be an instructor yet.
        </h1>
      )}
    </section>
  );
};

export default ModDashboard;
