/* eslint-disable react/prop-types */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {FaFontAwesomeFlag} from 'react-icons/fa';
import axios from 'axios';
import ProcessBars from '../components/ProcessBars';
import {knowTheWord, dontKnowTheWord} from '../utils/Algorithm';
import config from '../config';
import Modal from '../components/Modal';
import ReportModal from '../components/ReportModal';

/**
 * @class VocabCards
 * @augments Component
 */
class VocabCards extends Component {
  /**
   * @class VocabCards
   * @param {any} props - Props passed to the component
   */
  constructor(props) {
    super(props);
    this.state = {
      vocabList: [],
      flipCard: false,
      currentQuestion: 0,
      nextLvl: false,
      mastered: 0,
      wordInMastered: 0,
      learning: 0,
      wordInLearning: 0,
      reviewing: 0,
      wordInReviewing: 0,
      totalWords: 0,
      masteredPer: 0,
      reviewingPer: 0,
      learningPer: 0,
      availContinue: true,
      levelName: this.props.match.params.category,
      hasQuiz: false,
      showModal: false,
    };
    this.fetchProgressData = this.fetchProgressData.bind(this);
    this.handleKnowTheWord = this.handleKnowTheWord.bind(this);
    this.handleDoNotKnowTheWord = this.handleDoNotKnowTheWord.bind(this);
    this.flipCard = this.flipCard.bind(this);
    this.goToNextLevel = this.goToNextLevel.bind(this);
    this.handleContinue = this.handleContinue.bind(this);
  }

  /**
   * @function componentDidMount
   */
  componentDidMount() {
    this.fetchProgressData(this.state.levelName);
    const langData = localStorage.getItem('languageData');
    const learningData = langData && langData.split('-')[1];
    axios({
      method: 'post',
      url: `${config.REACT_APP_NODE_API_URL}/api/user/limits`,
      data: {
        id: this.props.id,
        level: this.state.levelName,
        languageCode: localStorage.getItem('languageData').split('-')[1],
      },
    })
      .then(res => {
        if (res.data.limited) {
          return window.location.replace('/verify');
        }
      })
      .catch(err => {
        console.log(err);
      });
    axios({
      method: 'get',
      url: `${config.REACT_APP_NODE_API_URL}/api/algo/get-leitner-algorithm-data/${this.props.id}/${this.state.levelName}/${learningData}`,
    }).then(res => {
      if (res.data.success) {
        this.setState({vocabList: res.data.data});
      } else {
        const user = JSON.parse(localStorage.getItem('currentUser'));
        axios
          .post(
            `${config.REACT_APP_NODE_API_URL}/api/algo/initialize-leitner-data`,
            {
              user: user._id,
              languageCode: localStorage.getItem('languageData').split('-')[1],
              levelName: this.state.levelName,
            }
          )
          .then(res => this.setState({vocabList: res.data.data}))
          .catch(err => {
            console.log(err);
          });
      }
    });
    axios
      .post(`${config.REACT_APP_NODE_API_URL}/api/quiz/generate`, {
        language: langData,
        question_level: this.state.levelName,
      })
      .then(res => {
        console.log(res.data);
        if (res.data.length > 0) {
          this.setState({hasQuiz: true});
        }
      })
      .catch(err => console.log(err));
  }

  /**
   * @function fetchProgressData
   * @param {string} levelName - Level name whose data is required to be fetched
   */
  fetchProgressData(levelName) {
    axios
      .get(
        `${config.REACT_APP_NODE_API_URL}/api/algo/get-leitner-data/${
          this.props.id
        }/${localStorage.getItem('languageData').split('-')[1]}/${levelName}`
      )
      .then(res => {
        this.setState({
          mastered: res.data.data.progressWords.mastered,
          learning: res.data.data.progressWords.learning,
          reviewing: res.data.data.progressWords.reviewing,
          wordInMastered: res.data.data.wordsInLevel.mastered,
          wordInLearning: res.data.data.wordsInLevel.learning,
          wordInReviewing: res.data.data.wordsInLevel.reviewing,
          totalWords: res.data.data.progressWords.total,
          masteredPer: res.data.data.progressPercentage.mastered,
          learningPer: res.data.data.progressPercentage.learning,
          reviewingPer: res.data.data.progressPercentage.reviewing,
        });
      })
      .catch(err => console.log(err));
  }

  /**
   * @function handleKnowTheWord
   * @param {any} word - The word to handle
   */
  handleKnowTheWord(word) {
    new Audio('/assets/audio/button-push.wav').play();
    knowTheWord(this.state.levelName, word._id);
    this.setState({flipCard: false});
    const nextQuestion = this.state.currentQuestion + 1;
    if (
      this.state.totalWords === this.state.mastered &&
      this.state.mastered !== 0 &&
      this.state.availContinue === true
    ) {
      this.setState({nextLvl: true});
      new Audio('/assets/audio/level-complete.wav').play();
    } else if (nextQuestion < this.state.vocabList.length) {
      this.setState({currentQuestion: nextQuestion});
    } else {
      this.setState({currentQuestion: 0});
    }
    this.fetchProgressData(this.state.levelName);
  }
  /**
   * @function handleDoNotKnowTheWord
   * @param {any} word - The word to handle
   */
  handleDoNotKnowTheWord(word) {
    new Audio('/assets/audio/button-push.wav').play();
    dontKnowTheWord(this.state.levelName, word._id);
    this.setState({flipCard: false});
    const nextQuestion = this.state.currentQuestion + 1;
    if (
      this.state.totalWords === this.state.mastered &&
      this.state.mastered !== 0 &&
      this.state.availContinue === true
    ) {
      this.setState({nextLvl: true});
      new Audio('/assets/audio/level-complete.wav').play();
    } else if (nextQuestion < this.state.vocabList.length) {
      this.setState({currentQuestion: nextQuestion});
    } else {
      this.setState({currentQuestion: 0});
    }
    this.fetchProgressData(this.state.levelName);
  }

  /**
   * @function flipCard
   */
  flipCard() {
    this.setState({flipCard: true});
    this.fetchProgressData(this.state.levelName);
  }

  /**
   * @function goToNextLevel
   */
  goToNextLevel() {
    const currLang = localStorage.getItem('languageData');
    const learningLangVal = currLang && currLang.split('-')[1];
    if (this.state.hasQuiz)
      window.location.pathname = `/quiz/${currLang}/${this.state.levelName}`;
    else {
      axios({
        method: 'get',
        url: `${config.REACT_APP_NODE_API_URL}/api/vocab/next/${this.state.levelName}/${learningLangVal}`,
      })
        .then(res => {
          window.location.pathname =
            `/vocab/category/` + res.data.data.nextCategory;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  /**
   * @function handleContinue
   */
  handleContinue() {
    this.setState({availContinue: false, nextLvl: false});
  }

  /**
   * @function toggleShowModal
   * @description toggle show/hide value of modal
   */
  toggleShowModal() {
    this.setState(x => ({showModal: !x.showModal}));
  }

  /**
   * @function render
   * @returns {import('react').ReactElement} JSX BODY
   */
  render() {
    const {id} = this.props;
    if (!id) return <Redirect to="/" />;
    const currWord = this.state.vocabList[this.state.currentQuestion];
    let learningLanguage = '';
    let knownLanguage = '';
    let wordStatus = '';
    if (currWord) {
      const currLang = localStorage.getItem('languageData');
      const knownLangVal = currLang && currLang.split('-')[0];
      const learningLangVal = currLang && currLang.split('-')[1];
      if (knownLangVal === 'eng') {
        knownLanguage = currWord.englishInEnglish;
      } else if (knownLangVal === 'hin') {
        knownLanguage = currWord.hindiInHindi;
      } else {
        knownLanguage = currWord.englishInEnglish;
      }
      if (learningLangVal === 'mun') {
        if (knownLangVal === 'eng') {
          learningLanguage = currWord.mundariInEnglish;
        } else if (knownLangVal === 'hin') {
          learningLanguage = currWord.mundariInHindi;
        }
      } else if (learningLangVal === 'kur') {
        if (knownLangVal === 'eng') {
          learningLanguage = currWord.kurukInEnglish;
        } else if (knownLangVal === 'hin') {
          learningLanguage = currWord.kurukInHindi;
        }
      } else if (learningLangVal === 'san') {
        if (knownLangVal === 'eng') {
          learningLanguage = currWord.santhaliInEnglish;
        } else if (knownLangVal === 'hin') {
          learningLanguage = currWord.santhaliInHindi;
        }
      } else {
        learningLanguage = currWord.kurukInEnglish;
      }
      // To check the status of the word: L-R-M
      if (
        this.state.wordInMastered &&
        this.state.wordInMastered.indexOf(currWord._id) !== -1
      ) {
        wordStatus = 'Mastered';
        // console.log("This word is in mastered");
      } else if (
        this.state.wordInLearning &&
        this.state.wordInLearning.indexOf(currWord._id) !== -1
      ) {
        wordStatus = 'Learning';
        // console.log("This word is in learning");
      } else if (
        this.state.wordInReviewing &&
        this.state.wordInReviewing.indexOf(currWord._id) !== -1
      ) {
        wordStatus = 'Reviewing';
        // console.log("This word is in reviewing");
      } else {
        wordStatus = 'New Word';
      }
    }
    return (
      <div className="vocabcards">
        {this.state.showModal && (
          <Modal>
            <ReportModal
              toggleShowModal={() => this.toggleShowModal()}
              currentWord={this.state.vocabList[this.state.currentQuestion]._id}
            />
          </Modal>
        )}
        <div className="vocabcards__note">
          <img
            src="/assets/loop-image.png"
            className="vocabcards__note__loopimage"
            alt="vocab card note"
          />
          <h2>{"Words you don't know will reappear later"}</h2>
        </div>
        {!this.state.nextLvl ? (
          <div className="vocabcard">
            <div className="vocabcard__content">
              {this.state.flipCard ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingTop: '1rem',
                    }}
                  >
                    <span
                      style={{fontSize: '1.5rem', cursor: 'pointer'}}
                      onClick={() => this.setState({showModal: true})}
                    >
                      <FaFontAwesomeFlag /> Report
                    </span>
                    <span style={{fontSize: '1.5rem'}}> {wordStatus}</span>
                  </div>
                  <div style={{paddingBottom: '3rem'}}>
                    <h1
                      style={{textAlign: 'center'}}
                      className="vocabcard__content__subheading"
                    >
                      {knownLanguage}
                    </h1>
                    <h1 className="vocabcard__content__heading">
                      {learningLanguage}
                    </h1>
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      fontSize: '1.5rem',
                      float: 'right',
                      paddingTop: '1rem',
                    }}
                  >
                    {wordStatus}
                  </div>
                  <h1 className="vocabcard__content__subheading__main">
                    {knownLanguage}
                  </h1>
                </>
              )}
            </div>
            {this.state.flipCard ? (
              <div className="vocabcard__actions">
                <button
                  className="btn btn-md vocabcard__actions__secondary"
                  onClick={() => this.handleDoNotKnowTheWord(currWord)}
                >
                  <div>😤</div>I didn{"'"}t know
                </button>
                <button
                  className="btn btn-md vocabcard__actions__primary"
                  onClick={() => this.handleKnowTheWord(currWord)}
                >
                  <div>😎</div>I knew
                </button>
              </div>
            ) : (
              <div className="vocabcard__actions">
                <button
                  className="btn btn-md vocabcard__actions__primary__show_meaning"
                  onClick={this.flipCard}
                >
                  <div>👀</div>
                  Show Meaning
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="vocabcard">
            <div className="vocabcard__content">
              <h1 className="vocabcard__content__heading__after">
                Congrats. Keep Learning
              </h1>
            </div>
            <div className="vocabcard__actions__after">
              <button
                className="btn btn-md vocabcard__actions__after__actionbtn"
                onClick={this.handleContinue}
              >
                Continue
              </button>
              <button
                className="btn btn-md vocabcard__actions__after__actionbtn"
                onClick={this.goToNextLevel}
              >
                {this.state.hasQuiz
                  ? 'Take Quiz to unlock next level'
                  : 'Go to next level'}
              </button>
              {/* <Link
                className="btn btn-md vocabcard__actions__after__actionbtn"
                to="/quiz/english/1"
              >
                Take Quiz
              </Link>*/}
            </div>
          </div>
        )}
        <div className="vocabcard__processbars">
          <h1 className="vocabcard__processbars__label">
            You have mastered{' '}
            {(this.state.mastered && this.state.mastered) || 0} out of{' '}
            {this.state.totalWords} words
          </h1>
          <ProcessBars
            data={[
              this.state.masteredPer && Math.floor(this.state.masteredPer),
              'Mastered',
            ]}
            className="vocabcard__processbar"
          />
          <h1 className="vocabcard__processbars__label">
            You are reviewing{' '}
            {(this.state.reviewing && this.state.reviewing) || 0} out of{' '}
            {this.state.totalWords} words
          </h1>
          <ProcessBars
            data={[
              this.state.reviewingPer && Math.floor(this.state.reviewingPer),
              'Reviewing',
            ]}
            className="vocabcard__processbar"
          />
          <h1 className="vocabcard__processbars__label">
            You are learning {(this.state.learning && this.state.learning) || 0}{' '}
            out of {this.state.totalWords} words
          </h1>
          <ProcessBars
            data={[
              (this.state.learningPer && Math.floor(this.state.learningPer)) ||
                0,
              'Learning',
            ]}
            className="vocabcard__processbar"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;

  return {
    id,
  };
};

export default connect(mapStateToProps, null)(VocabCards);
