/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

const EditWord = ({match}) => {
  const [englishInEnglish, setEnglishInEnglish] = useState('');
  const [hindiInHindi, setHindiInHindi] = useState('');
  const [mundariInEnglish, setMundariInEnglish] = useState('');
  const [mundariInHindi, setMundariInHindi] = useState('');
  const [kurukInEnglish, setKurukInEnglish] = useState('');
  const [kurukInHindi, setKurukInHindi] = useState('');
  const [santhaliInEnglish, setSanthaliInEnglish] = useState('');
  const [santhaliInHindi, setSanthaliInHindi] = useState('');
  const [hoInEnglish, setHoInEnglish] = useState('');
  const [hoInHindi, setHoInHindi] = useState('');
  const [khariaInEnglish, setKhariaInEnglish] = useState('');
  const [khariaInHindi, setKhariaInHindi] = useState('');
  const [bhiliInEnglish, setBhiliInEnglish] = useState('');
  const [bhiliInHindi, setBhiliInHindi] = useState('');
  const [gondiInEnglish, setGondiInEnglish] = useState('');
  const [gondiInHindi, setGondiInHindi] = useState('');
  const [tuluInEnglish, setTuluInEnglish] = useState('');
  const [tuluInHindi, setTuluInHindi] = useState('');
  const [message, setMessage] = useState('');
  const [modLang] = useState(
    localStorage.getItem('modLang') ? localStorage.getItem('modLang') : null
  );
  const moderator = localStorage.getItem('currentAdmin');
  useEffect(() => {
    axios
      .get(
        `${config.REACT_APP_NODE_API_URL}/api/vocab/get/vocab-by-code-and-id/${modLang}/${match.params.wordId}`
      )
      .then(res => {
        setEnglishInEnglish(res.data.data.englishInEnglish);
        setHindiInHindi(res.data.data.hindiInHindi);
        if (modLang === 'mun') {
          setMundariInEnglish(res.data.data.mundariInEnglish);
          setMundariInHindi(res.data.data.mundariInHindi);
        } else if (modLang === 'kur') {
          setKurukInEnglish(res.data.data.kurukInEnglish);
          setKurukInHindi(res.data.data.kurukInHindi);
        } else if (modLang === 'san') {
          setSanthaliInEnglish(res.data.data.santhaliInEnglish);
          setSanthaliInHindi(res.data.data.santhaliInHindi);
        } else if (modLang === 'hoc') {
          setHoInEnglish(res.data.data.hoInEnglish);
          setHoInHindi(res.data.data.hoInHindi);
        } else if (modLang === 'khr') {
          setKhariaInEnglish(res.data.data.khariaInEnglish);
          setKhariaInHindi(res.data.data.khariaInHindi);
        } else if (modLang === 'bhb') {
          setBhiliInEnglish(res.data.data.bhiliInEnglish);
          setBhiliInHindi(res.data.data.bhiliInHindi);
        } else if (modLang === 'gon') {
          setGondiInEnglish(res.data.data.gondiInEnglish);
          setGondiInHindi(res.data.data.gondiInHindi);
        } else if (modLang === 'tcy') {
          setTuluInEnglish(res.data.data.tuluInEnglish);
          setTuluInHindi(res.data.data.tuluInHindi);
        }
      })
      .catch(err => {
        console.log(err);
        setMessage(err.response.data.msg);
      });
    return () => {};
  }, [match.params.wordId, modLang]);
  const handleInput = e => {
    const {name, value} = e.currentTarget;
    switch (name) {
      case 'englishInEnglish':
        setEnglishInEnglish(value);
        break;
      case 'hindiInHindi':
        setHindiInHindi(value);
        break;
      case 'mundariInEnglish':
        setMundariInEnglish(value);
        break;
      case 'mundariInHindi':
        setMundariInHindi(value);
        break;
      case 'kurukInEnglish':
        setKurukInEnglish(value);
        break;
      case 'kurukInHindi':
        setKurukInHindi(value);
        break;
      case 'santhaliInEnglish':
        setSanthaliInEnglish(value);
        break;
      case 'santhaliInHindi':
        setSanthaliInHindi(value);
        break;
      case 'hoInEnglish':
        setHoInEnglish(value);
        break;
      case 'hoInHindi':
        setHoInHindi(value);
        break;
      case 'khariaInEnglish':
        setKhariaInEnglish(value);
        break;
      case 'khariaInHindi':
        setKhariaInHindi(value);
        break;
      case 'bhiliInEnglish':
        setBhiliInEnglish(value);
        break;
      case 'bhiliInHindi':
        setBhiliInHindi(value);
        break;
      case 'gondiInEnglish':
        setGondiInEnglish(value);
        break;
      case 'gondiInHindi':
        setGondiInHindi(value);
        break;
      case 'tuluInEnglish':
        setTuluInEnglish(value);
        break;
      case 'tuluInHindi':
        setTuluInHindi(value);
        break;
      default:
        break;
    }
  };
  const submitEditWord = e => {
    e.preventDefault();
    const regx = /[a-zA-Z]+/g;
    if (modLang === 'mun') {
      if (regx.test(hindiInHindi) || regx.test(mundariInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }
      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/update-vocab-data`,
        data: {
          lang: modLang,
          userId: localStorage.getItem('currentAdmin'),
          wordId: match.params.wordId,
          // englishInEnglish,
          hindiInHindi,
          // mundariInEnglish,
          mundariInHindi,
          moderator,
        },
      })
        .then(res => {
          setMessage('Word updated');
        })
        .catch(err => {
          setMessage(err.response.data.message);
          console.log(err);
        });
    } else if (modLang === 'kur') {
      if (regx.test(hindiInHindi) || regx.test(kurukInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }
      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/update-vocab-data`,
        data: {
          lang: modLang,
          userId: localStorage.getItem('currentAdmin'),
          wordId: match.params.wordId,
          // englishInEnglish,
          hindiInHindi,
          // kurukInEnglish,
          kurukInHindi,
          moderator,
        },
      })
        .then(res => {
          setMessage('Word updated');
        })
        .catch(err => {
          console.log(err);
        });
    } else if (modLang === 'san') {
      if (regx.test(hindiInHindi) || regx.test(santhaliInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }
      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/update-vocab-data`,
        data: {
          lang: modLang,
          userId: localStorage.getItem('currentAdmin'),
          wordId: match.params.wordId,
          // englishInEnglish,
          hindiInHindi,
          // santhaliInEnglish,
          santhaliInHindi,
          moderator,
        },
      })
        .then(res => {
          setMessage('Word updated');
        })
        .catch(err => {
          console.log(err);
        });
    } else if (modLang === 'hoc') {
      if (regx.test(hindiInHindi) || regx.test(hoInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }
      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/update-vocab-data`,
        data: {
          lang: modLang,
          userId: localStorage.getItem('currentAdmin'),
          wordId: match.params.wordId,
          // englishInEnglish,
          hindiInHindi,
          // santhaliInEnglish,
          hoInHindi,
          moderator,
        },
      })
        .then(res => {
          setMessage('Word updated');
        })
        .catch(err => {
          console.log(err);
        });
    } else if (modLang === 'khr') {
      if (regx.test(hindiInHindi) || regx.test(khariaInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }
      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/update-vocab-data`,
        data: {
          lang: modLang,
          userId: localStorage.getItem('currentAdmin'),
          wordId: match.params.wordId,
          // englishInEnglish,
          hindiInHindi,
          // santhaliInEnglish,
          khariaInHindi,
          moderator,
        },
      })
        .then(res => {
          setMessage('Word updated');
        })
        .catch(err => {
          console.log(err);
        });
    } else if (modLang === 'bhb') {
      if (regx.test(hindiInHindi) || regx.test(bhiliInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }
      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/update-vocab-data`,
        data: {
          lang: modLang,
          userId: localStorage.getItem('currentAdmin'),
          wordId: match.params.wordId,
          // englishInEnglish,
          hindiInHindi,
          // santhaliInEnglish,
          bhiliInHindi,
          moderator,
        },
      })
        .then(res => {
          setMessage('Word updated');
        })
        .catch(err => {
          console.log(err);
        });
    } else if (modLang === 'gon') {
      if (regx.test(hindiInHindi) || regx.test(gondiInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }
      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/update-vocab-data`,
        data: {
          lang: modLang,
          userId: localStorage.getItem('currentAdmin'),
          wordId: match.params.wordId,
          // englishInEnglish,
          hindiInHindi,
          // santhaliInEnglish,
          gondiInHindi,
          moderator,
        },
      })
        .then(res => {
          setMessage('Word updated');
        })
        .catch(err => {
          console.log(err);
        });
    } else if (modLang === 'tcy') {
      if (regx.test(hindiInHindi) || regx.test(tuluInHindi)) {
        setMessage('Enter valid devnagri script');
        return;
      }
      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/update-vocab-data`,
        data: {
          lang: modLang,
          userId: localStorage.getItem('currentAdmin'),
          wordId: match.params.wordId,
          // englishInEnglish,
          hindiInHindi,
          // santhaliInEnglish,
          tuluInHindi,
          moderator,
        },
      })
        .then(res => {
          setMessage('Word updated');
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  if (!moderator) return <Redirect to="/moderator" />;
  return (
    <section className="editwords">
      <div className="editwords__header">
        <h1 className="editwords__header__heading heading-primary--main u-center-text">
          Edit Words in Vocab
        </h1>
      </div>
      <div className="editwords__content">
        <form className="editwords__content__form" onSubmit={submitEditWord}>
          {/* <label className="editwords__content__form__label">English</label>
          <input
            type="text"
            className="editwords__content__form__input"
            name="englishInEnglish"
            onChange={handleInput}
            value={englishInEnglish}
          /> */}

          <label className="editwords__content__form__label">हिंदी</label>
          <input
            type="text"
            className="editwords__content__form__input"
            name="hindiInHindi"
            onChange={handleInput}
            value={hindiInHindi}
          />
          {modLang === 'mun' ? (
            <>
              {/* <label className="editwords__content__form__label">Mundari</label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="mundariInEnglish"
                onChange={handleInput}
                value={mundariInEnglish}
              /> */}
              <label className="editwords__content__form__label">मुंडारी</label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="mundariInHindi"
                onChange={handleInput}
                value={mundariInHindi}
              />
            </>
          ) : (
            ''
          )}
          {modLang === 'kur' ? (
            <>
              {/* <label className="editwords__content__form__label">
                Kuruk meaning in English
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="kurukInEnglish"
                onChange={handleInput}
                value={kurukInEnglish || ""}
              /> */}
              <label className="editwords__content__form__label">
                Kuruk meaning in Hindi
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="kurukInHindi"
                onChange={handleInput}
                value={kurukInHindi || ''}
              />
            </>
          ) : (
            ''
          )}
          {modLang === 'san' ? (
            <>
              {/* <label className="editwords__content__form__label">
                Santhali meaning in English
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="santhaliInEnglish"
                onChange={handleInput}
                value={santhaliInEnglish || ""}
              /> */}
              <label className="editwords__content__form__label">
                Santhali meaning in Hindi
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="santhaliInHindi"
                onChange={handleInput}
                value={santhaliInHindi || ''}
              />
            </>
          ) : (
            ''
          )}
          {modLang === 'hoc' ? (
            <>
              {/* <label className="editwords__content__form__label">
                Santhali meaning in English
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="santhaliInEnglish"
                onChange={handleInput}
                value={santhaliInEnglish || ""}
              /> */}
              <label className="editwords__content__form__label">
                Ho meaning in Hindi
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="hoInHindi"
                onChange={handleInput}
                value={hoInHindi || ''}
              />
            </>
          ) : (
            ''
          )}
          {modLang === 'khr' ? (
            <>
              {/* <label className="editwords__content__form__label">
                Santhali meaning in English
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="santhaliInEnglish"
                onChange={handleInput}
                value={santhaliInEnglish || ""}
              /> */}
              <label className="editwords__content__form__label">
                Kharia meaning in Hindi
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="khariaInHindi"
                onChange={handleInput}
                value={khariaInHindi || ''}
              />
            </>
          ) : (
            ''
          )}
          {modLang === 'bhb' ? (
            <>
              {/* <label className="editwords__content__form__label">
                Santhali meaning in English
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="santhaliInEnglish"
                onChange={handleInput}
                value={santhaliInEnglish || ""}
              /> */}
              <label className="editwords__content__form__label">
                Bhili meaning in Hindi
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="bhiliInHindi"
                onChange={handleInput}
                value={bhiliInHindi || ''}
              />
            </>
          ) : (
            ''
          )}
          {modLang === 'gon' ? (
            <>
              {/* <label className="editwords__content__form__label">
                Santhali meaning in English
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="santhaliInEnglish"
                onChange={handleInput}
                value={santhaliInEnglish || ""}
              /> */}
              <label className="editwords__content__form__label">
                Gondi meaning in Hindi
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="gondiInHindi"
                onChange={handleInput}
                value={gondiInHindi || ''}
              />
            </>
          ) : (
            ''
          )}
          {modLang === 'tcy' ? (
            <>
              {/* <label className="editwords__content__form__label">
                Santhali meaning in English
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="santhaliInEnglish"
                onChange={handleInput}
                value={santhaliInEnglish || ""}
              /> */}
              <label className="editwords__content__form__label">
                Tulu meaning in Hindi
              </label>
              <input
                type="text"
                className="editwords__content__form__input"
                name="tuluInHindi"
                onChange={handleInput}
                value={tuluInHindi || ''}
              />
            </>
          ) : (
            ''
          )}
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <button className="btn btn-md editwords__content__form__submit">
              Edit Word
            </button>
            <Link
              to="/moderator/manage-words"
              className="btn btn-md "
              style={{marginLeft: '1rem'}}
            >
              Back
            </Link>
          </div>
        </form>
        {message ? (
          <h1 className="heading-primary--sub u-center-text">{message}</h1>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

export default EditWord;
