/* eslint-disable react/prop-types */
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import Footer from '../layout/Footer';
import Navbar from '../layout/Navbar';
import LoggedInFooter from '../layout/LoggedInFooter';

const Privacy = props => {
  const {id} = props;
  return (
    <Fragment>
      <Navbar />
      <section className="privacy">
        <h1>Privacy Policy</h1>
        <div className="privacy-content">
          {/* 1 */}
          <h5>1. General</h5>
          <p>
            At Trilingo, we care about your personal data, so we have prepared
            this Privacy Policy to explain how we collect, use and share it.
          </p>
          <p>
            This Privacy Policy (“Privacy Policy”) details the personal data
            Trilingo, (“Trilingo”, “we”, “us” or “our”) receives about you, how
            we process it and your rights and obligations in relation to your
            personal data. Trilingo, a brand of Alfred Technologies (OPC) Pvt.
            Ltd registered at 613, Sushma Niketan, Road 03, Ward 13, Lobin
            Bagaan, Niche Chowk, Khunti, Jharkhand 835210, India is the data
            controller for the purposes of the General Data Protection
            Regulation (“GDPR”) and any relevant local legislation (“Data
            Protection Laws”).
          </p>
          <p>
            By using or accessing the Service, you agree to the terms of this
            Privacy Policy. Capitalized terms not defined here have the meanings
            set forth in the terms and conditions (the “Terms and Conditions”),
            located at www.trilingo.in/terms. We may update our Privacy Policy
            to reflect changes to our information practices. If we do this and
            the changes are material, we will post a notice that we have made
            changes to this Privacy Policy on the Website for at least 7 days
            before the changes are made, and we will indicate the date these
            terms were last revised at the bottom of the Privacy Policy. Any
            revisions to this Privacy Policy will become effective at the end of
            that 7 day period.
          </p>
          <p>
            If you are an employee, worker or contractor of Trilingo, the
            information about how we handle your personal information is
            available in the Trilingo internal knowledge base. With respect to
            employees based in India, we are committed to cooperating with
            Indian data protection authorities (DPAs) and comply with the advice
            given by such authorities with regard to human resources data
            transferred from India in the context of the employment
            relationship.
          </p>

          {/* 2 */}
          <h5>2. Information We Collect</h5>
          <p>
            This Privacy Policy explains how we collect, use and share your
            personal data.
          </p>
          <p>
            <strong>Information you provide</strong> <br /> Through the
            registration process, you will provide us with your name, e-mail
            address (or parent’s e-mail address), and age or date of birth. You
            will also provide us with your payment transaction information if
            you choose to pay for Trilingo services.
          </p>

          <p>
            <strong>Activity Data</strong> <br /> When you use the Service, you
            will submit information and content to your profile. We will also
            generate data about your use of our Services including your
            engagement in educational activities on the Service, or your sending
            of messages and otherwise transmitting information to other users
            (“Activity Data”). We also collect technical data about how you
            interact with our Services; for more information, see Cookies.
          </p>
          <p>
            When you use Trilingo in our app or on our website, we use a service
            named FullStory to log your activity. This provides us with a set of
            data and a session replay of your activity on Trilingo in the form
            of a video. FullSory captures and analyzes your usage data to help
            us make your Trilingo experience better.
          </p>

          <p>
            <strong>
              We only collect the data we need which means we don’t record:
            </strong>
          </p>
          <ul>
            <li>Username</li>
            <li>Name</li>
            <li>Profile Image</li>
            <li>Email Address</li>
            <li>Location or bio on the profile</li>
            <li>Password</li>
          </ul>

          <p>
            <strong>We do record the following data:</strong>
          </p>
          <ul>
            <li>Usage Patterns</li>
            <li>Clicks</li>
            <li>Mouse Movements</li>
            <li>Scrolling</li>
            <li>Typing</li>
            <li>Tech Specs</li>
            <li>Browser</li>
            <li>Device Type</li>
            <li>Operating System</li>
            <li>View Finder Size</li>
            <li>Script Errors</li>
            <li>IP Address</li>
            <li>Navigation</li>
            <li>Pages Visited</li>
            <li>Referrers</li>
            <li>URL Parameters</li>
            <li>Session Duration</li>
            <li>Learning Activity</li>
            <li>Session Progress</li>
            <li>Answers</li>
            <li>
              You can disable FullStory activity recording by using the opt-out
              function on FullStory’s website here.
            </li>
          </ul>

          <p>
            <strong>Live Speaking Practice</strong>
          </p>
          <p>
            When you take a Live Speaking Lesson, we will use your computer{"'"}
            s microphone throughout the lesson to speak to your tutor. You also
            have the option of using your camera to share your video with your
            tutor. Trilingo records these audio and video lessons and retains
            them until you close your Trilingo account. Trilingo uses this data
            to help improve the quality of the lessons and for internal research
            purposes.
          </p>
          <p>
            Trilingo does not share any of your personal data with your tutor.
            You are completely anonymous to them by default, and you can freely
            choose whether you show them your video or tell them any personal
            details such as your name during the conversation. Trilingo only
            shares some basic skill level information with the tutor, prior to
            your lesson, so they understand how best to speak to you.
          </p>

          <p>
            <strong>Speaking Challenges</strong>
          </p>
          <p>
            Some lessons involve you speaking into the app. To recognize speech
            your audio may be sent to a third party provider such as Google,
            Apple, or Amazon Web Services. We may ask you to allow Trilingo to
            collect and analyze your speech data to help us understand the
            effectiveness of our lessons, and to improve the product. If you
            choose not to share your audio with us, or if we haven’t asked you
            yet, then your audio will not be used in this way. Any audio you
            agree to send to Trilingo is anonymized when it reaches our servers
            to ensure that no trace of your personal data remains attached to
            the audio.
          </p>

          <p>
            <strong>Third Party Data</strong>
          </p>
          <p>
            We also collect information about you from third parties. For more
            information, see Information obtained by Third Parties.
          </p>

          <p>
            <strong>Product Research and Development</strong>
          </p>
          <p>
            We may contact you to participate in product research activities.
            These may include surveys, interviews, and other types of feedback
            sessions. When you participate in these research activities, the
            information you provide will be used to test, improve, and develop
            our products. We will record the video, audio and text
            transcriptions of this feedback together with any additional contact
            information you provide and will retain this data for two years.
          </p>

          <p>
            <strong>Please contact us at hello@trilingo.in to:</strong>
          </p>
          <ul>
            <li>
              Request more information about the research activities you have
              volunteered for.
            </li>
            <li>Opt out of being contacted for research activities.</li>
            <li>
              Request a copy of any research related data you have provided to
              us.
            </li>
          </ul>

          {/* 3 */}
          <h5>3. Cookies</h5>
          <p>
            When you enter the Website, we collect your browser type and your IP
            address (a unique address that identifies your computer on the
            Internet). When you use our mobile Application, we collect your
            device type, your device ID, and your IP address.
          </p>
          <p>
            In addition, we store certain information from your browser using
            {"'"}cookies.{'"'} A cookie is a piece of data stored on the user
            {"'"}s computer tied to information about the user. We use session
            ID cookies to confirm that users are logged in. If you do not want
            information collected through the use of cookies, there is a simple
            procedure in most browsers that allows you to deny or accept the
            cookie feature; however, you should note that cookies may be
            necessary to provide you certain features available on the Website
            (e.g., customized delivery of information).
          </p>
          <p>
            You can find more information about cookies and how to manage them
            at http://www.allaboutcookies.org.
          </p>

          {/* 4 */}
          <h5>4. Information Obtained By Third Parties</h5>
          <p>
            To support and enhance the Service, we work with a variety of third
            party advertising networks, marketing analytics service providers
            and website analysis firms, such as Google, Facebook, Oath, and
            Unity (collectively, “Third Party Advertising Service Providers”).
            These Third Party Advertising Service Providers collect and use
            personal data about your visits to and use of the Website and mobile
            Application, as well as other websites in order to serve you with
            advertisements and content tailored to meet your preferences and
            likely interests or better understand ad effectiveness. Aggregate
            demographic and interest data will also be used for market research
            purposes.
          </p>
          <p>
            We use Google Analytics which is a web analytics tool that helps us
            understand how users engage with the Website. Like many services,
            Google Analytics uses first-party cookies to track user interactions
            as in our case, where they are used to collect information about how
            users use our site. This information is used to compile reports and
            to help us improve our Website. The reports disclose website trends
            without identifying individual visitors. You can opt out of Google
            Analytics without affecting how you visit our site – for more
            information on opting out of being tracked by Google Analytics
            across all websites you use
            <span className="privacy-content-hide">
              , visit this Google page:
              https://tools.google.com/dlpage/gaoptout.
            </span>
          </p>
          <p>
            You can opt-out of receiving personalized ads from advertisers and
            ad networks that are members of the Network Advertising Initiative
            (“NAI”) or who follow the DAA Self-Regulatory Principles for Online
            Behavioral Advertising using their respective opt-out tools.{' '}
            <span className="privacy-content-hide">
              The NAI{"'"}s opt-out tool can be found here
              http://www.networkadvertising.org/ choices/ and the DAA{"'"}s opt
              out tool can be found here http://www.aboutads.info/choices/.
            </span>
          </p>
          <p>
            In addition, your mobile devices may offer settings that enable you
            to make choices about the collection, use, or transfer of mobile app
            information for online behavioral advertising (for example, Apple
            iOS’ Advertising ID and Google Android’s Advertising ID). Please
            note that opting out does not prevent the display of all
            advertisements to you.
          </p>

          {/* 5 */}
          <h5>5. Use of information obtained by Trilingo</h5>
          <p>
            Trilingo may occasionally send you service related and product
            change announcements through the general operation of the Service.
            We will send you reminder notifications to support the Trilingo
            teaching methodology by reinforcing the learning cadence and help
            maintain your learning streak. We process your data to help provide
            you with the Service and offer personalized features, to understand
            and improve our Service and to keep our Service safe and secure.
          </p>
          <p>
            Trilingo may use or share anonymous data collected through the
            Service, including Activity Data without limitation. As a business,
            it is critical that we perform our contract with you with the best
            service possible, and it is in our legitimate interests to perform
            these processing functions and to enable service e-mails by default
            to keep your data secure and provide our Service. You may opt out of
            any non-essential service e-mails at any time.
          </p>
          <p>
            We may also use your contact information to send you notifications
            regarding new services, offers and promotions offered by Trilingo if
            you affirmatively consent to receive such communications.
          </p>
          <p>
            When you register with Trilingo, some of your information is made
            public and available at various locations including
            trilingo.in/profile/your-username and
            trilingo.in/users/your-username. This will include the data from
            your Trilingo Profile and Account (Name, Location, Bio, Username,
            User ID, Profile picture and Trilingo Plus membership status); your
            followers and the people you follow; and your learning progress.
            Your Trilingo account email address is not shown publicly. Third
            party websites may be able to read, collect and use your public
            information for their own purposes.
          </p>
          <p>
            Profile information is used by Trilingo to be presented back to and
            edited by you when you access the Service and to be presented to
            other users. In some cases, other users may be able to supplement
            your profile, including by submitting comments (which can be deleted
            by you). Trilingo uses this information to provide you with support,
            to send you essential notifications, to enforce our terms,
            conditions and policies, to communicate with you, to administer the
            Services, and for internal operations, including troubleshooting,
            data analysis, testing, research, statistical, and survey purposes.
            Trilingo’s Terms and Conditions provide the contractual lawful basis
            for these processing activities.
          </p>
          <p>
            <strong>Sharing your personal data with third parties</strong>
          </p>
          <p>
            Trilingo shares your personal data only when it is necessary to
            offer the Service, legally required, or permitted by you.
          </p>
          <p>
            We will provide personal data to hosting providers such as Amazon
            Web Services, search engine providers such as Google, analytics
            providers such as Crashlytics, and support providers such as
            ZenDesk.
          </p>
          <p>
            These data processors help us bring you the Service. For example, we
            may share your information in order to detect where or how you
            encountered a bug when using our mobile application. In connection
            with these operations, our service providers will have access to
            personal data for a limited time. When we utilize service providers
            for processing any personal data, we implement contractual
            protections limiting the use of that personal data to the provision
            of services to Trilingo.
          </p>
          <p>
            We will be required to access and disclose personal data in response
            to lawful requests, such as subpoenas or court orders, or in
            compliance with applicable laws. Additionally, we will access and
            share account or other personal data when we believe it is necessary
            to comply with law, to protect our interests or property, to prevent
            fraud or other illegal activity perpetrated through the Service or
            using the Trilingo name, or to prevent imminent harm. This will
            include accessing and sharing personal data with other companies,
            lawyers, agents or government agencies.
          </p>
          <p>
            If the ownership of all or substantially all of the Trilingo
            business, or individual business units or assets owned by Trilingo
            that are related to the Service, were to change, your personal data
            will be transferred to the new owner. In any such transfer of
            information, your personal data would remain subject to this
            section.
          </p>
          <p>
            Trilingo will share aggregate or anonymous data collected through
            the Service, including Activity Data, for purposes such as
            understanding or improving the service.
          </p>

          {/* 6 */}
          <h5>6. Data subject rights and data retention</h5>
          <p>
            You can manage your account settings
            <span className="privacy-content-hide">
              at https://www.trilingo.in/settings/account to update, amend, and
              correct your information.
            </span>
          </p>
          <p>
            <strong>
              You also have the following rights in relation to the personal
              data we hold about you, unless provided otherwise by local law:
            </strong>
          </p>
          <ul>
            <li>
              1. To request access to, or erasure of, the personal data we hold
              about you.
            </li>
            <li>
              2. To request us to restrict the processing of the personal data
              we hold about you.
            </li>
            <li>
              3. To object to us processing personal data relating to you.
            </li>
            <li>
              4. Where you have given us consent to process your personal data,
              you have the right to withdraw that consent at any time.
            </li>
            <li>
              5. To export the personal data you have provided to Trilingo in a
              format that can be transferred electronically to a third party.
            </li>
            <li>
              6. To delete your account with Trilingo by following the
              instructions available through the Service.
            </li>
          </ul>
          <p>
            Trilingo will retain your data until your account is deleted, after
            which point we will retain anonymous data collected through the
            Service, including Activity Data, which may be used by Trilingo and
            shared with third parties in any manner. Information associated with
            the Trilingo , including results, will be kept indefinitely by
            Trilingo to improve the examination and for research and analysis.
          </p>
          <p>
            Please note that some of these rights are not absolute. In some
            cases, we may refuse a request to exercise particular rights if
            complying with it meant that we are no longer able to meet our
            contractual obligation to provide you with particular products and
            services. We will keep you informed as to the actions that we can
            take when you make your request.
          </p>

          {/* 7 */}
          <h5>7. Third party websites and links</h5>
          <p>
            Please note that you may have cookies placed on your computer by
            third party websites that refer you to our Service. Although we do
            not share your personal data with these third party websites unless
            it is reasonably necessary to offer the Service, they may be able to
            link certain non-personally identifiable information we transfer to
            them with personal data they previously collected from you. Please
            review the privacy policies of each website you visit to better
            understand their privacy practices. In addition, Trilingo would like
            to inform you that anytime you click on links (including advertising
            banners), which take you to third party websites, you will be
            subject to the third parties’ privacy policies.
          </p>
          <p>
            Our Services contain links to other sites operated by third parties.
            Trilingo does not control such other sites and is not responsible
            for their content, their privacy policies, or their use of personal
            data, including any personal or financial information collected by
            our third party payment processor to process payments for in-app
            purchases. Trilingo’s inclusion of such links does not imply any
            endorsement of the content on such sites or of their owners or
            operators except as disclosed on the Services. Any information
            submitted by you directly to these third parties is subject to that
            third party’s privacy policy.
          </p>

          {/* 8 */}
          <h5>8. User Privacy rights</h5>
          <p>
            <strong>User of Trilingo have the right to:</strong>
          </p>
          <ul>
            <li>
              Access a copy of the personal information we hold about you.
            </li>
            <li>
              Understand how we process your personal information and how it may
              be shared.
            </li>
            <li>
              Opt out of having your personal information shared with certain
              third parties.
            </li>
            <li>
              Request we delete your Trilingo account and personal information.
            </li>
            <li>
              To make such a request, please send an email to
              privacy@trilingo.in.
            </li>
          </ul>

          {/* 9 */}
          <h5>9. Links</h5>
          <p>
            The Service may contain links to other websites. We are not
            responsible for the privacy practices of other websites. We
            encourage users to be aware when they leave the Service to read the
            privacy statements of other websites that collect personally
            identifiable information. This Privacy Policy applies only to
            information collected by Trilingo via the Service.
          </p>

          {/* 10 */}
          <h5>10. Information security</h5>
          <p>
            Trilingo has implemented administrative and technical safeguards it
            believes are appropriate to protect the confidentiality, integrity
            and availability of your personal data, Testing ID, User Photo,
            access credentials, and Trilingo English Test results. However,
            given sufficient resources, a determined attacker could defeat those
            safeguards and may, as a result, gain access to the data we seek to
            protect.
          </p>

          {/* 11 */}
          <h5>11. Contact Us</h5>
          <p>
            <strong>
              Trilingo’s headquarters are located within the India at:
            </strong>
          </p>
          <ul>
            <li>Trilingo (Alfred Technologies OPC Pvt. Ltd),</li>
            <li>Sushma Niketan 613, Road 03, Ward 13,</li>
            <li>Lobin Bagaan, Niche Chowk,</li>
            <li>Khunti 835210,Jharkhand</li>
            <li>India</li>
            <li>Phone: +91-919-976-9697</li>
          </ul>
          <br />
          <p>
            For all data privacy inquiries and any questions or concerns you
            have about this Privacy Policy, please contact our Data Protection
            Officer at privacy@trilingo.in.
            <span className="privacy-content-hide">
              <br /> For all support inquiries, please go to
              https://support.trilingo.in
            </span>
          </p>
          <br />
          <p>Last revised on 1st May, 2021</p>
        </div>
      </section>
      {id ? <LoggedInFooter /> : <Footer />}
    </Fragment>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;

  return {
    id,
    error: state.auth.authError,
  };
};

export default connect(mapStateToProps)(Privacy);
