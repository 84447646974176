/* eslint-disable react/prop-types */
import React, {Component} from 'react';
import '../styles/pages/user/_all-notifications.scss';

/**
 * @class Tabs
 * @author NevetsKuro <stevenfernz96@gmail.com>
 * @augments Component
 * @description Used for viewing multiple data using tabs
 */
class Tabs extends Component {
  /**
   * @class Tabs
   * @param {{tabsItem: {name: string, icon: import('react-icons')}[], tabsData: {type: string, title: string, body: string, seen: boolean, date: string}[]}} props - Used to filter the tab headings items and body data
   */
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'All',
    };
  }

  /**
   * @function setSelectedTab
   * @param {string} name name of the current tab selected
   */
  setSelectedTab(name) {
    this.setState({selectedTab: name});
  }
  /**
   * @function render
   * @returns {import('react').JSXElementConstructor} -
   */
  render() {
    return (
      <div className="all-notifications-body-container">
        <div className="tabs-buttons">
          {this.props.tabItems.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  'tabs-button-item ' +
                  (item.name === this.state.selectedTab ? 'active' : '')
                }
                onClick={() => this.setSelectedTab(item.name)}
              >
                {item.icon}
                <span> {item.name}</span>
              </div>
            );
          })}
        </div>
        <div className="tabs-body">
          {this.state.selectedTab === 'All'
            ? this.props.tabData.map((item, index) => {
                return (
                  <div key={index} className="tabs-notifications-item">
                    <div className="tabs-notifications-item-icon">
                      {item.type.slice(0, 1)}
                    </div>
                    <div className="tabs-notifications-item-info">
                      <sm>
                        {item.type}
                        {/* <em> {item.date}</em> */}
                      </sm>
                      <p>{item.title}</p>
                      <p>{item.body}</p>
                    </div>
                  </div>
                );
              })
            : null}
          {this.props.tabData
            .filter(v => v.type === this.state.selectedTab)
            .map((item, index) => {
              return (
                <div key={index} className="tabs-notifications-item">
                  <div className="tabs-notifications-item-icon">
                    {item.type.slice(0, 1)}
                  </div>
                  <div className="tabs-notifications-item-info">
                    <sm>
                      {item.type}
                      {/* <em> {item.date}</em> */}
                    </sm>
                    <p>{item.title}</p>
                    <p>{item.body}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default Tabs;
