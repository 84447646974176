import React from 'react';
import ReactDOM from 'react-dom';
import './styles/styles.scss';
import App from './App';
// Redux Starts here
import {createStore, applyMiddleware, compose} from 'redux';
import rootReducer from './store/reducers/rootReducer';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import i18n from './i18n/index';
import {I18nextProvider} from 'react-i18next';
import {ToastProvider} from 'react-toast-notifications';
// Creating Redux Store
const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <canvas id="glCanvas" width="0" height="0" />
      <ToastProvider autoDismiss autoDismissTimeout={6000}>
        <App />
      </ToastProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);
