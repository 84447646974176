/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
import React from 'react';

const CategoryCard = props => {
  const {title, progress} = props;
  const progressData = progress !== 0 ? progress : 0;
  return (
    <div className="categorycard">
      <h1 className="categorycard__heading">
        {title.includes('.') ? title.split('.')[0] : title}
      </h1>
      <h2>{progressData + '% completed'}</h2>
      {}
      <div className="categorycard__processbar" done={progressData}>
        <div
          className="categorycard__processbar__done"
          style={{opacity: 1, width: `${progressData}%`}}
        ></div>
      </div>
    </div>
  );
};

export default CategoryCard;
