import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Redirect, Link} from 'react-router-dom';
import config from '../../../config';

const languageData = [
  {
    name: 'Mundari',
    code: 'mun',
  },
  {
    name: 'Kuruk',
    code: 'kur',
  },
  {
    name: 'Santhali',
    code: 'san',
  },
  {
    name: 'Ho',
    code: 'hoc',
  },
  {
    name: 'Kharia',
    code: 'khr',
  },
  {
    name: 'Bhili',
    code: 'bhb',
  },
  {
    name: 'Gondi',
    code: 'gon',
  },
  {
    name: 'Tulu',
    code: 'tcy',
  },
];
const ApproveInstrutorRequests = () => {
  const [userList, setUserList] = useState([]);
  const [modLang, setModLang] = useState('mun');
  useEffect(() => {
    axios({
      method: 'get',
      url: `${config.REACT_APP_NODE_API_URL}/api/admin/admin/get-unapproved-requests`,
    })
      .then(res => {
        setUserList(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  const assignModLang = () => {
    return (
      <select value={modLang} onChange={e => setModLang(e.target.value)}>
        {languageData.map(lang => (
          <option value={lang.code} key={lang.code}>
            {lang.name}
          </option>
        ))}
      </select>
    );
  };
  const handleApprove = (id, flag) => {
    console.log(modLang);
    axios({
      method: 'post',
      url: `${config.REACT_APP_NODE_API_URL}/api/admin/admin/handle-requests`,
      data: {
        _id: id,
        flag,
        modLang,
      },
    })
      .then(res => {
        console.log(res.data);
        setUserList(userList.filter(user => user._id !== id));
      })
      .catch(err => {
        console.log(err);
      });
  };
  const id = localStorage.getItem('currentAdmin');
  if (!id) return <Redirect to="/admin/auth" />;
  return (
    <section className="adminapproveinstructorrequests">
      <Link to="/admin/dashboard" className="btn btn-sm">
        Exit
      </Link>
      <div className="adminapproveinstructorrequests__header">
        <h1 className="adminapproveinstructorrequests__header__heading heading-primary--main u-center-text">
          Approve Instructor Requests
        </h1>
      </div>
      <div
        className="adminapproveinstructorrequests__content"
        style={{fontSize: '1.5rem'}}
      >
        <div className="adminapproveinstructorrequests__content__grid">
          <table className="managewords__table">
            <thead>
              <tr className="managewords__tr">
                <th className="managewords__th">#</th>
                <th className="managewords__th">Name</th>
                <th className="managewords__th">Email</th>
                <th className="managewords__th">Language</th>
                <th className="managewords__th">Actions</th>
              </tr>
            </thead>
            <tbody>
              {userList.length > 0 ? (
                userList.map((user, idx) => {
                  return (
                    <tr className="managewords__tr" key={user._id}>
                      <td className="managewords__td">{idx + 1}</td>
                      <td className="managewords__td">{user.name}</td>
                      <td className="managewords__td">{user.email}</td>
                      <td className="managewords__td">{assignModLang()}</td>
                      <td className="managewords__td">
                        <button
                          className="btn btn-sm"
                          onClick={() => handleApprove(user._id, 1)}
                        >
                          Approve
                        </button>
                        <button
                          className="btn btn-sm"
                          style={{marginLeft: '1rem'}}
                          onClick={() => handleApprove(user._id, 0)}
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>No user found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default ApproveInstrutorRequests;
