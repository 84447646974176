/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {activateUserAccount} from '../../store/actions/authActions';

const ActivateAccount = props => {
  const [buttonClicked, setButtonClicked] = useState(false);
  const {id} = props;
  if (!id && id !== props?.match?.id) return <Redirect to="/signin" />;
  const sendOtpToEmail = e => {
    e.preventDefault();
    setTimeout(() => {
      setButtonClicked(true);
    }, 2500);
    props.activateUserAccount({
      id,
    });
  };
  const {isAccountActivated} = props;
  if (isAccountActivated) return <Redirect to="/choose-goals" />;
  return (
    <section className="emailverify">
      <div className="emailverify__header">
        <h1 className="emailverify__header__heading">Activate My Account</h1>
      </div>
      <div className="emailverify__content">
        {!buttonClicked ? (
          <button
            className="btn emailverify__content__form__button"
            onClick={sendOtpToEmail}
            disabled={buttonClicked}
          >
            {!buttonClicked ? 'Activate My Account' : 'Activating...'}
          </button>
        ) : (
          ''
        )}
        {buttonClicked ? (
          <h1 className="emailverify__content__message">
            Your account has been activated.
          </h1>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;
  return {
    id,
    isAccountActivated: state.auth.isAccountActivated,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    activateUserAccount: email => dispatch(activateUserAccount(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount);
