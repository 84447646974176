/* eslint-disable react/prop-types */
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {userSignInWithEmailAndPassword} from '../../store/actions/authActions';
import i18n from '../../i18n/index';
import Footer from '../../layout/Footer';

/**
 * @class Signin
 * @augments Component
 */
class Signin extends Component {
  /**
   * @class Signin
   * @param {any} props - Props passed to the component
   */
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      submitClicked: false,
    };
    this.handleSignin = this.handleSignin.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  /**
   * @function handleSignin
   * @param {Event} e - Event
   */
  handleSignin(e) {
    e.preventDefault();
    this.setState({submitClicked: true});
    this.props.userSignInWithEmailAndPassword(this.state);
  }

  /**
   * @function handleInput
   * @param {Event} e - Event
   */
  handleInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  /**
   * @function render
   * @returns {import('react').JSXElementConstructor} JSX BODY
   */
  render() {
    const uri = new URL(window.location);
    if (
      (uri.searchParams.get('signIn') === 'facebook' ||
        uri.searchParams.get('signIn') === 'google') &&
      uri.searchParams.get('email') &&
      uri.searchParams.get('id')
    ) {
      localStorage.setItem(
        'isAccountActivated',
        JSON.stringify({
          status: true,
          email: uri.searchParams.get('email'),
        })
      );
      localStorage.setItem(
        'currentUser',
        JSON.stringify({
          _id: uri.searchParams.get('id'),
          newUser: 'Yes',
        })
      );
      return <Redirect to="/learn" />;
    }
    const {id, error} = this.props;
    if (id) return <Redirect to="/learn" />;
    return (
      <Fragment>
        <section className="auth__signin">
          <div className="auth__signin__header">
            <h1 className="auth__signin__header__heading">
              {i18n.t('Sign in here')}
            </h1>
          </div>
          <div className="auth__signin__content" onSubmit={this.handleSignin}>
            <form className="auth__signin__content__form">
              <input
                type="email"
                className="auth__signin__content__form__input"
                name="email"
                onChange={this.handleInput}
                placeholder={i18n.t('Enter your email')}
              />
              <input
                type="password"
                className="auth__signin__content__form__input"
                name="password"
                onChange={this.handleInput}
                placeholder={i18n.t('Enter your password')}
              />
              <button className="btn btn-md auth__signin__content__form__submit">
                {this.state.submitClicked && !error
                  ? `${i18n.t('Signing in')}`
                  : `${i18n.t('Sign in')}`}
              </button>
            </form>
            {error ? (
              <h1 className="auth__signin__content__form__error">
                {i18n.t(error)}
              </h1>
            ) : (
              ''
            )}
            <a
              href="/user/forgot-password"
              className="auth__signin__content__forgotpassword"
            >
              {i18n.t('Forgot Password')}
            </a>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;
  return {
    id,
    error: state.auth.authError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userSignInWithEmailAndPassword: credentials =>
      dispatch(userSignInWithEmailAndPassword(credentials)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
