import React, {Fragment, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import config from '../config';

const AccountSidebar = () => {
  const [name, setName] = useState('');
  useEffect(() => {
    const currentUser = localStorage.getItem('currentUser')
      ? localStorage.getItem('currentUser')
      : null;
    if (!currentUser) window.location.replace('/');
    const id = JSON.parse(currentUser)._id;
    axios
      .get(`${config.REACT_APP_NODE_API_URL}/api/user/${id}`)
      .then(res => {
        setName(res.data.data.name);
      })
      .catch(err => console.log(err));
  }, []);
  return (
    <Fragment>
      <div className="goal-card-profile-setting">
        <div className="profile-sidebar-settings">
          <h5
            style={{
              fontSize: '2rem',
              marginBottom: '2rem',
              marginTop: '2rem',
            }}
          >
            <span
              style={{padding: '0.5rem 1.2rem', marginRight: '1rem'}}
              className="profile-icon"
            >
              {name ? <>{name.charAt(0)}</> : <>...</>}
            </span>
            {name ? (
              <>
                {name.charAt(0)}
                {name.charAt(name.lastIndexOf(' ') + 1)}
              </>
            ) : (
              <>...</>
            )}
          </h5>
          {/* <Link to="/profile">
            <p
              style={{
                color: 'rgb(28, 176, 246)',
                marginBottom: '2rem',
                marginLeft: '1rem',
              }}
            >
              VIEW YOUR PROFILE
            </p>
          </Link> */}
          <Link to="/account">
            <p
              className={`settings-text ${
                window.location.pathname === '/account'
                  ? 'active-setting'
                  : 'inactive-setting'
              } `}
            >
              Account Settings
            </p>
          </Link>
          <Link to="/settings/language">
            <p
              className={`settings-text ${
                window.location.pathname === '/settings/language'
                  ? 'active-setting'
                  : 'inactive-setting'
              } `}
            >
              Learning Language
            </p>
          </Link>
          <Link to="/settings/password">
            <p
              className={`settings-text ${
                window.location.pathname === '/settings/password'
                  ? 'active-setting'
                  : 'inactive-setting'
              } `}
            >
              Password
            </p>
          </Link>
          {/* <p className="settings-text inactive-setting">Duolingo Plus</p>
          <p className="settings-text inactive-setting">Notifications</p> */}
          <Link to="/learn/goal">
            <p
              className={`settings-text ${
                window.location.pathname === '/learn/goal'
                  ? 'active-setting'
                  : 'inactive-setting'
              } `}
            >
              Goal
            </p>
          </Link>
          <Link to="/notifications">
            <p
              className={`settings-text ${
                window.location.pathname === '/notifications'
                  ? 'active-setting'
                  : 'inactive-setting'
              } `}
            >
              Notifications
            </p>
          </Link>
          {/* <p className="settings-text inactive-setting">Progress Sharing</p>
          <p className="settings-text inactive-setting">Privacy</p> */}
        </div>
      </div>
    </Fragment>
  );
};

export default AccountSidebar;
