/* eslint-disable react/prop-types */
import React from 'react';

const ProcessBars = props => {
  return (
    <div className="vocabcard__processbar__progress">
      <div
        className="vocabcard__processbar__progress__bar"
        style={{width: `${props.data[0] || 0}%`, height: 46}}
        id={props.data[1]}
      ></div>
    </div>
  );
};

export default ProcessBars;
