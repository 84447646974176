/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import config from '../../config';
import {Link} from 'react-router-dom';

const Analytics = () => {
  const [sessionData, setSessionData] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    axios
      .post(
        `${config.REACT_APP_NODE_API_URL}/api/admin/analytics/sessions`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(x => setSessionData(x.data.data));
  }, []);
  return (
    <div
      style={{
        overflow: 'flex',
        fontSize: '1.3rem',
        resize: 'contain',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: '100%',
        width: 'fit-content',
      }}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Link
        to="/admin/dashboard"
        className="btn btn-sm"
        style={{marginBottom: '10px'}}
      >
        Back
      </Link>
      <div
        style={{
          width: 'fit-content',
          textAlign: 'center',
          overflow: 'flex',
        }}
      >
        <br />
        <hr />
        <br />
        <h2>Session Data:</h2>
        <br />
        <hr />
        <br />
        <h3>Total Sessions: {sessionData.length}</h3>
        <h3>
          Active Sessions:{' '}
          {
            sessionData.filter(
              x => new Date(x.sessionExpiry).getTime() > Date.now()
            ).length
          }
        </h3>
        <h3>
          Expired Sessions:{' '}
          {
            sessionData.filter(
              x => new Date(x.sessionExpiry).getTime() < Date.now()
            ).length
          }
        </h3>
        <h3>
          User Bound Sessions:{' '}
          {sessionData.filter(x => x.user.length !== 0).length}
        </h3>
        <h3>
          Anonymous Sessions:{' '}
          {sessionData.filter(x => x.user.length === 0).length}
        </h3>
        <br />
        <hr />
        <br />
        <table
          className="managewords__table"
          style={{
            width: '100%',
            textAlign: 'center',
            fontSize: '1.5rem',
          }}
        >
          <thead>
            <tr className="managewords__tr">
              <th className="managewords__th" style={{textAlign: 'center'}}>
                #
              </th>
              <th className="managewords__th" style={{textAlign: 'center'}}>
                Users Associated
              </th>
              <th className="managewords__th" style={{textAlign: 'center'}}>
                Unique SessionID
              </th>
              <th className="managewords__th" style={{textAlign: 'center'}}>
                Created AT
              </th>
              <th className="managewords__th" style={{textAlign: 'center'}}>
                Session Expiry
              </th>
              <th className="managewords__th" style={{textAlign: 'center'}}>
                Expired
              </th>
            </tr>
          </thead>
          <tbody
            style={{
              width: '100%',
              textAlign: 'center',
              fontSize: '1.5rem',
            }}
          >
            {sessionData.length ? (
              sessionData.map((x, i) => (
                <tr className="managewords__tr" key={i}>
                  <td className="managewords__td">{i + 1}</td>
                  <td className="managewords__td">{x.user.length}</td>
                  <td className="managewords__td">
                    <Link to={`/admin/sessions/${x.sessionID}`}>
                      {x.sessionID}
                    </Link>
                  </td>
                  <td className="managewords__td">
                    {new Date(x.createdAt)
                      .toLocaleDateString('en-us', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })
                      .replace(',', '')}{' '}
                    {new Date(x.createdAt).toLocaleTimeString('en-US')}
                  </td>
                  <td className="managewords__td">
                    {new Date(x.sessionExpiry)
                      .toLocaleDateString('en-us', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      })
                      .replace(',', '')}{' '}
                    {new Date(x.sessionExpiry).toLocaleTimeString('en-US')}
                  </td>
                  <td className="managewords__td">
                    {(
                      new Date(x.sessionExpiry).getTime() < Date.now()
                    ).toString()}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>No active sessions (Yet)...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Analytics;
