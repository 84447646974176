import React, {useEffect, useState} from 'react';
import '../styles/components/_cookiestyles.scss';
import {BsX} from 'react-icons/bs';
import Switch from 'react-switch';

const CookiePreferences = () => {
  const [accepted, setAccepted] = useState(false);
  const [manage, setManage] = useState(false);
  const [essential, setEss] = useState(true);
  const [analytics, setAnaly] = useState(true);
  useEffect(() => {
    if (!localStorage.getItem('cookiePermission')) return;
    const permissions = JSON.parse(localStorage.getItem('cookiePermission'));
    setAnaly(permissions.analytics);
    setAccepted(permissions.essential);
  }, []);
  useEffect(() => {
    if (accepted) {
      localStorage.setItem(
        'cookiePermission',
        JSON.stringify({analytics, essential})
      );
    }
  }, [accepted, analytics, essential]);
  return (
    <div className="cookie-box" style={{display: `${accepted ? 'none' : ''}`}}>
      <div className="title">
        Cookie Policy:
        <BsX
          className="close"
          onClick={() => {
            setAccepted(true);
          }}
        />
      </div>
      <div className="desc">
        We use Cookies on this site to enhance your experience and improve our
        marketing efforts. By continuing to browse without changing your browser
        settings to block or delete Cookies, you agree to the storing of Cookies
        and related technologies on your device.{' '}
        <a href="https://gdpr.eu/cookies/" target="_blank" rel="noreferrer">
          Click here to learn more
        </a>
      </div>
      <div
        className="manage-cookies"
        style={{display: `${manage ? '' : 'none'}`}}
      >
        <div>
          <Switch
            checked={essential}
            onChange={() => {
              setEss(true);
            }}
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            height={15}
            width={48}
            disabled={true}
          />{' '}
          <span>Essential Cookies</span>
        </div>
        <div>
          <Switch
            checked={analytics}
            onChange={() => {
              setAnaly(x => !x);
            }}
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            height={15}
            width={48}
          />{' '}
          <span>Analytics Cookies</span>
        </div>
      </div>
      <div className="btn-div">
        <button
          onClick={() => {
            setAccepted(true);
          }}
        >
          Accept
        </button>
        <button
          onClick={() => {
            manage ? setAccepted(true) : setManage(true);
          }}
        >
          {manage ? 'Confirm' : 'Manage'}
        </button>
      </div>
    </div>
  );
};

export default CookiePreferences;
