import React, {useState, useEffect} from 'react';
import {Redirect, Link} from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

const AddCategory = () => {
  const [categoryName, setCategoryName] = useState('');
  const [cats, setCats] = useState([]);
  const [message, setMessage] = useState('');
  const [token] = useState(
    localStorage.getItem('adminToken')
      ? localStorage.getItem('adminToken')
      : null
  );
  const [modLang] = useState(
    localStorage.getItem('modLang')
      ? localStorage.getItem('modLang')
      : 'Something went wrong, Please login again'
  );
  useEffect(() => {
    axios
      .get(
        `${config.REACT_APP_NODE_API_URL}/api/admin/mod/get-category/${modLang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(res => {
        setCats(res.data.data.categories);
      });
  }, [modLang, token]);
  const handleSubmit = e => {
    e.preventDefault();
    setMessage('');
    const payload = {
      categoryName,
    };
    axios
      .put(
        `${config.REACT_APP_NODE_API_URL}/api/admin/mod/put-category/${modLang}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setMessage('Category added');
        setTimeout(() => {
          setMessage('');
          setCategoryName('');
        }, 1000);
      })
      .catch(err => {
        setMessage(
          err.response.data
            ? err.response.data.msg
            : 'Something went wrong, Try again'
        );
      });
  };
  if (!token) {
    localStorage.removeItem('currentAdmin');
    localStorage.removeItem('adminToken');
    return <Redirect to="/moderator" />;
  }

  const deleteCat = catName => {
    axios
      .put(
        `${config.REACT_APP_NODE_API_URL}/api/admin/mod/del-category/${modLang}`,
        {categoryName: catName},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        console.log('category deleted');
        window.location.reload();
      })
      .catch(err => console.log(err));
  };
  return (
    <div
      style={{
        padding: '15rem',
        textAlign: 'center',
        width: '70%',
        marginLeft: '10rem',
      }}
    >
      <h1>
        Language:
        {modLang === 'mun'
          ? ' Mundari'
          : modLang === 'kur'
          ? ' Kuruk'
          : modLang === 'san'
          ? ' Santhali'
          : modLang === 'hoc'
          ? ' Ho'
          : modLang === 'khr'
          ? ' Kharia'
          : modLang === 'bhb'
          ? ' Bhili'
          : modLang === 'gon'
          ? ' Gondi'
          : modLang === 'tcy'
          ? ' Tulu'
          : modLang}
      </h1>
      <br />
      <div>
        <form onSubmit={handleSubmit}>
          <label
            htmlFor="categoryName"
            style={{fontWeight: 'bold', fontSize: 16}}
          >
            Category/ LevelName
          </label>
          <input
            placeholder="Category"
            onChange={e => {
              setCategoryName(e.target.value);
              setMessage('');
            }}
            className="homepage__auth__content__form__input"
            id="categoryName"
            value={categoryName}
            required={true}
          />
          <button
            type="submit"
            className="btn btn-md homepage__auth__content__form__submit"
          >
            Add Category
          </button>
          <Link
            to="/moderator/dashboard"
            className="btn btn-sm"
            style={{marginLeft: '5rem'}}
          >
            Back
          </Link>
        </form>
        {message && <h1>{message}</h1>}
      </div>
      <div style={{width: '80%'}}>
        <br />
        <br />
        <br />
        <br />
        <table className="managewords__table" style={{width: '100%'}}>
          <thead>
            <tr className="managewords__tr">
              <th className="managewords__th">#</th>
              <th className="managewords__th">Name</th>
              <th className="managewords__th">Long Description</th>
              <th className="managewords__th">Short Desc</th>
              <th className="managewords__th">Actions</th>
            </tr>
          </thead>
          <tbody>
            {cats.map((x, i) => {
              return (
                <tr key={i} className="managewords__tr">
                  <td className="managewords__td">{i + 1}</td>
                  <td className="managewords__td">{x.categoryName}</td>
                  <td className="managewords__td">
                    {x.longDesc ? x.longDesc : 'null'}
                  </td>
                  <td className="managewords__td">
                    {x.shortDesc ? x.shortDesc : 'null'}
                  </td>
                  <td
                    className="managewords__td"
                    style={{display: 'flex', flexDirection: 'row'}}
                  >
                    <Link
                      to={{
                        pathname: `/moderator/edit-category/${x.categoryName}`,
                        state: {longDesc: x.longDesc, shortDesc: x.shortDesc},
                      }}
                    >
                      Edit
                    </Link>
                    <button
                      onClick={() => deleteCat(x.categoryName)}
                      style={{marginLeft: '.7rem'}}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddCategory;
