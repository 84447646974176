/* eslint-disable react/prop-types */
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import Footer from '../layout/Footer';
import Navbar from '../layout/Navbar';
import LoggedInFooter from '../layout/LoggedInFooter';

const TermsAndConditions = props => {
  const {id} = props;
  return (
    <Fragment>
      <Navbar />
      <section className="terms-and-conditions">
        <h1>Terms and conditions</h1>
        <div className="terms-and-conditions-content">
          <h5>Terms and Conditions of Service</h5> <br />
          {/* 1 */}
          <h5>1. General</h5>
          <p>
            Trilingo websites (“Websites”) and mobile applications (“Apps”) and
            related services (together with the Websites, the “Service”) are
            operated by Trilingo, (“Trilingo,” “us,” or “we”). Access and use of
            the Service is subject to the following Terms and Conditions of
            Service (“Terms and Conditions”). By accessing or using any part of
            the Service, you represent that you have read, understood, and agree
            to be bound by these Terms and Conditions including any future
            modifications. Trilingo may amend, update or change these Terms and
            Conditions. If we do this, we will post a notice that we have made
            changes to these Terms and Conditions on the Websites for at least 7
            days after the changes are posted and will indicate at the bottom of
            the Terms and Conditions the date these terms were last revised. Any
            revisions to these Terms and Conditions will become effective the
            earlier of (i) the end of such 7-day period or (ii) the first time
            you access or use the Service after such changes. If you do not
            agree to abide by these Terms and Conditions, you are not authorized
            to use, access or participate in the Service.
          </p>
          <p>
            PLEASE NOTE THAT THESE TERMS AND CONDITIONS CONTAIN A MANDATORY
            ARBITRATION OF DISPUTES PROVISION THAT REQUIRES THE USE OF
            ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES IN CERTAIN
            CIRCUMSTANCES, RATHER THAN JURY TRIALS OR CLASS ACTION LAWSUITS.
            VIEW THESE TERMS HERE.
          </p>
          {/* 2 */}
          <h5>2. Description of Website and Service</h5>
          <p>
            The Service allows users to access and use a variety of educational
            services, including learning or practicing a language. Trilingo may,
            in its sole discretion and at any time, update, change, suspend,
            make improvements to or discontinue any aspect of the Service,
            temporarily or permanently.
          </p>
          {/* 3 */}
          <h5>3. Registration; Submission of Content</h5>
          <h5>a. Registration</h5>
          <p>
            In connection with registering for and using the Service, you agree
          </p>
          <ul>
            <li>
              (i) to provide accurate, current and complete information about
              you and/or your organization as requested by Trilingo;
            </li>
            <li>
              (ii) to maintain the confidentiality of your password and other
              information related to the security of your account;
            </li>
            <li>
              (iii) to maintain and promptly update any registration information
              you provide to Trilingo, to keep such information accurate,
              current and complete; and
            </li>
            <li>
              (iv) to be fully responsible for all use of your account and for
              any actions that take place through your account.
            </li>
          </ul>
          <h5>b. Course Contributor Submissions</h5>
          <p>
            If you are or become a Course Contributor, you may offer to
            translate or have translated certain existing courses into other
            languages and you may offer to contribute new courses to the
            Service, as agreed between you and Trilingo on a case-by-case basis.
            Subject to any guidelines posted on the Service, you may perform any
            such translations or create any such courses in accordance with your
            own schedule and using your own facilities and resources. You are
            not required to become a Course Contributor and you may cease your
            activities as a Course Contributor at any time. You acknowledge that
            you do not desire and will not receive compensation for your
            activities as a Course Contributor or for our use of any Course
            Contributor Materials (as defined below) you submit. Any translation
            of an existing Trilingo course you submit or have submitted and any
            new language course you submit or have submitted as a Course
            Contributor (collectively, “Course Contributor Materials”) are owned
            by you (subject of course to us retaining ownership of the existing
            Trilingo course you translated). By submitting any Course
            Contributor Material, you grant us a fully paid up, royalty-free,
            perpetual, sublicensable license to reproduce, display, perform,
            modify, create derivative works of, distribute and otherwise use
            such Course Contributor Material in any manner.
          </p>
          <h5>c. General Content</h5>
          <p>
            As a condition of submitting any ratings, reviews, information,
            data, text, photographs, audio clips, audiovisual works,
            translations, flashcards or other materials on the Services
            (“Content”), you hereby grant to Trilingo a royalty free, perpetual,
            irrevocable, worldwide, nonexclusive, transferable, and
            sublicensable license to use, reproduce, copy, adapt, modify, merge,
            distribute, publicly display, create derivative works from,
            incorporate such Content into other works; sublicense through
            multiple tiers the Content, and acknowledge that this license cannot
            be terminated by you once your Content is submitted to the Services.
            You represent that you own or have secured all legal rights
            necessary for the Content submitted by you to be used by you,
            Trilingo, and others as described and otherwise contemplated in
            these Terms and Conditions. You understand that other users will
            have access to the Content and that neither they or Trilingo have
            any obligation to you or anyone else to maintain the confidentiality
            of the Content.
          </p>
          {/* 4 */}
          <h5>4. Your Representations and Warranties</h5>
          <p>
            You represent and warrant to Trilingo that your access and use of
            the Service will be in accordance with these Terms and Conditions
            and with all applicable laws, rules and regulations of the United
            States and any other relevant jurisdiction, including those
            regarding online conduct or acceptable content, and those regarding
            the transmission of data or information exported from the United
            States and/or the jurisdiction in which you reside. You further
            represent and warrant that you have created or own any material you
            submit via the Service (including Translation Materials, Course
            Contributor Materials, Activity Materials, and Content) and that you
            have the right, as applicable, to grant us a license to use that
            material as set forth above or the right to assign that material to
            us as set forth below.
          </p>
          <p>You represent and warrant that</p>
          <ul>
            <li>
              (1) you are not organized under the laws of, operating from, or
              otherwise ordinarily resident in a country or territory that is
              the target or comprehensive India{"'"}s economic or trade
              sanctions (i.e., an embargo) or
            </li>
            <li>
              (2) identified on a list of prohibited or restricted persons, such
              as the India{"'"}s Treasury Department’s List of Specially
              Designated Nationals and Blocked Persons, or
            </li>
            <li>(3) otherwise the target of India{"'"}s sanctions.</li>
          </ul>
          {/* 5 */}
          <h5>5. Inappropriate Use</h5>
          <p>
            You will not upload, display or otherwise provide on or through the
            Service any content that:
          </p>
          <ul>
            <li>
              (i) is libelous, defamatory, abusive, threatening, harassing,
              hateful, offensive or otherwise violates any law or infringes upon
              the right of any third party (including copyright, trademark,
              privacy, publicity or other personal or proprietary rights); or
            </li>
            <li>
              (ii) in Trilingo’s sole judgment, is objectionable or which
              restricts or inhibits any other person from using the Service or
              which may expose Trilingo or its users to any harm or liability of
              any kind.
            </li>
          </ul>
          {/* 6 */}
          <h5>6. Indemnification of Trilingo</h5>
          <p>
            You agree to defend, indemnify and hold harmless Trilingo and its
            directors, officers, employees, contractors, agents, suppliers,
            licensors, successors and assigns, from and against any and all
            losses, claims, causes of action, obligations, liabilities and
            damages whatsoever, including attorney{"'"}s fees, arising out of or
            relating to your access or use of the Service, any false
            representation made to us (as part of these Terms and Conditions or
            otherwise), your breach of any of these Terms and Conditions, or any
            claim that any translation we provide to you is inaccurate,
            inappropriate or defective in any way whatsoever.
          </p>
          {/* 7 */}
          <h5>7. License to Apps</h5>
          <p>
            Subject to the terms of these Terms and Conditions, Trilingo grants
            you a non-transferable, non-exclusive license to download, install,
            and use one copy of each App in object code form only on an
            interactive wireless device that you own or control. You may not
            derive or attempt to derive the source code of all or any portion of
            any App, permit any third party to derive or attempt to derive such
            source code, or reverse engineer, decompile, disassemble, or
            translate any App or any part thereof. Trilingo and its licensors
            own and shall retain all intellectual property rights and other
            rights in and to the Apps, and any changes, modifications, or
            corrections thereto.
          </p>
          {/* 8 */}
          <h5>8. Third-Party Links, Sites, and Services</h5>
          <p>
            The Service may contain links to third-party websites, advertisers,
            services, special offers, or other events or activities that are not
            owned or controlled by Trilingo. We do not endorse or assume any
            responsibility for any such third-party sites, information,
            materials, products, or services. If you access any third party
            website, service, or content from Trilingo, you understand that
            these Terms and Conditions and our Privacy Policy do not apply to
            your use of such sites. You expressly acknowledge and agree that
            Trilingo shall not be responsible or liable, directly or indirectly,
            for any damage or loss arising from your use of any third-party
            website, service, or content.
          </p>
          <p>
            The Service may include advertisements, which may be targeted to the
            Content or information on the Service, or other information. The
            types and extent of advertising by Trilingo on the Service are
            subject to change. In consideration for Trilingo granting you access
            to and use of the Service, you agree that Trilingo and its third
            party providers and partners may place such advertising in
            connection with the display of content or information submitted by
            you or others.
          </p>
          {/* 9 */}
          <h5>9. NO REPRESENTATIONS OR WARRANTIES BY TRILINGO</h5>
          <p>
            THE SERVICE, INCLUDING ALL IMAGES, AUDIO FILES AND OTHER CONTENT
            THEREIN, AND ANY OTHER INFORMATION, PROPERTY AND RIGHTS GRANTED OR
            PROVIDED TO YOU BY TRILINGO ARE PROVIDED TO YOU ON AN “AS IS” BASIS.
            TRILINGO AND ITS SUPPLIERS MAKE NO REPRESENTATIONS OR WARRANTIES OF
            ANY KIND WITH RESPECT TO THE SERVICE, EITHER EXPRESS OR IMPLIED, AND
            ALL SUCH REPRESENTATIONS AND WARRANTIES, INCLUDING WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
            NON-INFRINGEMENT, ARE EXPRESSLY DISCLAIMED. WITHOUT LIMITING THE
            GENERALITY OF THE FOREGOING, TRILINGO DOES NOT MAKE ANY
            REPRESENTATION OR WARRANTY OF ANY KIND RELATING TO ACCURACY, SERVICE
            AVAILABILITY, COMPLETENESS, INFORMATIONAL CONTENT, ERROR-FREE
            OPERATION, RESULTS TO BE OBTAINED FROM USE, OR NON-INFRINGEMENT.
            ACCESS AND USE OF THE SERVICE MAY BE UNAVAILABLE DURING PERIODS OF
            PEAK DEMAND, SYSTEM UPGRADES, MALFUNCTIONS OR SCHEDULED OR
            UNSCHEDULED MAINTENANCE OR FOR OTHER REASONS. SOME JURISDICTIONS DO
            NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE
            EXCLUSION MAY NOT APPLY TO YOU.
          </p>
          {/* 10 */}
          <h5>10. LIMITATION ON TYPES OF DAMAGES/LIMITATION OF LIABILITY</h5>
          <p>
            IN NO EVENT WILL TRILINGO BE LIABLE TO YOU OR ANY THIRD PARTY
            CLAIMING THROUGH YOU (WHETHER BASED IN CONTRACT, TORT, STRICT
            LIABILITY OR OTHER THEORY) FOR INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATING TO THE
            ACCESS OR USE OF, OR THE INABILITY TO ACCESS OR USE, THE SERVICE OR
            ANY PORTION THEREOF, INCLUDING BUT NOT LIMITED TO THE LOSS OF USE OF
            THE SERVICE, INACCURATE RESULTS, LOSS OF PROFITS, BUSINESS
            INTERRUPTION, OR DAMAGES STEMMING FROM LOSS OR CORRUPTION OF DATA OR
            DATA BEING RENDERED INACCURATE, THE COST OF RECOVERING ANY DATA, THE
            COST OF SUBSTITUTE SERVICES OR CLAIMS BY THIRD PARTIES FOR ANY
            DAMAGE TO COMPUTERS, SOFTWARE, MODEMS, TELEPHONES OR OTHER PROPERTY,
            EVEN IF TRILINGO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES. TRILINGO’S LIABILITY TO YOU OR ANY THIRD PARTY CLAIMING
            THROUGH YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF
            THE ACTION, IS LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO
            TRILINGO FOR THE SERVICE IN THE 12 MONTHS PRIOR TO THE INITIAL
            ACTION GIVING RISE TO LIABILITY. THIS IS AN AGGREGATE LIMIT. THE
            EXISTENCE OF MORE THAN ONE CLAIM HEREUNDER WILL NOT INCREASE THIS
            LIMIT.
          </p>
          {/* 11 */}
          <h5>11. Termination</h5>
          <p>
            Trilingo may terminate your access and use of the Service
            immediately at any time, for any reason, and at such time you will
            have no further right to use the Service. You may terminate your
            Trilingo account at any time by following the instructions available
            through the Service. The provisions of these Terms and Conditions
            relating to the protection and enforcement of Trilingo’s proprietary
            rights, your representations and warranties, disclaimer of
            representations and warranties, release and indemnities, limitations
            of liability and types of damages, ownership of data and
            information, governing law and venue, and miscellaneous provisions
            shall survive any such termination.
          </p>
          {/* 12 */}
          <h5>
            12. Proprietary Rights in Service Content and Activity Materials
          </h5>
          <p>
            All content available through the Service, including designs, text,
            graphics, images, information, software, audio and other files, and
            their selection and arrangement (the {'"'}Service Content{"'"}), are
            the proprietary property of Trilingo or its licensors. No Service
            Content may be modified, copied, distributed, framed, reproduced,
            republished, downloaded, scraped, displayed, posted, transmitted, or
            sold in any form or by any means, in whole or in part, other than as
            expressly permitted in these Terms and Conditions. You may not use
            any data mining, robots, scraping or similar data gathering or
            extraction methods to obtain Service Content. As between you and
            Trilingo, all data, information and materials generated from your
            access and use of the educational activities made available on or
            through the Service, including translated content generated by you
            (collectively, the “Activity Materials”), shall be exclusively owned
            by Trilingo, and you shall not have any right to use such Activity
            Materials except as expressly authorized by these Terms and
            Conditions. Activity Materials will not include Translation
            Materials. By using the Service, you hereby assign to Trilingo any
            and all rights, title and interest, including any intellectual
            property rights or proprietary rights, in the Activity Materials.
            All rights of Trilingo or its licensors that are not expressly
            granted in these Terms and Conditions are reserved to Trilingo and
            its licensors.
          </p>
          {/* 13 */}
          <h5>13. Trademarks</h5>
          <p>
            “Trilingo” and all other trademarks, service marks, graphics and
            logos used in connection with the Service are trademarks or service
            marks of Trilingo or their respective owners, and certain of them
            are registered with the United States Patent and Trademark Office.
            Access and use of the Service does not grant or provide you with the
            right or license to reproduce or otherwise use the Trilingo name or
            any Trilingo or third-party trademarks, service marks, graphics or
            logos.
          </p>
          {/* 14 */}
          <h5>14. Privacy</h5>
          <p>
            Use of the Service is also governed by our Privacy Policy, a copy of
            which is located at www.trilingo.in/privacy. By using the Service,
            you consent to the terms of the Privacy Policy.
          </p>
          {/* 15 */}
          <h5>
            15. Notice for Claims of Copyright Violations and Agent for Notice
          </h5>
          <p>
            If you are a copyright owner and have a good faith belief that any
            material available through the Service infringes upon your
            copyrights, you may submit a copyright infringement notification to
            Trilingo pursuant to the Digital Millennium Copyright Act by
            providing us with the following information in writing:
          </p>
          <ul>
            <li>
              an electronic or physical signature of the copyright owner or the
              person authorized to act on behalf of the owner of the copyright
              interest;
            </li>
            <li>
              a description of the copyrighted work that you claim has been
              infringed;
            </li>
            <li>
              a description of where the material that you claim is infringing
              is located on the Service, with enough detail that we may find it
              on the Service; your address, telephone number, and email address;
            </li>
            <li>
              a statement by you that you have a good faith belief that the
              disputed use is not authorized by the copyright owner, its agent,
              or the law; and
            </li>
            <li>
              a statement by you, made under penalty of perjury, that the above
              information in your notice is accurate and that you are the
              copyright owner or are authorized to act on the copyright owner
              {"'"}s behalf.
            </li>
          </ul>
          <p>
            Please consult your legal counsel for further details. Trilingo’s
            Agent for Notice of claims of copyright infringement can be reached
            as follows:
          </p>
          <ul>
            <li>
              By mail: Trilingo - Alfred Technologies, 613, Sushma Niketan Road
              03, Ward 13, Lobin Bagaan, Khunti 835210, Jharkhand India
            </li>
            <li>By email: abuse@trilingo.in</li>
          </ul>
          {/* 16 */}
          <h5>16. Governing Law and Arbitration; No Class Action</h5>
          <p>
            These Terms and Conditions, its subject matter and Trilingo’s and
            your respective rights under these Terms and Conditions, as well as
            and any claim, cause of action or dispute (“claim”) arising out of
            or related to these Terms and Conditions, shall be governed by and
            construed only under the laws of court of the Ranchi, Jharkahnd -
            India, excluding the conflict of law provisions of that or any other
            jurisdiction, regardless of your country of origin or where you
            access the Service. ANY DISPUTE OR CLAIM RELATING IN ANY WAY TO
            THESE TERMS AND CONDITIONS OR THE SERVICE WILL BE RESOLVED BY
            BINDING ARBITRATION, RATHER THAN IN COURT, except for Trilingo’s
            right to seek injunctive relief as set forth below. Unless otherwise
            expressly required by applicable law, each party shall bear its own
            attorneys’ fees without regard to which party is deemed the
            prevailing party in the arbitration proceeding.
          </p>
          <p>
            If you do not want to arbitrate disputes with Trilingo and you are
            an individual, you may opt out of this arbitration agreement by
            sending an email to legal@trilingo.in within 30 days of the day you
            first access or use the Service.
          </p>
          <p>
            If you intend to seek arbitration you must first send written notice
            to Trilingo’s Administration Office of your intent to arbitrate
            (“Notice”). The Notice to Trilingo should be sent by any of the
            following means:
          </p>
          <ul>
            <li>(i) electronic mail to legal@trilingo.in; or </li>
            <li>
              (ii) sending the Notice by Indian Postal Service certified mail to
              Trilingo - Alfred Technologies, 613, Sushma Niketan Road 03, Ward
              13, Lobin Bagaan, Khunti 835210, Jharkhand India. The Notice must
              <ul>
                <li>
                  (a) describe the nature and basis of the claim or dispute; and
                </li>
                <li>(b) set forth the specific relief sought; and</li>
                <li>
                  (c) set forth your name, address and contact information.
                </li>
              </ul>
            </li>
          </ul>
          <p>
            If we intend to seek arbitration against you, we will send any
            notice of dispute to you at the contact information we have for you.
          </p>
          <p>
            To the fullest extent permitted by applicable law, YOU AND TRILINGO
            EACH AGREE THAT ANY DISPUTE RESOLUTION PROCEEDING WILL BE CONDUCTED
            ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR
            REPRESENTATIVE ACTION. If for any reason a claim proceeds in court
            rather than in arbitration, YOU AND TRILINGO EACH WAIVE ANY RIGHT TO
            A JURY TRIAL. If a court of competent jurisdiction finds the
            foregoing arbitration provisions invalid or inapplicable, you and
            Trilingo agree that all claims arising out of or related to these
            Terms and Conditions must be resolved exclusively by a state or
            federal court located in Ranchi in the state of Jharkhand of India,
            and you and Trilingo each agree to submit to the exercise of
            personal jurisdiction of such courts for the purpose of litigating
            all such claims. Notwithstanding the above, you agree that Trilingo
            shall still be allowed to apply for and obtain injunctive remedies
            (or an equivalent type of urgent legal relief) in any jurisdiction.
          </p>
          {/* 17 */}
          <h5>17. Language</h5>
          <p>
            This agreement was originally written in English (US). To the extent
            any translated version of this agreement conflicts with the English
            version, the English version controls.
          </p>
          {/* 18 */}
          <h5>18. Miscellaneous</h5>
          <p>
            These Terms and Conditions constitute the entire agreement between
            Trilingo and you concerning the subject matter hereof. In the event
            that any of the Terms and Conditions are held by a court or other
            tribunal of competent jurisdiction to be unenforceable, such
            provisions shall be limited or eliminated to the minimum extent
            necessary so that these Terms and Conditions shall otherwise remain
            in full force and effect. A waiver by Trilingo or you of any
            provision of these Terms and Conditions or any breach thereof, in
            any one instance, will not waive such term or condition or any
            subsequent breach thereof. Trilingo may assign its rights or
            obligations under these Terms and Conditions without condition.
            These Terms and Conditions will be binding upon and will inure to
            the benefit of Trilingo and you, and Trilingo{"'"}s and your
            respective successors and permitted assigns.
          </p>
        </div>
      </section>
      {id ? <LoggedInFooter /> : <Footer />}
    </Fragment>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;

  return {
    id,
    error: state.auth.authError,
  };
};

export default connect(mapStateToProps)(TermsAndConditions);
