/* eslint-disable react/prop-types */
import React, {Fragment, useEffect, useState} from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import {Bar, Line} from 'react-chartjs-2';
import {Link} from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import Switch from 'react-switch';

const ProgressGraph = ({width, height}) => {
  const [labels, setLabels] = useState([]);
  const [progressData, setProgressData] = useState([]);
  const [dailyGoal, setDailyGoal] = useState(0);
  const [todaysProgress, setTodaysProgress] = useState(0);
  const [daysToggle, setDays] = useState(7);
  const [isWeek, setToggle] = useState(true);
  const [isLine, setLine] = useState(false);

  const setGraphData = latestData => {
    const lastLearnt =
      latestData.length > 0 ? latestData[latestData.length - 1] : 0;
    const userLastDate =
      lastLearnt === 0 ? new Date() : new Date(lastLearnt.date);
    const todaysDate = new Date();
    if (
      todaysDate.getDate() === userLastDate.getDate() &&
      todaysDate.getMonth() === userLastDate.getMonth()
    ) {
      setTodaysProgress(lastLearnt === 0 ? 0 : lastLearnt.wordsLearned);
    }
    let labelTemp = [];
    const progressTemp = [];
    const tempDays = [];
    for (let i = daysToggle - 1; i >= 0; i--) {
      const tempDate = new Date(todaysDate.getTime() - i * 24 * 60 * 60 * 1000);
      tempDays.push(tempDate);
      const day = tempDate.getDate();
      const month = tempDate.getMonth() + 1;
      labelTemp.push(`${day}/${month}`);
      progressTemp.push(0);
    }
    latestData.forEach(dt => {
      const dDate = new Date(`${dt.date}`);
      const day = dDate.getDate();
      const month = dDate.getMonth() + 1;
      if (labelTemp.indexOf(`${day}/${month}`) > -1)
        progressTemp[labelTemp.indexOf(`${day}/${month}`)] = dt.wordsLearned;
    });
    labelTemp = labelTemp.map(x => x.split('/')[0]);
    setLabels(labelTemp);
    setProgressData(progressTemp);
  };

  useEffect(() => {
    const currentUser = localStorage.getItem('currentUser')
      ? localStorage.getItem('currentUser')
      : null;
    const id = JSON.parse(currentUser)._id;
    axios
      .get(
        `${
          config.REACT_APP_NODE_API_URL
        }/api/algo/get-daily-progressData/${id}/${
          localStorage.getItem('languageData').split('-')[1]
        }`
      )
      .then(res => {
        setGraphData(res.data.data);
      })
      .catch(err => {
        setGraphData([]);
        console.log(err);
      });
    axios
      .get(`${config.REACT_APP_NODE_API_URL}/api/user/${id}`)
      .then(res => setDailyGoal(res.data.data.dailyGoal))
      .catch(err => console.log(err));
    return () => {
      setLabels([]);
      setProgressData([]);
    };
    // eslint-disable-next-line
  }, [daysToggle]);
  return (
    <Fragment>
      <div style={{display: 'flex', marginBottom: '3rem'}}>
        <h5 style={{flexGrow: '1', fontSize: '2.8rem'}}>Daily Goal</h5>
        <Link style={{textDecoration: 'none'}} to="/learn/goal">
          <p style={{fontSize: '2.6rem', color: '#1cb0f6', fontWeight: 700}}>
            EDIT GOAL
          </p>
        </Link>
      </div>
      <div
        style={{
          // display: 'flex',
          marginBottom: '1rem',
          marginTop: '1.5rem',
          // justifyContent: 'flex-start',
        }}
      >
        <div style={{marginTop: '1rem'}}>
          <p
            style={{
              fontSize: '2.3rem',
              marginBottom: '0.5rem',
            }}
          >
            You have completed
          </p>
          <ProgressBar
            bgColor={'#f99529'}
            className="graph-progress-bar"
            isLabelVisible={false}
            completed={Number((todaysProgress / dailyGoal) * 100)}
          />
          <p
            style={{
              marginTop: '1rem',
              marginLeft: '0.4rem',
              fontSize: '1.6rem',
              color: '#737373',
            }}
          >
            {`${
              todaysProgress > dailyGoal ? dailyGoal : todaysProgress
            }/${dailyGoal}`}
          </p>
        </div>
      </div>
      <div
        style={{
          padding: '1.5rem',
          fontSize: '1.5rem',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <span
            style={{paddingRight: '1rem', position: 'relative', top: '-9px'}}
          >
            Bar
          </span>
          <Switch
            checked={isLine}
            onChange={() => {
              setLine(x => !x);
            }}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            id="material-switch"
          />
          <span
            style={{paddingLeft: '1rem', position: 'relative', top: '-9px'}}
          >
            Line
          </span>
        </div>
        <div>
          <span
            style={{paddingRight: '1rem', position: 'relative', top: '-9px'}}
          >
            Weekly
          </span>
          <Switch
            checked={!isWeek}
            onChange={() => {
              setDays(isWeek ? 31 : 7);
              setToggle(x => !x);
            }}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            id="material-switch"
          />
          <span
            style={{paddingLeft: '1rem', position: 'relative', top: '-9px'}}
          >
            Monthly
          </span>
        </div>
      </div>
      <div>
        {isLine ? (
          <Line
            width={width}
            height={height}
            data={{
              labels: labels.length > 0 ? labels : [1, 2, 3, 4, 5], // ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
              datasets: [
                {
                  label: ' words',
                  data:
                    progressData.length > 0 ? progressData : [0, 0, 0, 0, 0], // [33, 53, 85, 41, 44, 65, 89]
                  fill: true,
                  backgroundColor: 'rgba(249,149,41,0.2)',
                  borderColor: 'rgba(249,149,41,1)',
                  tension: 0.3,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                  onClick: e => e.stopPropagation(),
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                },
                y: {
                  min: 0,
                  suggestedMax: dailyGoal,
                },
              },
            }}
          />
        ) : (
          <Bar
            width={width}
            height={height}
            data={{
              labels: labels.length > 0 ? labels : [1, 2, 3, 4, 5], // ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
              datasets: [
                {
                  label: 'words',
                  data:
                    progressData.length > 0 ? progressData : [0, 0, 0, 0, 0], // [33, 53, 85, 41, 44, 65, 89]
                  fill: true,
                  backgroundColor: 'rgba(249,149,41,0.63)',
                  borderColor: 'rgba(249,149,41,1)',
                  tension: 0.3,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                  position: 'bottom',
                  onClick: e => e.stopPropagation(),
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                },
                y: {
                  min: 0,
                  suggestedMax: dailyGoal,
                },
              },
            }}
          />
        )}
      </div>
    </Fragment>
  );
};

export default ProgressGraph;
