import React, {useState, useEffect} from 'react';
import axios from 'axios';
import config from '../../config';
import {Link} from 'react-router-dom';

const Tickets = () => {
  const [tickets, setTickets] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem('adminToken')
      ? localStorage.getItem('adminToken')
      : null;
    axios
      .get(`${config.REACT_APP_NODE_API_URL}/api/admin/tickets`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(x => setTickets(x.data.data))
      .catch(console.error);
  }, []);
  return (
    <div style={{padding: '20rem', fontSize: '1.5rem'}}>
      <Link to="/admin/dashboard" className="btn btn-sm">
        Back
      </Link>
      <br />
      <h1 className="heading-primary--sub u-center-text">Tickets</h1>
      <br />
      <hr />
      <div
        style={{
          textAlign: 'center',
        }}
      >
        {tickets.length ? (
          tickets.map((x, i) => (
            <details key={i}>
              <summary>
                <h3>
                  [{x._id}]{x.subject}
                </h3>
              </summary>
              This message was sent by: {x.name} ({x.email}) ({x.contact})
              <br />
              {x.message}
            </details>
          ))
        ) : (
          <h4>No tickets (yet)...</h4>
        )}
      </div>
    </div>
  );
};

export default Tickets;
