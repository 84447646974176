/* eslint-disable react/prop-types */
import React, {Fragment, useEffect, useState} from 'react';
import {FaLayerGroup, FaBookOpen, FaTrophy, FaTasks} from 'react-icons/fa';
import {RiRouteLine} from 'react-icons/ri';
import {HiSpeakerphone} from 'react-icons/hi';
import Tabs from '../../components/Tabs';
import '../../styles/pages/user/_all-notifications.scss';
import axios from 'axios';
import config from '../../config';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {updateProfileData} from '../../store/actions/userActions';

/**
 * @author NevetsKuro <stevenfernz96@gmail.com>
 * @function AllNotifications
 * @description Page for viewing all notification
 * @param {{id:string}} props - current user id
 * @returns {import('react').ReactFragment} JSX element
 */
const AllNotifications = props => {
  const [tabItems, setTabItems] = useState([]);
  const [tabData, setTabData] = useState([]);
  const {id} = props;
  useEffect(() => {
    setTabItems([
      {
        icon: <FaLayerGroup />,
        name: 'All',
      },
      {
        icon: <HiSpeakerphone />,
        name: 'Announcement',
      },
      {
        icon: <FaBookOpen />,
        name: 'Lesson',
      },
      {
        icon: <RiRouteLine />,
        name: 'Course',
      },
      {
        icon: <FaTasks />,
        name: 'Exercise',
      },
      {
        icon: <FaTrophy />,
        name: 'Achievement',
      },
    ]);
  }, []);

  /**
   * @author NevetsKuro <stevenfernz96@gmail.com>
   * @description fetch all notifications by today's date
   */
  useEffect(() => {
    if (id) {
      axios({
        method: 'get',
        url: `${config.REACT_APP_NODE_API_URL}/api/user/notifications/${id}`,
      })
        .then(res => {
          setTabData(res.data.data?.notificationsData);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [id]);

  if (!id) return <Redirect to="/" />;
  /**
   * @function render
   * @returns {import('react').ReactFragment} -
   */
  return (
    <Fragment>
      <section className="all-notifications">
        <div className="all-notifications-header">
          <h2>All Your Notifications at one Place</h2>
        </div>
        <div className="all-notifications-body">
          <Tabs tabItems={tabItems} tabData={tabData} />
        </div>
      </section>
    </Fragment>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;

  return {
    id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProfileData: payload => dispatch(updateProfileData(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AllNotifications);
