import React, {useState, useEffect} from 'react';
import axios from 'axios';
import config from '../../config';
import {Link} from 'react-router-dom';

const Analytics = () => {
  const [languageData, setLanguageData] = useState([]);
  const [partialData, setPartialData] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    axios
      .get(`${config.REACT_APP_NODE_API_URL}/api/admin/analytics/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(x => {
        console.log(x.data.data);
        setLanguageData(x.data.data);
        setPartialData(x.data.partialData);
        setSessionData(x.data.sessionData);
      });
  }, []);
  return (
    <div
      style={{
        overflow: 'flex',
        fontSize: '1.3rem',
        resize: 'contain',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        paddingTop: '10%',
        height: '100%',
        width: 'fit-content',
      }}
    >
      <Link
        to="/admin/dashboard"
        className="btn btn-sm"
        style={{marginBottom: '10px'}}
      >
        Back
      </Link>
      <br />
      <hr />
      <br />
      <h1 style={{textAlign: 'center'}}>User Analytics: </h1>
      <br />
      <hr />
      <br />
      <div style={{textAlign: 'center'}}>
        <h3>{`Total Accounts: ${languageData.length}`}</h3>
        <h3>
          {`Active Accounts: ${
            languageData.filter(x => x?.isAccountActive).length
          }`}
        </h3>
        <h3>
          {`Languages Active: ${
            [...new Set(languageData.map(x => x?.languageData))].length
          }`}
        </h3>
      </div>
      <br />
      <hr />
      <br />
      <table className="managewords__table" style={{width: '100%'}}>
        <thead>
          <tr className="managewords__tr">
            <th className="managewords__th">#</th>
            <th className="managewords__th">Name</th>
            <th className="managewords__th">Email</th>
            <th className="managewords__th">Account Status</th>
            <th className="managewords__th">Language</th>
            <th className="managewords__th">Unique User Tag</th>
            <th className="managewords__th">Days Practiced</th>
            <th className="managewords__th">Total Words Learnt</th>
            <th className="managewords__th">Average Words/Day</th>
            <th className="managewords__th">Effective Time Spent</th>
            <th className="managewords__th">Active Sessions</th>
          </tr>
        </thead>
        <tbody>
          {languageData.map((x, i) => {
            return (
              <tr key={i} className="managewords__tr">
                <td className="managewords__td">{i + 1}</td>
                <td className="managewords__td">{x.name}</td>
                <td className="managewords__td">{x.email}</td>
                <td className="managewords__td">
                  {x?.isAccountActive
                    ? 'Active'
                    : x?.isEmailSent
                    ? 'Not Verified'
                    : 'Inactive'}
                </td>
                <td className="managewords__td">
                  {x?.languageData || 'Unknown'}
                </td>
                <td className="managewords__td">
                  <Link to={`/admin/user-analytics/${x._id}`}>{x._id}</Link>
                </td>
                <td className="managewords__td">
                  {partialData.find(y => y.id === x._id)?.totalDays || 0}
                </td>
                <td className="managewords__td">
                  {partialData.find(y => y.id === x._id)?.total || 0}
                </td>
                <td className="managewords__td">
                  {partialData.find(y => y.id === x._id)?.average || 0}
                </td>
                <td className="managewords__td">{x.effectiveTimeSpent}</td>
                <td className="managewords__td">
                  {sessionData.filter(y => y.user.includes(x._id)).length}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Analytics;
