import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

const ReShuffleCategory = () => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [deletedData, setDeletedData] = useState([]);
  const [bulkUpdate, setBulkUpdate] = useState({
    category: 'Selected for bulk update',
    words: [],
  });
  const [update, setUpdate] = useState('');
  const [message, setMessage] = useState('');
  const [modLang] = useState(
    localStorage.getItem('modLang') ? localStorage.getItem('modLang') : null
  );
  const [token] = useState(
    localStorage.getItem('adminToken')
      ? localStorage.getItem('adminToken')
      : null
  );
  useEffect(() => {
    axios
      .get(`${config.REACT_APP_NODE_API_URL}/api/vocab/get-category/${modLang}`)
      .then(res => {
        setCategories(res.data.data.categories.map(x => x.categoryName));
        const cats = res.data.data.categories.map(x => x.categoryName);
        const table = [];
        const deletedTable = [];
        axios
          .get(
            `${config.REACT_APP_NODE_API_URL}/api/vocab/get/vocab-by-langauge/${modLang}`
          )
          .then(res2 => {
            const word = res2.data.data.vocabList;
            cats.forEach(x =>
              table.push({
                category: x,
                words: word.filter(y => y.category === x),
              })
            );
            setData(table);
            const deleted = [
              ...new Set(word.map(item => item.category)),
            ].filter(x => !cats.includes(x));
            deleted.forEach(x =>
              deletedTable.push({
                category: x,
                words: word.filter(y => y.category === x),
              })
            );
            setDeletedData(deletedTable);
          });
      });
  }, [modLang]);
  let language;
  if (modLang) {
    language =
      modLang === 'mun'
        ? ' Mundari'
        : modLang === 'kur'
        ? ' Kuruk'
        : modLang === 'san'
        ? ' Santhali'
        : modLang === 'hoc'
        ? ' Ho'
        : modLang === 'khr'
        ? ' Kharia'
        : modLang === 'bhb'
        ? ' Bhili'
        : modLang === 'gon'
        ? ' Gondi'
        : modLang === 'tcy'
        ? ' Tulu'
        : 'Error';
  }
  return (
    <div style={{padding: '20rem', fontSize: '1.5rem'}}>
      <Link to="/moderator/dashboard" className="btn btn-sm">
        Back
      </Link>
      <h1 style={{textAlign: 'center'}}>Language: {language}</h1>
      <br />
      <h1 className="heading-primary--sub u-center-text">{message}</h1>
      <br />
      <hr />
      <br />
      {bulkUpdate.words.length ? (
        <React.Fragment>
          <h2>Selected for bulk update ({bulkUpdate.words.length}): </h2>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
            onSubmit={e => {
              e.preventDefault();
              if (update === '') return;
              setMessage('');
              axios
                .post(
                  `${config.REACT_APP_NODE_API_URL}/api/admin/mod/bulk-update-word-category/${modLang}`,
                  {
                    newCategoryName: update,
                    words: bulkUpdate.words.map(x => x._id),
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then(res => {
                  setMessage(`${res.data.status}: ${res.data.msg}`);
                })
                .catch(console.error);
            }}
          >
            <select onChange={e => setUpdate(e.target.value)}>
              <option>{'Select a Category'}</option>
              {categories.map((a, aIndex) => (
                <option key={aIndex} value={a}>
                  {a}
                </option>
              ))}
            </select>
            <button
              style={{
                backgroundColor: '#fffff',
              }}
              type="submit"
            >
              Update
            </button>
          </form>
          <div>
            <details>
              <summary>
                <h3 style={{textAlign: 'center'}}>{bulkUpdate.category}</h3>
              </summary>
              <table className="managewords__table">
                <thead>
                  <tr className="managewords__tr">
                    <th className="managewords__th">#</th>
                    <th className="managewords__th">English</th>
                    <th className="managewords__th">Hindi</th>
                    <th className="managewords__th">{language}(English)</th>
                    <th className="managewords__th">{language}(Hindi)</th>
                    <th className="managewords__th">Category</th>
                    <th className="managewords__th">Moderator</th>
                    <th className="managewords__th">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {bulkUpdate.words.length > 0 ? (
                    bulkUpdate.words.map((word, idx) => (
                      <tr key={idx} className="managewords__tr">
                        <td className="managewords__td">{idx + 1}</td>
                        <td className="managewords__td">
                          {word.englishInEnglish
                            ? word.englishInEnglish
                            : 'null'}
                        </td>
                        <td className="managewords__td">
                          {word.hindiInHindi ? word.hindiInHindi : 'null'}
                        </td>
                        <td className="managewords__td">
                          {modLang === 'mun'
                            ? word.mundariInEnglish
                            : modLang === 'kur'
                            ? word.kurukInEnglish
                            : modLang === 'san'
                            ? word.santhaliInEnglish
                            : modLang === 'hoc'
                            ? word.hoInEnglish
                            : modLang === 'khr'
                            ? word.khariaInEnglish
                            : modLang === 'bhb'
                            ? word.bhiliInEnglish
                            : modLang === 'gon'
                            ? word.gondiInEnglish
                            : modLang === 'tcy'
                            ? word.tuluInEnglish
                            : 'null'}
                        </td>
                        <td className="managewords__td">
                          {modLang === 'mun'
                            ? word.mundariInHindi
                            : modLang === 'kur'
                            ? word.kurukInHindi
                            : modLang === 'san'
                            ? word.santhaliInHindi
                            : modLang === 'hoc'
                            ? word.hoInHindi
                            : modLang === 'khr'
                            ? word.khariaInHindi
                            : modLang === 'bhb'
                            ? word.bhiliInHindi
                            : modLang === 'gon'
                            ? word.gondiInHindi
                            : modLang === 'tcy'
                            ? word.tuluInHindi
                            : 'null'}
                        </td>
                        <td className="managewords__td">{word.category}</td>
                        <td className="managewords__td">
                          {word.moderator ? word.moderator.email : 'null'}
                        </td>
                        <td
                          className="managewords__td"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <button
                            style={{
                              backgroundColor: '#fffff',
                            }}
                            type="button"
                            onClick={e => {
                              setBulkUpdate(id => ({
                                category: id.category,
                                words: id.words.filter(
                                  words => words._id !== word._id
                                ),
                              }));
                            }}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>No words found..</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </details>
          </div>
          <br />
          <hr />
          <br />
        </React.Fragment>
      ) : (
        ''
      )}
      {data.map((x, i) => {
        return (
          <React.Fragment key={i}>
            <div>
              <details>
                <summary>
                  <h3
                    style={{textAlign: 'center'}}
                  >{`${x.category} (${x.words.length})`}</h3>
                </summary>
                <table className="managewords__table">
                  <thead>
                    <tr className="managewords__tr">
                      <th className="managewords__th">#</th>
                      <th className="managewords__th">English</th>
                      <th className="managewords__th">Hindi</th>
                      <th className="managewords__th">{language}(English)</th>
                      <th className="managewords__th">{language}(Hindi)</th>
                      <th className="managewords__th">Category</th>
                      <th className="managewords__th">Moderator</th>
                      <th className="managewords__th">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {x.words.length > 0 ? (
                      x.words.map((word, idx) => (
                        <tr
                          key={idx}
                          className={`managewords__tr ${
                            bulkUpdate.words.includes(word)
                              ? 'selected_bulk_update'
                              : ''
                          }`}
                        >
                          <td className="managewords__td">{idx + 1}</td>
                          <td className="managewords__td">
                            {word.englishInEnglish
                              ? word.englishInEnglish
                              : 'null'}
                          </td>
                          <td className="managewords__td">
                            {word.hindiInHindi ? word.hindiInHindi : 'null'}
                          </td>
                          <td className="managewords__td">
                            {modLang === 'mun'
                              ? word.mundariInEnglish
                              : modLang === 'kur'
                              ? word.kurukInEnglish
                              : modLang === 'san'
                              ? word.santhaliInEnglish
                              : modLang === 'hoc'
                              ? word.hoInEnglish
                              : modLang === 'khr'
                              ? word.khariaInEnglish
                              : modLang === 'bhb'
                              ? word.bhiliInEnglish
                              : modLang === 'gon'
                              ? word.gondiInEnglish
                              : modLang === 'tcy'
                              ? word.tuluInEnglish
                              : 'null'}
                          </td>
                          <td className="managewords__td">
                            {modLang === 'mun'
                              ? word.mundariInHindi
                              : modLang === 'kur'
                              ? word.kurukInHindi
                              : modLang === 'san'
                              ? word.santhaliInHindi
                              : modLang === 'hoc'
                              ? word.hoInHindi
                              : modLang === 'khr'
                              ? word.khariaInHindi
                              : modLang === 'bhb'
                              ? word.bhiliInHindi
                              : modLang === 'gon'
                              ? word.gondiInHindi
                              : modLang === 'tcy'
                              ? word.tuluInHindi
                              : 'null'}
                          </td>
                          <td className="managewords__td">{word.category}</td>
                          <td className="managewords__td">
                            {word.moderator ? word.moderator.email : 'null'}
                          </td>
                          <td
                            className="managewords__td"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <form
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                              onSubmit={e => {
                                e.preventDefault();
                                if (update === '') return;
                                setMessage('');
                                axios
                                  .post(
                                    `${config.REACT_APP_NODE_API_URL}/api/admin/mod/update-word-category/${modLang}`,
                                    {
                                      newCategoryName: update,
                                      word: {
                                        _id: word._id,
                                        category: word.category,
                                      },
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer ${token}`,
                                      },
                                    }
                                  )
                                  .then(res => {
                                    setMessage(
                                      `${res.data.status}: ${res.data.msg}`
                                    );
                                  })
                                  .catch(console.error);
                              }}
                            >
                              <select onChange={e => setUpdate(e.target.value)}>
                                <option>{word.category}</option>
                                {categories
                                  .filter(a => a !== word.category)
                                  .map((a, aIndex) => (
                                    <option key={aIndex} value={a}>
                                      {a}
                                    </option>
                                  ))}
                              </select>
                              <button
                                style={{
                                  backgroundColor: '#fffff',
                                }}
                                type="submit"
                              >
                                Update
                              </button>
                              <button
                                style={{
                                  backgroundColor: '#fffff',
                                }}
                                type="button"
                                onClick={e => {
                                  if (e.currentTarget.innerHTML === 'Remove') {
                                    setBulkUpdate(id => ({
                                      category: id.category,
                                      words: id.words.filter(
                                        words => words._id !== word._id
                                      ),
                                    }));
                                  } else {
                                    setBulkUpdate(id => {
                                      id.words.push(word);
                                      return {
                                        category: id.category,
                                        words: id.words,
                                      };
                                    });
                                  }
                                }}
                              >
                                {bulkUpdate.words.includes(word)
                                  ? 'Remove'
                                  : 'Add for bulk update'}
                              </button>
                            </form>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7}>No words found..</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </details>
            </div>
            <br />
            <hr />
            <br />
          </React.Fragment>
        );
      })}
      <hr />
      <h2>Deleted Categories: </h2>
      {deletedData.map((x, i) => {
        return (
          <React.Fragment key={i}>
            <div>
              <details>
                <summary>
                  <h3 style={{textAlign: 'center'}}>
                    {`[Deleted]: ${x.category} (${x.words.length})`}
                  </h3>
                </summary>
                <table className="managewords__table">
                  <thead>
                    <tr className="managewords__tr">
                      <th className="managewords__th">#</th>
                      <th className="managewords__th">English</th>
                      <th className="managewords__th">Hindi</th>
                      <th className="managewords__th">{language}(English)</th>
                      <th className="managewords__th">{language}(Hindi)</th>
                      <th className="managewords__th">Category</th>
                      <th className="managewords__th">Moderator</th>
                      <th className="managewords__th">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {x.words.length > 0 ? (
                      x.words.map((word, idx) => (
                        <tr
                          key={idx}
                          className={`managewords__tr ${
                            bulkUpdate.words.includes(word)
                              ? 'selected_bulk_update'
                              : ''
                          }`}
                        >
                          <td className="managewords__td">{idx + 1}</td>
                          <td className="managewords__td">
                            {word.englishInEnglish
                              ? word.englishInEnglish
                              : 'null'}
                          </td>
                          <td className="managewords__td">
                            {word.hindiInHindi ? word.hindiInHindi : 'null'}
                          </td>
                          <td className="managewords__td">
                            {modLang === 'mun'
                              ? word.mundariInEnglish
                              : modLang === 'kur'
                              ? word.kurukInEnglish
                              : modLang === 'san'
                              ? word.santhaliInEnglish
                              : modLang === 'hoc'
                              ? word.hoInEnglish
                              : modLang === 'khr'
                              ? word.khariaInEnglish
                              : modLang === 'bhb'
                              ? word.bhiliInEnglish
                              : modLang === 'gon'
                              ? word.gondiInEnglish
                              : modLang === 'tcy'
                              ? word.tuluInEnglish
                              : 'null'}
                          </td>
                          <td className="managewords__td">
                            {modLang === 'mun'
                              ? word.mundariInHindi
                              : modLang === 'kur'
                              ? word.kurukInHindi
                              : modLang === 'san'
                              ? word.santhaliInHindi
                              : modLang === 'hoc'
                              ? word.hoInHindi
                              : modLang === 'khr'
                              ? word.khariaInHindi
                              : modLang === 'bhb'
                              ? word.bhiliInHindi
                              : modLang === 'gon'
                              ? word.gondiInHindi
                              : modLang === 'tcy'
                              ? word.tuluInHindi
                              : 'null'}
                          </td>
                          <td className="managewords__td">{word.category}</td>
                          <td className="managewords__td">
                            {word.moderator ? word.moderator.email : 'null'}
                          </td>
                          <td
                            className="managewords__td"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                            }}
                          >
                            <form
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                              onSubmit={e => {
                                e.preventDefault();
                                if (update === '') return;
                                setMessage('');
                                axios
                                  .post(
                                    `${config.REACT_APP_NODE_API_URL}/api/admin/mod/update-word-category/${modLang}`,
                                    {
                                      newCategoryName: update,
                                      word: {
                                        _id: word._id,
                                        category: word.category,
                                      },
                                    },
                                    {
                                      headers: {
                                        Authorization: `Bearer ${token}`,
                                      },
                                    }
                                  )
                                  .then(res => {
                                    setMessage(
                                      `${res.data.status}: ${res.data.msg}`
                                    );
                                  })
                                  .catch(console.error);
                              }}
                            >
                              <select onChange={e => setUpdate(e.target.value)}>
                                <option>{word.category}</option>
                                {categories
                                  .filter(a => a !== word.category)
                                  .map((a, aIndex) => (
                                    <option key={aIndex} value={a}>
                                      {a}
                                    </option>
                                  ))}
                              </select>
                              <button
                                style={{
                                  backgroundColor: '#fffff',
                                }}
                                type="submit"
                              >
                                Update
                              </button>
                              <button
                                style={{
                                  backgroundColor: '#fffff',
                                }}
                                type="button"
                                onClick={e => {
                                  if (e.currentTarget.innerHTML === 'Remove') {
                                    setBulkUpdate(id => ({
                                      category: id.category,
                                      words: id.words.filter(
                                        words => words._id !== word._id
                                      ),
                                    }));
                                  } else {
                                    setBulkUpdate(id => {
                                      id.words.push(word);
                                      return {
                                        category: id.category,
                                        words: id.words,
                                      };
                                    });
                                  }
                                }}
                              >
                                {bulkUpdate.words.includes(word)
                                  ? 'Remove'
                                  : 'Add for bulk update'}
                              </button>
                            </form>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={7}>No words found..</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </details>
            </div>
            <br />
            <hr />
            <br />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ReShuffleCategory;
