/* eslint-disable react/prop-types */
import React from 'react';
// import ResultSummary from './ResultSummary';
import {postResultData} from '../utils/quizResult';
import Progress from './QuizProcessbar';
import config from '../config';
import axios from 'axios';
import SocialShare from './SocialShare';

const QuizResult = props => {
  const {questions, options, questionLevel, timeTaken, language} = props;
  let totCorrect = 0;
  let totWrong = 0;
  let notAttempt = 0;
  const quizData = [];
  questions.forEach((q, i) => {
    if (q.right_ans === options[i]) {
      q.isCorrect = true;
      totCorrect++;
    } else if (options[i] === 'null') {
      notAttempt++;
    } else {
      q.isCorrect = false;
      totWrong++;
    }
    quizData.push({
      question: q.question,
      right_ans: q.right_ans,
      user_ans: options[i],
      time: timeTaken[i],
    });
  });
  postResultData(totCorrect, quizData, questionLevel);
  if (totCorrect / questions.length > 0.6) {
    new Audio('/assets/audio/quiz-pass.wav').play();
  }

  const nextLevelHandler = () => {
    axios({
      method: 'get',
      url: `${config.REACT_APP_NODE_API_URL}/api/vocab/next/${questionLevel}/${
        language.split('-')[1]
      }`,
    })
      .then(res => {
        window.location.pathname =
          `/vocab/category/` + res.data.data.nextCategory;
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className="result">
      <div className="result-heading">
        <h2>
          You scored {totCorrect} out of {questions.length}
          {'! '}
        </h2>
      </div>
      <div className="result-bars">
        <div>
          <div className="bar-heading">
            Correct: {totCorrect} / {questions.length}
          </div>
          <Progress done={(totCorrect / questions.length) * 100} />
        </div>

        <div>
          <div className="bar-heading">
            Wrong: {totWrong} / {questions.length}
          </div>
          <Progress done={(totWrong / questions.length) * 100} />
        </div>
        <div>
          <div className="bar-heading">
            Not Attempted: {notAttempt} / {questions.length}
          </div>
          <Progress done={(notAttempt / questions.length) * 100} />
        </div>
      </div>
      <h1 style={{textAlign: 'center'}}>Summary</h1>
      <div className="result-questions">
        {questions.map((q, index) => (
          <div key={q._id}>
            <div className="question">
              <h4>
                {index + 1}. {q.question}
              </h4>
              <div className="answers">
                <h5 className="correct">Correct option : {q.right_ans}</h5>
                <h5 className={`${q.isCorrect ? 'correct' : 'wrong'}`}>
                  {options[index] === 'null'
                    ? 'Not answered'
                    : `Your answer: ${options[index]}`}
                </h5>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="next-steps">
        <h1>
          {totCorrect / questions.length > 0.6
            ? 'You have unlocked the next level!'
            : 'Failure is the stepping stone of success.'}
        </h1>
        {totCorrect / questions.length > 0.6 ? (
          <SocialShare language={language.split('-')[1]} />
        ) : (
          <></>
        )}
        {totCorrect / questions.length > 0.6 ? (
          <button onClick={nextLevelHandler}>Continue to next level</button>
        ) : (
          <button
            onClick={() => {
              window.location.pathname = `/vocab/category/${questionLevel}`;
            }}
          >
            Learn {questionLevel} again
          </button>
        )}
      </div>
    </div>
  );
};

export default QuizResult;

// <div>
//   <ResultSummary questionLevel={questionLevel} />
// </div>;
