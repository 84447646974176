import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import axios from 'axios';
import config from '../../config';

const ManageWords = () => {
  const [words, setWords] = useState([]);
  const [search, setSearch] = useState('');
  const [modLang] = useState(
    localStorage.getItem('modLang') ? localStorage.getItem('modLang') : null
  );
  useEffect(() => {
    axios
      .get(
        `${config.REACT_APP_NODE_API_URL}/api/vocab/get/vocab-by-langauge/${modLang}`
      )
      .then(res => {
        console.log(res.data);
        setWords(res.data.data.vocabList);
      })
      .catch(err => {
        console.log(err);
      });

    return () => {
      setWords([]);
    };
  }, [modLang]);

  const searchBar = () => {
    return (
      <div
        style={{
          display: 'flex',
          width: '80rem',
          margin: '2rem auto',
          height: '3.5rem',
        }}
      >
        <input
          type="text"
          className="form-control"
          name="search_query"
          id="search_query"
          style={{flex: 1, fontSize: '2rem'}}
          placeholder="Search for word"
          onChange={e => setSearch(e.target.value)}
        />
      </div>
    );
  };

  const filteredWords = words.filter(word => {
    if (typeof word.englishInEnglish === 'string') {
      return word.englishInEnglish.toLowerCase().includes(search.toLowerCase());
    } else if (typeof word.hindiInHindi === 'string') {
      return word.hindiInHindi.includes(search);
    }
    return word;
  });

  let language;
  if (modLang) {
    language =
      modLang === 'mun'
        ? ' Mundari'
        : modLang === 'kur'
        ? ' Kuruk'
        : modLang === 'san'
        ? ' Santhali'
        : modLang === 'hoc'
        ? ' Ho'
        : modLang === 'khr'
        ? ' Kharia'
        : modLang === 'bhb'
        ? ' Bhili'
        : modLang === 'gon'
        ? ' Gondi'
        : modLang === 'tcy'
        ? ' Tulu'
        : modLang;
  }

  return (
    <div style={{padding: '20rem', fontSize: '1.5rem'}}>
      <Link to="/moderator/dashboard" className="btn btn-sm">
        Back
      </Link>
      <h1 style={{textAlign: 'center'}}>Language: {language}</h1>
      <br />
      {searchBar()}
      <div>
        <table className="managewords__table">
          <thead>
            <tr className="managewords__tr">
              <th className="managewords__th">#</th>
              <th className="managewords__th">English</th>
              <th className="managewords__th">Hindi</th>
              <th className="managewords__th">{language}(English)</th>
              <th className="managewords__th">{language}(Hindi)</th>
              <th className="managewords__th">Category</th>
              {/* <th className="managewords__th">Moderator</th> */}
              <th className="managewords__th">Quiz</th>
            </tr>
          </thead>
          <tbody>
            {words.length > 0 ? (
              filteredWords.map((word, idx) => (
                <tr key={word._id} className="managewords__tr">
                  <td className="managewords__td">{idx + 1}</td>
                  <td className="managewords__td">
                    {word.englishInEnglish ? word.englishInEnglish : 'null'}
                  </td>
                  <td className="managewords__td">
                    {word.hindiInHindi ? word.hindiInHindi : 'null'}
                  </td>
                  <td className="managewords__td">
                    {modLang === 'mun'
                      ? word.mundariInEnglish
                      : modLang === 'kur'
                      ? word.kurukInEnglish
                      : modLang === 'san'
                      ? word.santhaliInEnglish
                      : modLang === 'hoc'
                      ? word.hoInEnglish
                      : modLang === 'khr'
                      ? word.khariaInEnglish
                      : modLang === 'bhb'
                      ? word.bhiliInEnglish
                      : modLang === 'gon'
                      ? word.gondiInEnglish
                      : modLang === 'tcy'
                      ? word.tuluInEnglish
                      : 'null'}
                  </td>
                  <td className="managewords__td">
                    {modLang === 'mun'
                      ? word.mundariInHindi
                      : modLang === 'kur'
                      ? word.kurukInHindi
                      : modLang === 'san'
                      ? word.santhaliInHindi
                      : modLang === 'hoc'
                      ? word.hoInHindi
                      : modLang === 'khr'
                      ? word.khariaInHindi
                      : modLang === 'bhb'
                      ? word.bhiliInHindi
                      : modLang === 'gon'
                      ? word.gondiInHindi
                      : modLang === 'tcy'
                      ? word.tuluInHindi
                      : 'null'}
                  </td>
                  <td className="managewords__td">{word.category}</td>
                  {/* <td className="managewords__td">
                    {word.moderator ? word.moderator.email : "null"}
                  </td> */}
                  <td>
                    <Link
                      to={{
                        pathname: `/moderator/manage-questions/${word.hindiInHindi?.trim()}`,
                        state: {category: word.category},
                      }}
                    >
                      Question(s)
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>No words found..</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageWords;
