/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import config from '../config';

import '../styles/components/_socialshare.scss';
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaWhatsappSquare,
} from 'react-icons/fa';

/**
 * @author Kn0wn-Un <naikdarsh175@gmail.com>
 * @param {{string}} props contains name of language
 * @description displaying social share buttons
 * @returns {boolean} returns boolean
 */
const SocialShare = props => {
  const [langName, setName] = useState('');
  const [fbLink, setFB] = useState('');
  const [twLink, setTw] = useState('');
  const [whLink, setWh] = useState('');
  useEffect(() => {
    axios
      .get(`${config.REACT_APP_NODE_API_URL}/api/vocab/public/language-data`)
      .then(res => {
        const lang = res.data.data?.find(
          x => x.languageCode === props.language
        );
        setName(lang.name);
      });
    setFB(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        'https://www.trilingo.in/'
      )}&quote=${encodeURIComponent(
        `Yayee!\n I just completed a ${langName} lesson in TriLingo!\nIt's fun and easy to use.
        \nJoin today to explore more about ${langName} Language & Culture.\n\n#${langName}#TriLingo#LanguageLearning#Culture`
      )}&hashtag=${encodeURIComponent('#TriLingo')}`
    );
    setTw(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        `Yayee!\n I just completed a ${langName} lesson in TriLingo!\nIt's fun and easy to use.
        \nJoin today to explore more about ${langName} Language & Culture.\n`
      )}&hashtags=${langName},TriLingo,LanguageLearning,Culture&url=${encodeURIComponent(
        'https://www.trilingo.in/ \n\n'
      )}`
    );
    setWh(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `Yayee!\n I just completed a ${langName} lesson in TriLingo!\nIt's fun and easy to use.
          \nJoin today to explore more about ${langName} Language & Culture.\nhttps://www.trilingo.in/\n\n#${langName}#TriLingo#LanguageLearning#Culture`
      )}`
    );
  }, [props.language, langName]);

  return (
    <div className="social-share-div">
      <h1>Woohoo! Way to go 💪</h1>
      <h3>
        We are as happy as you are, but happiness is only real when{' '}
        <i style={{color: '#f99529'}}>Shared</i> 😉
      </h3>
      <div className="share-buttons-div">
        <div className="share-button">
          <a href={fbLink} target="_blank" rel="noreferrer">
            <FaFacebookSquare style={{color: '#4267B2'}} />
          </a>
        </div>
        <div className="share-button">
          <a href={twLink} target="_blank" rel="noreferrer">
            <FaTwitterSquare style={{color: '#1DA1F2'}} />
          </a>
        </div>
        <div className="share-button wh-icon">
          <a href={whLink} target="_blank" rel="noreferrer">
            <FaWhatsappSquare style={{color: '#25D366'}} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SocialShare;
