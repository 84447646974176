import React, {Fragment} from 'react';
import {
  GrTwitter,
  GrFacebookOption,
  GrLinkedinOption,
  GrInstagram,
} from 'react-icons/gr';
import {Link} from 'react-router-dom';

const LoggedInFooter = () => {
  return (
    <Fragment>
      <footer className="logged-in-footer">
        <div className="logged-in-footer-content">
          <p>
            &copy; {new Date().getFullYear()} Trilingo. All Rights Reserved.
          </p>
          <Link to="/about">
            <p className="logged-in-footer-links">About</p>
          </Link>
          <Link to="/contact">
            <p className="logged-in-footer-links">Contact Us</p>
          </Link>
          <Link to="/terms-and-conditions">
            <p className="logged-in-footer-links">Terms &amp; Conditions</p>
          </Link>
          <Link to="/privacy-policy">
            <p className="logged-in-footer-links">Privacy</p>
          </Link>
          <div className="logged-in-social-media-icons logged-in-footer-links">
            <span>
              <GrFacebookOption />
            </span>
            <span>
              <GrInstagram />
            </span>
            <span>
              <GrTwitter />
            </span>
            <span>
              <GrLinkedinOption />
            </span>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default LoggedInFooter;
