/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

const questionType = [
  {
    name: 'MCQ',
    value: 1,
  },
  {
    name: 'Fill in the blanks',
    value: 3,
  },
  {
    name: 'Match the sets',
    value: 4,
  },
];

const AddQuestion = props => {
  const [values, setValues] = useState({
    language: '',
    question_level: '',
    question: '',
    options: [],
    opt_1: '',
    opt_2: '',
    opt_3: '',
    opt_4: '',
    right_ans: '',
    question_type: 1,
    success: '',
    error: '',
  });
  const word = props.match.params.word;
  const modLang = localStorage.getItem('modLang')
    ? localStorage.getItem('modLang')
    : null;
  const {
    question,
    opt_1,
    opt_2,
    opt_3,
    opt_4,
    options,
    success,
    error,
    question_type,
  } = values;
  const handleChange = name => event => {
    const val = event.target.value;
    setValues({...values, error: '', [name]: val});
  };
  const handleSubmit = e => {
    e.preventDefault();
    setValues({...values, error: ''});

    if (opt_1 && opt_2 && opt_3 && opt_4) {
      options.push(opt_1);
      options.push(opt_2);
      options.push(opt_3);
      options.push(opt_4);
    } else {
      setValues({...values, error: 'Enter all fields'});
      return;
    }
    const payload = {
      language: `hin-${modLang}`,
      question_level: props.location.state.category,
      question,
      options,
      word: word.trim(),
      right_ans: opt_1,
      question_type,
    };

    axios
      .post(`${config.REACT_APP_NODE_API_URL}/api/quiz/add`, payload)
      .then(res => {
        console.log(res.data);
        console.log('data saved');
        setValues({
          ...values,
          question: '',
          right_ans: '',
          opt_1: '',
          opt_2: '',
          opt_3: '',
          opt_4: '',
          options: [],
          success: 'Question added sucessfully',
          error: '',
        });
      })
      .catch(err => {
        console.log(err);
        setValues({
          ...values,
          error: err.response
            ? err.response.data.message
            : 'Something went wrong, Try again',
        });
      });
    console.log(values);
  };

  const language =
    modLang === 'mun'
      ? ' Mundari'
      : modLang === 'kur'
      ? ' Kuruk'
      : modLang === 'san'
      ? ' Santhali'
      : modLang === 'hoc'
      ? ' Ho'
      : modLang === 'khr'
      ? ' Kharia'
      : modLang === 'bhb'
      ? ' Bhili'
      : modLang === 'gon'
      ? ' Gondi'
      : modLang === 'tcy'
      ? ' Tulu'
      : modLang;

  return (
    <div
      style={{marginTop: '11.4rem', textAlign: 'center', fontSize: '1.5rem'}}
    >
      <h1>
        Language:
        {language}
      </h1>
      <h2>Word: {word}</h2>
      <h2>Category: {props.location.state.category}</h2>

      <form className="add_question__form" onSubmit={handleSubmit}>
        <div>
          <select
            onChange={e =>
              setValues({...values, question_type: e.target.value})
            }
          >
            <option>Select Type</option>
            {questionType.map(tp => {
              return (
                <option key={tp.name} value={tp.value}>
                  {tp.name}
                </option>
              );
            })}
          </select>
        </div>

        <div>
          <label className="add_question__form__label">Question: </label>
          <input
            type="text"
            onChange={handleChange('question')}
            className="add_question__form__input"
            value={question}
          />
        </div>
        <div>
          <label className="add_question__form__label">
            Option 1 (Right Answer)
          </label>
          <input
            type="text"
            onChange={handleChange('opt_1')}
            value={opt_1}
            className="add_question__form__input"
          />
        </div>
        <div>
          <label className="add_question__form__label">Option 2</label>
          <input
            type="text"
            onChange={handleChange('opt_2')}
            value={opt_2}
            className="add_question__form__input"
          />
        </div>
        <div>
          <label className="add_question__form__label">Option 3</label>
          <input
            type="text"
            className="add_question__form__input"
            onChange={handleChange('opt_3')}
            value={opt_3}
          />
        </div>
        <div>
          <label className="add_question__form__label">Option 4</label>
          <input
            type="text"
            onChange={handleChange('opt_4')}
            value={opt_4}
            className="add_question__form__input"
          />
        </div>
        {success && <h2 style={{color: 'green'}}>{success}</h2>}
        {error && <h2 style={{color: 'red'}}>{error}</h2>}
        <div>
          <button className="btn btn-sm" type="submit">
            Submit
          </button>

          <Link
            to="/moderator/manage-quiz"
            className="btn btn-sm"
            style={{
              marginLeft: '0.4rem',
            }}
          >
            Back
          </Link>
        </div>
      </form>
    </div>
  );
};

export default AddQuestion;
