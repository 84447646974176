import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Link, Redirect} from 'react-router-dom';
import config from '../../config';

const ModAuth = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNo, setContactNo] = useState(0);
  const [password, setPassword] = useState('');
  const [signInClicked, setSignInClicked] = useState(false);
  const [signUpClicked, setSignUpClicked] = useState(false);
  const [isSignedin, setIsSignedin] = useState(false);
  const [signInError, setSignInError] = useState('');
  const [signupError, setSignUpError] = useState('');
  useEffect(() => {
    if (localStorage.getItem('isMod')) {
      setIsSignedin(true);
    }
  }, []);
  const handleSignInInput = e => {
    const {name, value} = e.currentTarget;
    switch (name) {
      case 'signInEmail':
        setEmail(value);
        break;
      case 'signInPassword':
        setPassword(value);
        break;
      default:
        break;
    }
  };
  const handleSignin = e => {
    e.preventDefault();
    setSignInClicked(true);
    axios({
      method: 'post',
      url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/auth/signin`,
      data: {
        email,
        password,
      },
    })
      .then(res => {
        localStorage.setItem('currentAdmin', res.data.user._id);
        localStorage.setItem('adminToken', res.data.token);
        localStorage.setItem('moderator', res.data.user.name);
        localStorage.setItem('modLang', res.data.user.modLang);
        setIsSignedin(true);
      })
      .catch(err => {
        console.log(err);
        setSignInError(
          err.response
            ? err.response.data.message
            : 'Something went wrong, Try again'
        );
      });
  };
  const handleSignUpInput = e => {
    const {name, value} = e.currentTarget;
    switch (name) {
      case 'signUpName':
        setName(value);
        break;
      case 'signUpEmail':
        setEmail(value);
        break;
      case 'signUpPassword':
        setPassword(value);
        break;
      case 'contactNo':
        setContactNo(value);
        break;
      default:
        break;
    }
  };
  const handleSignUp = e => {
    e.preventDefault();
    setSignUpClicked(true);
    axios({
      method: 'post',
      url: `${config.REACT_APP_NODE_API_URL}/api/admin/mod/auth/signup`,
      data: {
        name,
        email,
        password,
        contactNo,
      },
    })
      .then(res => {
        localStorage.setItem('currentAdmin', res.data.data._id);
        localStorage.setItem('moderator', res.data.data.name);
        setIsSignedin(true);
      })
      .catch(err => {
        setSignUpError(err.response.data.message);
      });
  };
  if (isSignedin) {
    return <Redirect to="/moderator/dashboard" />;
  }
  return (
    <section className="modauth">
      <div className="modauth__header">
        <h1 className="modauth__header__heading heading-primary--main u-center-text">
          Welcome to Mod
        </h1>
      </div>
      <div className="modauth__content">
        <div className="modauth__content__grid">
          <div className="modauth__content__grid__form">
            <h1 className="modauth__content__grid__heading heading-secondary--main u-center-text">
              Sign in
            </h1>
            <form
              className="modauth__content__grid__form"
              onSubmit={handleSignin}
            >
              <input
                type="email"
                className="modauth__content__grid__form__input"
                name="signInEmail"
                required={true}
                onChange={handleSignInInput}
                placeholder="Email"
              />
              <input
                type="password"
                className="modauth__content__grid__form__input"
                name="signInPassword"
                required={true}
                onChange={handleSignInInput}
                placeholder="Password"
              />
              <button className="btn btn-md modauth__content__grid__form__submit">
                {signInClicked ? 'Signing in' : 'Sign in'}
              </button>
            </form>
            {signInError ? (
              <h1 className="modauth__content__grid__form__error">
                {signInError}
              </h1>
            ) : (
              ''
            )}
            <Link to="/moderator/forgot-password">Forgot Password?</Link>
          </div>
          <div className="modauth__content__grid__form">
            <h1 className="modauth__header__heading heading-secondary--main u-center-text">
              Sign up
            </h1>
            <form
              className="modauth__content__grid__form"
              onSubmit={handleSignUp}
            >
              <input
                type="text"
                className="modauth__content__grid__form__input"
                name="signUpName"
                required={true}
                onChange={handleSignUpInput}
                placeholder="Your Name"
              />
              <input
                type="email"
                className="modauth__content__grid__form__input"
                name="signUpEmail"
                required={true}
                onChange={handleSignUpInput}
                placeholder="Your Email"
              />
              <input
                type="number"
                className="modauth__content__grid__form__input"
                name="contactNo"
                required={true}
                onChange={handleSignUpInput}
                placeholder="Your Contact Number"
              />
              <input
                type="text"
                className="modauth__content__grid__form__input"
                name="signUpPassword"
                required={true}
                onChange={handleSignUpInput}
                placeholder="Password"
              />
              <button className="btn btn-md modauth__content__grid__form__submit">
                {signUpClicked ? 'Signing up' : 'Sign up'}
              </button>
            </form>
            {signupError ? (
              <h1 className="modauth__content__grid__form__error">
                {signupError}
              </h1>
            ) : (
              ''
            )}
          </div>
        </div>
        <a href="/admin" className="modauth__link">
          Are you an Admin?
        </a>
      </div>
    </section>
  );
};

export default ModAuth;
