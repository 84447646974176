import React, {Fragment, useState, useEffect} from 'react';
import ProgressBar from '@ramonak/react-progress-bar';

import axios from 'axios';
import config from '../config';

const Achievements = () => {
  const [vocabPoints, setVocabPoints] = useState(0);
  const [totalWords, setTotalWords] = useState(0);
  const [vocabBar, setVocabBar] = useState(0);
  useEffect(() => {
    const currentUser = localStorage.getItem('currentUser')
      ? localStorage.getItem('currentUser')
      : null;
    const id = JSON.parse(currentUser)._id;
    axios
      .get(
        `${config.REACT_APP_NODE_API_URL}/api/user/get-points/${id}/${
          localStorage.getItem('languageData').split('-')[1]
        }`
      )
      .then(res => {
        setVocabPoints(res.data.data.userPoints.soil);
        setTotalWords(res.data.data.totalWords);
        const vocabPercent =
          (res.data.data.userPoints.soil / res.data.data.totalWords) * 100;
        console.log(res.data.data, vocabPercent);
        if (vocabPercent) {
          setVocabBar(vocabPercent);
        } else {
          setVocabBar(0);
        }
        // setReadingBar(0/1);
        // setWritingBar(0/1);
        // setListeningBar(0/1);
        // setSpeakingBar(0/1);
      })
      .catch(err => console.log(err));
  }, []);
  return (
    <Fragment>
      <div style={{display: 'flex', marginBottom: '3rem'}}>
        <h5 style={{flexGrow: '1', fontSize: '2.8rem'}}>Skills</h5>
      </div>
      {/* 1. Vocabulary */}
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <img
          src="/assets/Gems/Earth.png"
          alt="earth_gem"
          width={70}
          height={70}
          style={{objectFit: 'contain'}}
        />
        <div style={{marginLeft: '2rem', flexGrow: '1'}}>
          <h3 style={{fontSize: '19px', marginBottom: '1rem'}}>Vocabulary</h3>
          <ProgressBar
            bgColor={'#ffc107'}
            className="achievement-progress-bar"
            isLabelVisible={false}
            completed={vocabBar}
          />
        </div>
        {/* <p
          style={{
            marginTop: '4rem',
            marginLeft: '1rem',
            color: 'rgb(175, 175, 175',
            fontSize: '1.5rem',
          }}
        >
          {`${vocabPoints}/${totalWords}`}
        </p> */}
      </div>
      <p
        style={{
          marginLeft: '9.3rem',
          display: 'flex',
          alignItems: 'flex-start',
          color: 'rgb(175, 175, 175)',
          fontSize: '1.5rem',
        }}
      >
        {totalWords > 0 ? `${vocabPoints}/${totalWords} words` : `Coming Soon`}
      </p>
      <hr
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          border: '0.5px solid #d9d9d9',
        }}
      />
      {/* 2. Reading */}
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          marginTop: '2rem',
        }}
      >
        <img
          src="/assets/Gems/Water.png"
          alt="water_gem"
          width={70}
          height={70}
          style={{objectFit: 'contain'}}
        />
        <div style={{marginLeft: '2rem', flexGrow: '1'}}>
          <h3 style={{fontSize: '19px', marginBottom: '1rem'}}>Reading</h3>
          <ProgressBar
            bgColor={'#ffc107'}
            className="achievement-progress-bar"
            isLabelVisible={false}
            completed={0}
          />
        </div>
      </div>
      <p
        style={{
          marginLeft: '9.3rem',
          display: 'flex',
          alignItems: 'flex-start',
          color: 'rgb(175, 175, 175',
          fontSize: '1.5rem',
        }}
      >
        Coming Soon
      </p>
      <hr
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          border: '0.5px solid #d9d9d9',
        }}
      />
      {/* 3. Writing */}
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          marginTop: '2rem',
        }}
      >
        <img
          src="/assets/Gems/Sky.png"
          alt="sky_gem"
          width={70}
          height={70}
          style={{objectFit: 'contain'}}
        />
        <div style={{marginLeft: '2rem', flexGrow: '1'}}>
          <h3 style={{fontSize: '19px', marginBottom: '1rem'}}>Writing</h3>
          <ProgressBar
            bgColor={'#ffc107'}
            className="achievement-progress-bar"
            isLabelVisible={false}
            completed={0}
          />
        </div>
      </div>
      <p
        style={{
          marginLeft: '9.3rem',
          display: 'flex',
          alignItems: 'flex-start',
          color: 'rgb(175, 175, 175',
          fontSize: '1.5rem',
        }}
      >
        Coming Soon
      </p>
      <hr
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          border: '0.5px solid #d9d9d9',
        }}
      />
      {/* 4. Listening */}
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          marginTop: '2rem',
        }}
      >
        <img
          src="/assets/Gems/Leaf.png"
          alt="leaf_gem"
          width={70}
          height={70}
          style={{objectFit: 'contain'}}
        />
        <div style={{marginLeft: '2rem', flexGrow: '1'}}>
          <h3 style={{fontSize: '19px', marginBottom: '1rem'}}>Listening</h3>
          <ProgressBar
            bgColor={'#ffc107'}
            className="achievement-progress-bar"
            isLabelVisible={false}
            completed={0}
          />
        </div>
      </div>
      <p
        style={{
          marginLeft: '9.3rem',
          display: 'flex',
          alignItems: 'flex-start',
          color: 'rgb(175, 175, 175',
          fontSize: '1.5rem',
        }}
      >
        Coming Soon
      </p>
      <hr
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
          border: '0.5px solid #d9d9d9',
        }}
      />
      {/* 5. Speaking */}
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          marginTop: '2rem',
        }}
      >
        <img
          src="/assets/Gems/Fire.png"
          alt="fire_gem"
          width={70}
          height={70}
          style={{objectFit: 'contain'}}
        />
        <div style={{marginLeft: '2rem', flexGrow: '1'}}>
          <h3 style={{fontSize: '19px', marginBottom: '1rem'}}>Speaking</h3>
          <ProgressBar
            bgColor={'#ffc107'}
            className="achievement-progress-bar"
            isLabelVisible={false}
            completed={0}
          />
        </div>
      </div>
      <p
        style={{
          marginLeft: '9.3rem',
          display: 'flex',
          alignItems: 'flex-start',
          color: 'rgb(175, 175, 175',
          fontSize: '1.5rem',
        }}
      >
        Coming Soon
      </p>
    </Fragment>
  );
};

export default Achievements;
