import axios from 'axios';
import config from '../../config';
import React, {Fragment, useEffect, useState} from 'react';
import AccountSidebar from '../../components/AccountSidebar';
import LoggedInFooter from '../../layout/LoggedInFooter';
import '../../styles/pages/user/_notifications.scss';
const Notifications = () => {
  const [hasEmail, setEmail] = useState(true);
  const [hasReminders, setReminders] = useState(false);
  const [emailPref] = useState([
    {pref: 'Reminders', tagLine: 'I forget to practice'},
    {pref: 'Updates', tagLine: 'There is a product update'},
    {pref: 'Account', tagLine: 'There are changes in my account'},
    {pref: 'Marketing', tagLine: 'Trilingo has special promotions'},
  ]);
  const [userEmails, setEmailPerf] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [hours, setHours] = useState('8');
  const [minutes, setMinutes] = useState('15');
  const [timeOfDay, setTOD] = useState('AM');

  /**
   * @function handleCheckbox
   * @param {string} pref - E-mail preferences
   */
  const handleCheckbox = pref => {
    if (userEmails.includes(pref))
      setEmailPerf(userEmails.filter(x => x !== pref));
    else setEmailPerf([...userEmails, pref]);
    setHasChanged(true);
  };

  /**
   * @function handleSave
   */
  const handleSave = () => {
    const h = Number(hours === '12' ? '0' : hours);
    localStorage.setItem(
      'Daily Reminder',
      JSON.stringify({
        remind: hasReminders,
        hours: timeOfDay === 'AM' ? h : h + 12,
        minutes: Number(minutes),
      })
    );
    const currentUser = localStorage.getItem('currentUser')
      ? localStorage.getItem('currentUser')
      : null;
    const id = JSON.parse(currentUser)._id;
    axios
      .post(
        `${config.REACT_APP_NODE_API_URL}/api/user/email-notifications/update`,
        {
          id,
          enabled: hasEmail,
          emailPreferences: [...userEmails],
        }
      )
      .then(() => {})
      .catch(err => console.log(err));
    setHasChanged(false);
  };

  /**
   * @function useEffect
   */
  useEffect(() => {
    const tempTime = JSON.parse(localStorage.getItem('Daily Reminder'));
    if (tempTime) {
      setReminders(tempTime.remind);
      if (tempTime.hours >= 12) {
        setTOD('PM');
      }
      setHours(
        (tempTime.hours <= 12
          ? tempTime.hours === 0
            ? 12
            : tempTime.hours
          : tempTime.hours - 12) + ''
      );
      setMinutes((tempTime.minutes === 0 ? '00' : tempTime.minutes) + '');
    }
    const currentUser = localStorage.getItem('currentUser')
      ? localStorage.getItem('currentUser')
      : null;
    const id = JSON.parse(currentUser)._id;
    axios
      .get(`${config.REACT_APP_NODE_API_URL}/api/user/${id}`)
      .then(res => {
        setEmailPerf(res.data.data.emailNotifications.emailPreferences);
        setEmail(res.data.data.emailNotifications.enabled);
      })
      .catch(err => console.log(err));
  }, []);

  /**
   * @function useEffect
   */
  useEffect(() => {
    if (hasReminders) {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission().then(permission => {
          if (permission !== 'granted') {
            alert('Please allow notifications');
            setReminders(false);
          } else {
            setReminders(true);
          }
        });
      }
    }
  }, [hasReminders]);

  return (
    <Fragment>
      <section className="learning-language">
        <div className="learning-language-settings">
          <div className="learning-language-form">
            <div className="notifications-heading">
              <h1>Notifications</h1>
              <button
                className={`${
                  hasChanged ? 'account-btn account-btn-changed' : 'account-btn'
                } `}
                onClick={handleSave}
              >
                SAVE CHANGES
              </button>
            </div>
            <div className="subheadings">
              <h3>Daily Reminders</h3>
              <input
                type="checkbox"
                checked={hasReminders}
                onChange={() => {
                  setReminders(x => !x);
                  setHasChanged(true);
                }}
              ></input>
            </div>
            <div className="preferences-div">
              <div className="preferences-heading">Remind me when</div>
              <div className="preferences-list" style={{margin: '2rem 0'}}>
                <div className="tagline">{"I don't learn before "}</div>
                <div className="set-time">
                  <select
                    id="hours"
                    value={hours}
                    onChange={e => {
                      setHours(e.target.value);
                      setHasChanged(true);
                    }}
                  >
                    {[...Array(12).keys()].map((x, index) => {
                      return (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      );
                    })}
                  </select>
                  {' : '}
                  <select
                    id="minutes"
                    value={minutes}
                    onChange={e => {
                      setMinutes(e.target.value);
                      setHasChanged(true);
                    }}
                  >
                    {['00', '15', '30', '45'].map((x, index) => {
                      return (
                        <option key={index} value={x}>
                          {x}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    id="timeofday"
                    value={timeOfDay}
                    onChange={e => {
                      setTOD(e.target.value);
                      setHasChanged(true);
                    }}
                  >
                    {['AM', 'PM'].map((x, index) => {
                      return (
                        <option key={index} value={x}>
                          {x}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div className="subheadings">
              <h3>Emails</h3>
              <input
                type="checkbox"
                checked={hasEmail}
                onChange={() => {
                  setEmail(x => !x);
                  setHasChanged(true);
                }}
              ></input>
            </div>
            <div className="preferences-div">
              <div className="preferences-heading">Email me when</div>
              <div className="preferences-list">
                {emailPref.map(prefe => {
                  return (
                    <div className="preferences" key={prefe.pref}>
                      <div>
                        <input
                          type="checkbox"
                          checked={userEmails.includes(prefe.pref)}
                          id={prefe.pref}
                          onChange={e => {
                            handleCheckbox(e.target.id);
                          }}
                        />
                      </div>
                      <div className="tagline">{prefe.tagLine}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <AccountSidebar />
        </div>
      </section>
      <LoggedInFooter />
    </Fragment>
  );
};
export default Notifications;
