/* eslint-disable react/prop-types */
import React from 'react';

/**
 * @class Timer
 */
export default class Timer extends React.Component {
  /**
   * @class Timer
   * @param {*} props - props passed to class
   */
  constructor(props) {
    super(props);
    this.state = {
      seconds: 0,
    };
  }
  /**
   * @function tick
   */
  tick() {
    const {duration, timeoutFn, calTimeFn} = this.props;
    if (this.state.seconds === duration) {
      timeoutFn();
    } else {
      this.setState(prevState => ({
        seconds: prevState.seconds + 1,
      }));
      calTimeFn();
    }
  }

  /**
   * @function componentDidMount
   */
  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000);
  }

  /**
   * @function componentWillUnmount
   */
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  /**
   * @function render
   * @returns {import('react').JSXElementConstructor} -
   */
  render() {
    const {duration} = this.props;
    const timeLeft = duration - this.state.seconds;
    return <span>{timeLeft}</span>;
  }
}
