/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import {updateProfileData} from '../../store/actions/userActions';
import i18n from 'i18next';

const EditProfile = props => {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [contactNo, setContactNo] = useState(null);
  const [gender, setGender] = useState(null);
  const [age, setAge] = useState(null);
  const [streetName, setStreetName] = useState(null);
  const [areaName, setAreaName] = useState(null);
  const [locality, setLocality] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [state, setState] = useState(null);
  const [occupation, setOccupation] = useState(null);
  const [isTribal, setIsTribal] = useState(null);
  const [tribe, setTribe] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [photoUrl, setPhotoUrl] = useState(
    'https://s3.amazonaws.com/37assets/svn/765-default-avatar.png'
  );
  const [isMouseHoverOnImage, setIsMouseHoverOnImage] = useState(false);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [savedMessage] = useState(null);
  const {id, dataSavedMessage} = props;
  const mouseEnter = () => {
    setIsMouseHoverOnImage(true);
  };

  const mouseLeave = () => {
    setIsMouseHoverOnImage(false);
  };

  useEffect(() => {
    const currentLanguage = localStorage.getItem('changeLanguage');

    // 2. Selecting default value for language
    document.getElementById('changeLanguage').value = currentLanguage
      ? currentLanguage
      : 'hin';

    axios({
      method: 'get',
      url: `${config.REACT_APP_NODE_API_URL}/api/user/${id}`,
    })
      .then(res => {
        setName(res.data.data.name);
        setEmail(res.data.data.email);
        setContactNo(res.data.data.contactNo);
        setPhotoUrl(res.data.data.photoUrl);
        setState(res.data.data.state);
        setGender(res.data.data.gender);
        setAge(res.data.data.age);
        setStreetName(res.data.data.streetName);
        setAreaName(res.data.data.areaName);
        setLocality(res.data.data.locality);
        setOccupation(res.data.data.occupation);
        setTribe(res.data.data.tribe);
        document.getElementById('state').value = res.data.data.state || null;
        if (document.getElementById('gender')) {
          document.getElementById('gender').value =
            res.data.data.gender || null;
        }
        if (document.getElementById('dateOfBirth')) {
          document.getElementById('dateOfBirth').value =
            res.data.data.dateOfBirth || null;
        }
        document.getElementById('occupation').value =
          res.data.data.occupation || null;

        console.log(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [id, savedMessage]);

  const getImagePreview = e => {
    const file = e.target.files[0];
    const isImageFile = file.type.includes('image/');
    if (isImageFile) {
      const reader = new FileReader();
      reader.onload = e => {
        console.log(e.target.result);
        setPhotoUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      // TODO => Fix if not an image
      console.log('Not Image');
    }
  };

  // Handles Select Dropdowns
  const handleSelectValues = () => {
    if (document.getElementById('state').selectedOptions[0])
      setState(document.getElementById('state').selectedOptions[0].value);
    if (document.getElementById('gender').selectedOptions[0])
      setGender(document.getElementById('gender').selectedOptions[0].value);
    if (document.getElementById('occupation').selectedOptions[0])
      setOccupation(
        document.getElementById('occupation').selectedOptions[0].value
      );
  };

  const handleInput = e => {
    const {name, value} = e.currentTarget;
    if (name === 'name') {
      setName(value);
    } else if (name === 'email') {
      setEmail(value);
    } else if (name === 'contactNo') {
      setContactNo(value);
    } else if (name === 'dateOfBirth') {
      setDateOfBirth(value);
    } else if (name === 'age') {
      setAge(value);
    } else if (name === 'streetName') {
      setStreetName(value);
    } else if (name === 'areaName') {
      setAreaName(value);
    } else if (name === 'locality') {
      setLocality(value);
    } else if (name === 'pincode') {
      setPincode(value);
    } else if (name === 'occupation') {
      setOccupation(value);
    } else if (name === 'tribe') {
      setTribe(value);
    }
  };

  const changeLang = e => {
    console.log(e.target.value);
    if (e.target.value === 'yes') {
      setIsTribal(true);
    } else {
      setTribe(null);
      setIsTribal(false);
    }
  };

  const changeTextLanguage = e => {
    localStorage.setItem(
      'changeLanguage',
      document.getElementById('changeLanguage').selectedOptions[0].value
    );
    i18n.changeLanguage(
      document.getElementById('changeLanguage').selectedOptions[0].value
    );
    window.location.reload();
  };

  // Updates User Data
  const updateData = e => {
    e.preventDefault();
    setIsSaveClicked(true);
    props.updateProfileData({
      photoUrl,
      contactNo,
      gender,
      state,
      age,
      streetName,
      areaName,
      locality,
      pincode,
      occupation,
      tribe,
    });

    setTimeout(() => {
      setIsSaveClicked(false);
    }, 1000);
  };

  if (!id) return <Redirect to="/" />;

  return (
    <main className="editprofile">
      <div className="editprofile__container__header">
        <h1>Edit Your Profile</h1>
      </div>
      <div className="editprofile__container">
        <div className="editprofile__container__content">
          <div
            className="editprofile__container__content__dp"
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
          >
            <img
              src={photoUrl}
              className="editprofile__container__content__dp__canvas"
              alt="edit profile container"
            />
            {isMouseHoverOnImage ? (
              <>
                <label
                  className="editprofile__container__content__dp__canvas__label"
                  htmlFor="user-image-upload"
                >
                  <img
                    src="https://img.icons8.com/material/30/000000/edit--v1.png"
                    alt="user upload"
                  />
                </label>
                <input
                  type="file"
                  className="editprofile__container__content__dp__edit__input"
                  onChange={getImagePreview}
                  id="user-image-upload"
                />
              </>
            ) : null}
          </div>
          <div className="editprofile__container__content__static">
            <h3 className="heading-primary--sub editprofile__container__content__static__item__primary">
              {name}
            </h3>
            <h3 className="heading-primary--sub editprofile__container__content__static__item__secondary">
              {email}
            </h3>
            <h3 className="heading-primary--sub i editprofle__content__static__item__secondary">
              {dateOfBirth}
            </h3>
            {gender ? (
              <h3 className="heading-primary--sub editprofile__container__content__static__item__secondary">
                {gender}
              </h3>
            ) : (
              ''
            )}
            {contactNo ? (
              <h3 className="heading-primary--sub editprofile__container__content__static__item editprofile__container__content__static__item__secondary">
                {contactNo}
              </h3>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="editprofile__container__content__section">
          <div className="editprofile__container__content__section__header">
            <h1 className="editprofile__container__content__section__header__heading">
              Primary Details
            </h1>
          </div>
          <form
            className="editprofile__container__content__section__form"
            id="temp"
          >
            <input
              type="number"
              placeholder="Enter Your Age"
              name="age"
              className="editprofile__container__content__section__form__input"
              defaultValue={age || ''}
              onChange={handleInput}
            />

            <select
              className="editprofile__container__content__section__form__select"
              onChange={handleSelectValues}
              id="gender"
              value={gender || ''}
            >
              <option
                value="null"
                className="editprofile__container__content__section__form__select__item"
              >
                Choose Your Gender
              </option>
              <option
                value="female"
                className="editprofile__container__content__section__form__select__item"
              >
                Female
              </option>
              <option
                value="male"
                className="editprofile__container__content__section__form__select__item"
              >
                Male
              </option>
              <option
                value="LGBTQ+"
                className="editprofile__container__content__section__form__select__item"
              >
                LGBTQ+
              </option>
              <option
                value="Prefer not to say"
                className="editprofile__container__content__section__form__select__item"
              >
                Prefer not to say
              </option>
            </select>

            <input
              type="tel"
              placeholder="Enter Your Contact No"
              name="contactNo"
              className="editprofile__container__content__section__form__input"
              defaultValue={contactNo || ''}
              onChange={handleInput}
            />

            <button
              className="btn editprofile__container__content__section__form__submit"
              onClick={updateData}
            >
              {!isSaveClicked ? 'Save Profile' : 'Saving'}
            </button>
            {dataSavedMessage ? (
              <h1 className="editprofile__container__content__section__message">
                {dataSavedMessage}
              </h1>
            ) : (
              ''
            )}
          </form>
        </div>

        <div className="editprofile__container__content__section">
          <div className="editprofile__container__content__section__header">
            <h1 className="editprofile__container__content__section__header__heading">
              Location
            </h1>
          </div>
          <form className="editprofile__container__content__section__form">
            <input
              type="text"
              placeholder="Enter Your Street Name"
              name="streetName"
              className="editprofile__container__content__section__form__input"
              defaultValue={streetName || ''}
              onChange={handleInput}
            />

            <input
              type="text"
              placeholder="Enter Your Area Name"
              name="areaName"
              className="editprofile__container__content__section__form__input"
              defaultValue={areaName || ''}
              onChange={handleInput}
            />

            <input
              type="text"
              placeholder="Enter Your Locality"
              name="locality"
              className="editprofile__container__content__section__form__input"
              defaultValue={locality || ''}
              onChange={handleInput}
            />

            <input
              type="number"
              placeholder="Enter Your Pincode"
              name="pincode"
              className="editprofile__container__content__section__form__input"
              defaultValue={pincode || ''}
              onChange={handleInput}
            />

            <select
              className="editprofile__container__content__section__form__select"
              onChange={handleSelectValues}
              id="state"
              value={state || ''}
            >
              {stateList.map((state, key) => {
                return (
                  <option
                    value={state.value}
                    className="editprofile__container__content__section__form__select__item"
                    key={key}
                  >
                    {state.name}
                  </option>
                );
              })}
            </select>

            <button
              className="btn editprofile__container__content__section__form__submit"
              onClick={updateData}
            >
              {!isSaveClicked ? 'Save Profile' : 'Saving'}
            </button>
            {dataSavedMessage ? (
              <h1 className="editprofile__container__content__section__message">
                {dataSavedMessage}
              </h1>
            ) : (
              ''
            )}
          </form>
        </div>

        <div className="editprofile__container__content__section">
          <div className="editprofile__container__content__section__header">
            <h1 className="editprofile__container__content__section__header__heading">
              Professional Background
            </h1>
          </div>
          <form className="editprofile__container__content__section__form">
            <select
              className="editprofile__container__content__section__form__select"
              onChange={handleSelectValues}
              id="occupation"
              value={occupation || ''}
            >
              <option
                value="null"
                className="editprofile__container__content__section__form__select__item"
              >
                Choose Your Occupation
              </option>
              <option
                value="Student"
                className="editprofile__container__content__section__form__select__item"
              >
                Student
              </option>
              <option
                value="Entrepreneurship"
                className="editprofile__container__content__section__form__select__item"
              >
                Entrepreneurship
              </option>
              <option
                value="Business"
                className="editprofile__container__content__section__form__select__item"
              >
                Business
              </option>
              <option
                value="Prefer not to say"
                className="editprofile__container__content__section__form__select__item"
              >
                Prefer not to say
              </option>
            </select>

            <label className="editprofile__container__content__section__form__label">
              I belong to tribal community..
            </label>

            {!tribe ? (
              <div className="editprofile__container__content__section__form__radio">
                <input
                  type="radio"
                  id="yes"
                  name="tribe"
                  value="yes"
                  className="editprofile__container__content__section__form__radio__item"
                  onChange={changeLang}
                />
                <label
                  htmlFor="yes"
                  className="editprofile__container__content__section__form__radio__item__label"
                >
                  Yes
                </label>

                <input
                  type="radio"
                  id="no"
                  name="tribe"
                  value="no"
                  className="editprofile__container__content__section__form__radio__item"
                  onChange={changeLang}
                />
                <label
                  htmlFor="no"
                  className="editprofile__container__content__section__form__radio__item__label"
                >
                  No
                </label>
              </div>
            ) : (
              <input
                type="text"
                placeholder="Enter Your Tribe"
                name="tribe"
                className="editprofile__container__content__section__form__input"
                defaultValue={tribe || ''}
                onChange={handleInput}
              />
            )}

            {isTribal ? (
              <input
                type="text"
                placeholder="Enter Your Tribe"
                name="tribe"
                className="editprofile__container__content__section__form__input"
                defaultValue={tribe || ''}
                onChange={handleInput}
              />
            ) : (
              ''
            )}

            <label className="editprofile__container__content__section__form__label">
              Change Language
            </label>
            <select
              className="navigation__nav__select"
              id="changeLanguage"
              onChange={changeTextLanguage}
            >
              <option value="hin" className="navigation__nav__select__option">
                Hindi
              </option>
              <option value="en" className="navigation__nav__select__option">
                English
              </option>
            </select>

            <button
              className="btn editprofile__container__content__section__form__submit"
              onClick={updateData}
            >
              {!isSaveClicked ? 'Save Profile' : 'Saving'}
            </button>
            {dataSavedMessage ? (
              <h1 className="editprofile__container__content__section__message">
                {dataSavedMessage}
              </h1>
            ) : (
              ''
            )}
          </form>
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;

  return {
    id,
    dataSavedMessage: state.user.message,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProfileData: payload => dispatch(updateProfileData(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);

const stateList = [
  {
    name: 'Choose Your State',
    value: 'null',
  },
  {
    name: 'Andaman and Nicobar Islands',
    value: 'Andaman and Nicobar Islands',
  },
  {
    name: 'Andhra Pradesh',
    value: 'Andhra Pradesh',
  },
  {
    name: 'Arunachal Pradesh',
    value: 'Arunachal Pradesh',
  },
  {
    name: 'Assam',
    value: 'Assam',
  },
  {
    name: 'Bihar',
    value: 'Bihar',
  },
  {
    name: 'Chandigarh',
    value: 'Chandigarh',
  },
  {
    name: 'Chhattisgarh',
    value: 'Chhattisgarh',
  },
  {
    name: 'Dadra and Nagar Haveli and Daman and Diu',
    value: 'Dadra and Nagar Haveli and Daman and Diu',
  },

  {
    name: 'Delhi',
    value: 'Delhi',
  },
  {
    name: 'Goa',
    value: 'Goa',
  },
  {
    name: 'Gujarat',
    value: 'Gujarat',
  },
  {
    name: 'Haryana',
    value: 'Haryana',
  },
  {
    name: 'Himachal Pradesh',
    value: 'Himachal Pradesh',
  },
  {
    name: 'Jammu and Kashmir',
    value: 'Jammu and Kashmir',
  },
  {
    name: 'Jharkhand',
    value: 'Jharkhand',
  },
  {
    name: 'Karnataka',
    value: 'Karnataka',
  },
  {
    name: 'Kerala',
    value: 'Kerala',
  },
  {
    name: 'Ladakh',
    value: 'Ladakh',
  },
  {
    name: 'Lakshadweep',
    value: 'Lakshadweep',
  },
  {
    name: 'Madhya Pradesh',
    value: 'Madhya Pradesh',
  },
  {
    name: 'Maharashtra',
    value: 'Maharashtra',
  },
  {
    name: 'Manipur',
    value: 'Manipur',
  },
  {
    name: 'Meghalaya',
    value: 'Meghalaya',
  },
  {
    name: 'Mizoram',
    value: 'Mizoram',
  },
  {
    name: 'Nagaland',
    value: 'Nagaland',
  },
  {
    name: 'Odisha',
    value: 'Odisha',
  },
  {
    name: 'Puducherry',
    value: 'Puducherry',
  },
  {
    name: 'Punjab',
    value: 'Punjab',
  },
  {
    name: 'Rajasthan',
    value: 'Rajasthan',
  },
  {
    name: 'Sikkim',
    value: 'Sikkim',
  },
  {
    name: 'Tamil Nadu',
    value: 'Tamil Nadu',
  },
  {
    name: 'Telangana',
    value: 'Telangana',
  },
  {
    name: 'Tripura',
    value: 'Tripura',
  },
  {
    name: 'Uttar Pradesh',
    value: 'Uttar Pradesh',
  },
  {
    name: 'Uttarakhand',
    value: 'Uttarakhand',
  },
  {
    name: 'West Bengal',
    value: 'West Bengal',
  },
];
