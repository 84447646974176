import axios from 'axios';
import {generateUid} from '../../utils/uid';
import config from '../../config';
// Signing up User
export const userSignUpWithEmailAndPassword = credentials => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${config.REACT_APP_NODE_API_URL}/api/auth/signup`,
      data: {
        name: credentials.fullName,
        dateOfBirth: credentials.dateOfBirth,
        email: credentials.email,
        password: credentials.password,
      },
    })
      .then(res => {
        localStorage.setItem(
          'isAccountActivated',
          JSON.stringify({
            status: false,
            email: res.data.data.email,
          })
        );
        localStorage.setItem(
          'currentUser',
          JSON.stringify({
            _id: res.data.data._id,
            newUser: 'Yes',
          })
        );
        localStorage.setItem('languageData', res.data.data.languages[0]);
        dispatch({
          type: 'SIGNIN_SUCCESS',
        });
        dispatch({
          type: 'SIGNUP_SUCCESS',
        });
      })
      .catch(err => {
        console.log(err);
        dispatch({
          type: 'SIGNUP_ERROR',
          err: err.response.data.message,
        });
      });
  };
};

// Signing in User
export const userSignInWithEmailAndPassword = credentials => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${config.REACT_APP_NODE_API_URL}/api/auth/signin`,
      data: {
        email: credentials.email,
        password: credentials.password,
      },
    })
      .then(res => {
        return axios.get(
          `${config.REACT_APP_NODE_API_URL}/api/user/${res.data.user._id}`
        );
      })
      .then(res => {
        const newUser = JSON.parse(localStorage.getItem('currentUser'))?.newUser
          ? JSON.parse(localStorage.getItem('currentUser'))?.newUser
          : 'No';
        localStorage.setItem(
          'currentUser',
          JSON.stringify({
            _id: res.data.data._id,
            isAccountActive: res.data.data.isAccountActive,
            newUser,
          })
        );
        localStorage.setItem('languageData', res.data.data.languages[0]);
        dispatch({
          type: 'SIGNIN_SUCCESS',
        });
      })
      .catch(err => {
        console.log(err);
        dispatch({
          type: 'SIGNIN_ERROR',
          err,
        });
      });
  };
};

// Send Email For Verify Account
export const sendEmailToVerifyAccount = payload => {
  return dispatch => {
    localStorage.setItem('isAccountActivated', JSON.stringify(payload));
    console.log(payload);
    axios({
      method: 'post',
      url: `${config.REACT_APP_NODE_API_URL}/api/auth/verify-account`,
      data: {
        uid: payload.uid,
        email: payload.email,
      },
    })
      .then(res => {
        console.log(res);
      })
      .catch(console.log);
  };
};

// Activate User Account
export const activateUserAccount = payload => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${config.REACT_APP_NODE_API_URL}/api/auth/activate-account`,
      data: payload,
    })
      .then(res => {
        if (res.data.isAccountActivated) {
          // 1. Make local storage null for isActiveAccount
          localStorage.removeItem('isAccountActivated');
          // 2. Dispatch action
          dispatch({
            type: 'USER_ACCOUNT_ACTIVE',
            isAccountActivated: res.data.isAccountActivated,
          });
        }
      })
      .catch(err => console.log(err));
  };
};

// Send Email For Forgot Password
export const sendEmailForOtp = email => {
  return () => {
    const uid = generateUid();
    axios({
      method: 'post',
      url: `${config.REACT_APP_NODE_API_URL}/api/auth/forgot-password`,
      data: {
        email,
        uid,
      },
    }).catch(console.log);
  };
};

// Send Updated Password Payload To DB
export const updatePassword = payload => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${config.REACT_APP_NODE_API_URL}/api/auth/update-password`,
      data: {
        email: payload.email,
        password: payload.password,
      },
    })
      .then(res => {
        localStorage.setItem('updateEmailPayload', null);
        dispatch({
          type: 'PASSWORD_UPDATE__SUCCESS',
          message: res.data.message,
        });
      })
      .catch(err => {
        console.log(err);
        dispatch({
          type: 'PASSWORD_UPDATE__ERROR',
          message: 'Whoops Error, Try Again.',
        });
      });
  };
};

// Signing out User
export const signOutUser = () => {
  return dispatch => {
    localStorage.setItem('currentUser', null);
    dispatch({
      type: 'SIGNOUT__SUCCESS',
    });
  };
};
