/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/prop-types */
import React, {Fragment, useState, useEffect} from 'react';
import AccountSidebar from '../../components/AccountSidebar';
import LoggedInFooter from '../../layout/LoggedInFooter';
import {connect} from 'react-redux';
import axios from 'axios';
import config from '../../config';
import {Link, Redirect} from 'react-router-dom';

const LearningLanguage = props => {
  const [languages, setLanguages] = useState([]);
  const [userLangs, setUserlangs] = useState([]);
  const {id} = props;
  if (!id) return <Redirect to="/" />;

  useEffect(() => {
    axios({
      method: 'get',
      url: `${config.REACT_APP_NODE_API_URL}/api/user/${id}`,
    })
      .then(res => {
        setUserlangs(res.data.data.languages.map(x => x.split('-')[1]));
        return axios.get(
          `${config.REACT_APP_NODE_API_URL}/api/vocab/public/language-data`
        );
      })
      .then(x => {
        setLanguages(x.data.data);
        console.log(x.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [id]);

  return (
    <Fragment>
      <section className="learning-language">
        <div className="learning-language-settings">
          <div>
            <h1>Language</h1>
            <div className="language-learning-cards-row">
              {languages?.map((x, i) =>
                userLangs.includes(
                  x.languageCode === 'hoc' ? 'kur' : x.languageCode
                ) ? (
                  <div className="language-learning-card" key={i}>
                    <h2>{`${x.name} (${x.status})`}</h2>
                    <p>
                      {x.details}
                      <br />
                      <span style={{fontWeight: 'bold'}}>{x.tagLine}</span>
                    </p>
                  </div>
                ) : null
              )}
            </div>
          </div>
          <AccountSidebar />
        </div>
        <div className="learning-language-settings">
          <Link
            to="/courses"
            className="learning-language-btn"
            style={{display: 'none'}}
          >
            Change Course
          </Link>
        </div>
      </section>
      <LoggedInFooter />
    </Fragment>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;
  return {
    id,
    error: state.auth.authError,
  };
};

export default connect(mapStateToProps)(LearningLanguage);
