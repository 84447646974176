/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {sendEmailToVerifyAccount} from '../../store/actions/authActions';
import axios from 'axios';
import config from '../../config';
import Modal from '../../components/Modal';
import AlertBox from '../../components/AlertBox';
import {FaChevronRight} from 'react-icons/fa';

/**
 * @author NevetsKuro <stevenfernz96@gmail.com>
 * @function VerifyEmail
 * @description To verify email id
 * @param {{id:string}} props - current user id
 * @returns {import('react').ReactFragment} JSX element
 */
const VerifyEmail = props => {
  const [email, setEmail] = useState('');
  const [isActive, setActive] = useState(false);
  const {id} = props;
  // states for alert-box
  const [alertMessage, setAlertMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    axios({
      method: 'get',
      url: `${config.REACT_APP_NODE_API_URL}/api/user/${id}`,
    })
      .then(res => {
        setActive(res.data.data.isAccountActive);
        setEmail(res.data.data.email);
      })
      .catch(err => {
        console.log(err);
      });
  }, [id]);
  useEffect(() => {
    if (email) {
      sendOtpToEmail(email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  /**
   * @author NevetsKuro <stevenfernz96@gmail.com>
   * @function sendOtpToEmail
   * @param {string} email - user s email id
   * @description fetch all notifications by today's date
   */
  const sendOtpToEmail = email => {
    if (email) {
      props.sendEmailToVerifyAccount({
        uid: props.id,
        email: email,
      });
    }
  };
  // for accessing multiple input using focus and keys
  useEffect(() => {
    otpInput();
  }, []);
  useEffect(() => {
    if (isActive) window.location.replace('/choose-goals');
  }, [isActive]);
  /**
   * @function toggleShowModal
   * @description toggle show/hide value of modal
   */
  const toggleShowModal = () => {
    setShowModal(prevModal => !prevModal);
  };
  /**
   * @function activateEmail
   * @description activate email by sending verifying
   */
  const activateEmail = () => {
    let otp = '';
    Array.from(
      document.getElementsByClassName('emailverify-otp-input')
    ).forEach(x => (otp += x.value));

    if (otp.length === 6) {
      axios({
        method: 'post',
        url: `${config.REACT_APP_NODE_API_URL}/api/auth/verify/gen-otp`,
        data: {
          id,
          otp,
        },
      })
        .then(res => {
          if (res.data.success) {
            setActive(true);
            setAlertMessage('Successfully verified!');
            toggleShowModal();
          } else {
            setAlertMessage(
              res.data.msg ? res.data.msg : 'OTP Invalid or Expired'
            );
            toggleShowModal();
            console.log(res.data.msg);
          }
        })
        .catch(err => {
          setAlertMessage('OTP Invalid or Expired');
          toggleShowModal();
          console.log('Error', err);
        });
    } else {
      setAlertMessage('Kindly enter OTP first!');
    }
  };
  if (!id) return <Redirect to="/" />;
  const otpInput = () => {
    const inputs = document.querySelectorAll('#otp > div > *[id]');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('keydown', function (event) {
        if (event.key === 'Backspace') {
          inputs[i].value = '';
          if (i !== 0) inputs[i - 1].focus();
        } else {
          if (i === inputs.length - 1 && inputs[i].value) {
            return true;
          } else if (event.keyCode > 47 && event.keyCode < 58) {
            inputs[i].value = event.key;
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          } else if (event.keyCode > 64 && event.keyCode < 91) {
            inputs[i].value = String.fromCharCode(event.keyCode);
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          }
        }
      });
    }
  };
  return (
    <section className="emailverify">
      {showModal && (
        <Modal>
          <AlertBox
            toggleShowModal={() => toggleShowModal()}
            message={alertMessage}
          />
        </Modal>
      )}
      {/* <img
        src="/assets/logo192.png"
        alt="TriLingo"
        width={80}
        height={80}
        style={{objectFit: 'contain'}}
      /> */}
      <h1 className="emailverify__header__heading">
        Check your email for a code
      </h1>
      <div className="emailverify-body" style={{}}>
        <p>
          {`We have sent a six-character code to `}
          <b>{email}</b>
          {`. The code expires shortly, so please enter it soon. `}
        </p>
        <div id="otp" className="emailverify-otp-input-container">
          <div className="emailverify-otp-input-inner-container">
            <input
              id="first"
              type="text"
              className="emailverify-otp-input"
              maxLength="1"
            />
            <input
              id="second"
              type="text"
              className="emailverify-otp-input"
              maxLength="1"
            />
            <input
              id="third"
              type="text"
              className="emailverify-otp-input"
              maxLength="1"
              style={{border: 0}}
            />
          </div>
          <div className="dash-container">
            <div className="dash"></div>
          </div>
          <div className="emailverify-otp-input-inner-container">
            <input
              id="fourth"
              type="text"
              className="emailverify-otp-input"
              maxLength="1"
            />
            <input
              id="fifth"
              type="text"
              className="emailverify-otp-input"
              maxLength="1"
            />
            <input
              id="sixth"
              type="text"
              className="emailverify-otp-input"
              style={{border: 0}}
              maxLength="1"
            />
          </div>
        </div>

        <div
          className="emailverify-otp-button"
          onClick={() => {
            activateEmail();
          }}
        >
          Activate <FaChevronRight size="24" />
        </div>
        <span
          style={{color: 'gray', cursor: 'pointer'}}
          onClick={e => {
            e.preventDefault();
            sendOtpToEmail(email);
            setAlertMessage('OTP sent to email ' + email);
            toggleShowModal();
          }}
        >
          Resend OTP?
        </span>
      </div>
    </section>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;
  return {
    id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendEmailToVerifyAccount: email =>
      dispatch(sendEmailToVerifyAccount(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
