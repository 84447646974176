import React, {Fragment, useState, useEffect} from 'react';
import AccountSidebar from '../../components/AccountSidebar';
import LoggedInFooter from '../../layout/LoggedInFooter';
import ProgressBar from '@ramonak/react-progress-bar';
import axios from 'axios';
import config from '../../config';

const Account = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [created, setCreated] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDOB] = useState('');
  const [oName, setOName] = useState('');
  const [oGender, setOGender] = useState('');
  const [message, setMessage] = useState('');
  const [completed, setCompleted] = useState(4);
  const [formValueChange, setFormValueChange] = useState(false);
  useEffect(() => {
    const id = JSON.parse(localStorage.getItem('currentUser'))._id;
    axios({
      method: 'get',
      url: `${config.REACT_APP_NODE_API_URL}/api/user/${id}`,
    })
      .then(res => {
        setName(res.data?.data?.name || '');
        setOName(res.data?.data?.name || '');
        setEmail(res.data?.data?.email || '');
        setGender(res.data?.data?.gender || '');
        setOGender(res.data?.data?.gender || '');
        setDOB(
          new Date(res.data?.data?.dateOfBirth).toLocaleDateString() || ''
        );
        setCreated(
          `${new Date(res.data.data.createdAt).toLocaleString('default', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}`
        );
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (!name.trim()) {
      setFormValueChange(false);
      return;
    }
    setFormValueChange(name.trim() !== oName || gender !== oGender);
    if (!gender && !name) {
      setCompleted(2);
    } else if (!gender || !name) {
      setCompleted(3);
    } else {
      setCompleted(4);
    }
  }, [gender, name, oGender, oName]);
  const updateData = e => {
    if (!name.trim()) {
      setMessage('Name cannot be empty');
      setTimeout(() => {
        setMessage('');
      }, 3000);
      return;
    }
    const id = JSON.parse(localStorage.getItem('currentUser'))._id;
    e.preventDefault();
    setMessage('');
    setFormValueChange(false);
    axios
      .post(`${config.REACT_APP_NODE_API_URL}/api/user/update/${id}`, {
        name: name.trim(),
        gender,
      })
      .then(() => {
        setMessage('Data updated');
        setTimeout(() => {
          setMessage('');
        }, 1000);
      })
      .catch(err =>
        setMessage(
          err.response.data?.message || 'Something went wrong, Try again'
        )
      );
  };
  return (
    <Fragment>
      <section className="account">
        <div className="account-settings">
          <div className="account-form">
            <div className="account-form-heading">
              <h1>Account Settings</h1>
              <button
                className={`${
                  formValueChange
                    ? 'account-btn account-btn-changed'
                    : 'account-btn'
                }`}
                disabled={!formValueChange}
                onClick={updateData}
              >
                SAVE CHANGES
              </button>
            </div>
            {message && <h2>{message}</h2>}
            {completed === 4 ? (
              <></>
            ) : (
              <div>
                <div>{completed} out of 4 details filled</div>
                <ProgressBar
                  bgColor={'#f99529'}
                  isLabelVisible={false}
                  completed={(completed / 4) * 100}
                />
              </div>
            )}
            <div className="account-form-content">
              <form>
                <div className="account-form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    required={true}
                    onChange={e => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div className="account-form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={() => {}}
                  />
                </div>
                <div className="account-form-group">
                  <label htmlFor="age">Date of Birth</label>
                  <input name="age" value={dob} disabled={true} />
                </div>
                <div className="account-form-group">
                  <label htmlFor="gender">Gender</label>
                  <select
                    name="gender"
                    value={gender}
                    onChange={e => {
                      setGender(e.target.value);
                    }}
                  >
                    <option disabled={true} value="">
                      Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others (LGBTQAI+)</option>
                    <option value="Not-specified">
                      I don{"'"}t want to specify
                    </option>
                  </select>
                </div>
              </form>
              <div className="non-input-content">
                <h5>Joined on</h5>
                <p>{created}</p>
              </div>
            </div>
          </div>
          <AccountSidebar />
        </div>
      </section>
      <LoggedInFooter />
    </Fragment>
  );
};

export default Account;
