import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Footer from '../layout/Footer';
import LoggedInFooter from '../layout/LoggedInFooter';
import Navbar from '../layout/Navbar';

const Mission = () => {
  return (
    <Fragment>
      <h2>Personalized education.</h2>
      <p>
        Everyone learns in different ways. For the first time in history, we can
        analyze how millions of people learn at once to create the most
        effective educational system possible and tailor it to each student.
      </p>
      <p>
        Our ultimate goal is to give everyone access to a private tutor
        experience through technology.
      </p>
      <hr className="about-hr-bar" />
      <h2>Making learning fun.</h2>
      <p>
        It{"'"}s hard to stay motivated when learning online, so we made
        Duolingo so fun that people would prefer picking up new skills over
        playing a game.
      </p>
      <hr className="about-hr-bar" />
    </Fragment>
  );
};

const Approach = () => {
  return (
    <Fragment>
      <h2>Our teaching approach</h2>
      <p>
        We believe that anyone can learn a language with Trilingo. Our free,
        bite-size lessons feel more like a game than a textbook, and that{"'"}s
        by design: Learning is easier when you{"'"}re having fun.
      </p>
      <p>
        But Trilingo isn{"'"}t just a game. It{"'"}s based on a methodology
        proven to foster long-term retention, and a curriculum aligned to an
        international standard. Let{"'"}s explore how language learning happens
        with Duolingo!
      </p>
      <hr className="about-hr-bar" />
      <h2>Real-life communication</h2>
      <p>
        Language is ultimately a communication tool. Trilingo takes a functional
        approach by focusing on what learners actually want to do with a
        language.
      </p>
      <p>
        Lessons focus on a real-life goal — for instance, ordering at a
        restaurant. Learners develop the vocabulary and grammar needed to
        achieve that goal through lots of varied practice in reading, writing,
        listening, and speaking.
      </p>
      <hr className="about-hr-bar" />
    </Fragment>
  );
};

const JoinUs = () => {
  return (
    <Fragment>
      <h2>Join Us</h2>
      <p>
        At Trilingo, we hire interns for core functions like building apps,
        business development, and marketing. Just because somebody is 18 years
        old, we don{"'"}t believe they can{"'"}t work alongside us like regular
        team members. Many of our interns have extended their internships,
        completed consecutive internships, or joined us full time after they
        graduated.
      </p>
      <hr className="about-hr-bar" />
    </Fragment>
  );
};

const Philosophy = () => {
  return (
    <Fragment>
      <h2>Philosophy</h2>
      <p>
        We{"'"}re a multicultural, diverse group of people working to develop
        the best education in the world and make it universally available.
      </p>
      <hr className="about-hr-bar" />
    </Fragment>
  );
};

const Contact = () => {
  return (
    <Fragment>
      <h2>Contact</h2>
      {/* <p>
        At Trilingo, we hire interns for core functions like building apps,
        business development, and marketing. Just because somebody is 18 years
        old, we don't believe they can't work alongside us like regular team
        members. Many of our interns have extended their internships, completed
        consecutive internships, or joined us full time after they graduated.
      </p> */}
      <p>
        Are you having any trouble?{' '}
        <Link
          style={{color: '#1cb0f6'}}
          to="/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          Find help here.
        </Link>
      </p>
      <hr className="about-hr-bar" />
    </Fragment>
  );
};

const Press = () => {
  return (
    <Fragment>
      <h2>About Trilingo</h2>
      <p>
        At Trilingo, we hire interns for core functions like building apps,
        business development, and marketing. Just because somebody is 18 years
        old, we don{"'"}t believe they can{"'"}t work alongside us like regular
        team members. Many of our interns have extended their internships,
        completed consecutive internships, or joined us full time after they
        graduated.
      </p>
      <hr className="about-hr-bar" />
    </Fragment>
  );
};

const About = props => {
  // eslint-disable-next-line react/prop-types
  const {id} = props;
  const [activeTab, setActiveTab] = useState('mission');
  return (
    <Fragment>
      <Navbar />
      <section className="about">
        <div className="about-content">
          <h1>About Us</h1>
          <div className="about-content-tabs">
            {/* 1. Tab Mission */}
            <div
              className="about-content-tabs-link"
              onClick={() => setActiveTab('mission')}
            >
              <h5
                className={`${
                  activeTab === 'mission'
                    ? 'active-about-tab-link'
                    : 'inactive-about-tab-link'
                }`}
              >
                MISSION
              </h5>
              <hr
                className={`${
                  activeTab === 'mission'
                    ? 'active-about-bar'
                    : 'inactive-about-bar'
                }`}
              />
            </div>
            {/* 2. Tab Approch */}
            <div
              className="about-content-tabs-link"
              onClick={() => setActiveTab('approach')}
            >
              <h5
                className={`${
                  activeTab === 'approach'
                    ? 'active-about-tab-link'
                    : 'inactive-about-tab-link'
                }`}
              >
                APPROACH
              </h5>
              <hr
                className={`${
                  activeTab === 'approach'
                    ? 'active-about-bar'
                    : 'inactive-about-bar'
                }`}
              />
            </div>
            {/* 3. Tab Join Us */}
            <div
              className="about-content-tabs-link"
              onClick={() => setActiveTab('joinus')}
            >
              <h5
                className={`${
                  activeTab === 'joinus'
                    ? 'active-about-tab-link'
                    : 'inactive-about-tab-link'
                }`}
              >
                JOIN US
              </h5>
              <hr
                className={`${
                  activeTab === 'joinus'
                    ? 'active-about-bar'
                    : 'inactive-about-bar'
                }`}
              />
            </div>
            {/* 4. Tab Philosophy */}
            <div
              className="about-content-tabs-link"
              onClick={() => setActiveTab('philosophy')}
            >
              <h5
                className={`${
                  activeTab === 'philosophy'
                    ? 'active-about-tab-link'
                    : 'inactive-about-tab-link'
                }`}
              >
                PHILOSOPHY
              </h5>
              <hr
                className={`${
                  activeTab === 'philosophy'
                    ? 'active-about-bar'
                    : 'inactive-about-bar'
                }`}
              />
            </div>
            {/* 5. Tab Contact */}
            <div
              className="about-content-tabs-link"
              onClick={() => setActiveTab('Contact')}
            >
              <h5
                className={`${
                  activeTab === 'Contact'
                    ? 'active-about-tab-link'
                    : 'inactive-about-tab-link'
                }`}
              >
                Contact
              </h5>
              <hr
                className={`${
                  activeTab === 'Contact'
                    ? 'active-about-bar'
                    : 'inactive-about-bar'
                }`}
              />
            </div>
            {/* 6. Tab Press */}
            {/* <div
              className="about-content-tabs-link"
              onClick={() => setActiveTab('press')}
            >
              <h5
                className={`${
                  activeTab === 'press'
                    ? 'active-about-tab-link'
                    : 'inactive-about-tab-link'
                }`}
              >
                PRESS
              </h5>
              <hr
                className={`${
                  activeTab === 'press'
                    ? 'active-about-bar'
                    : 'inactive-about-bar'
                }`}
              />
            </div> */}
          </div>
          <hr className="about-hr-bar" />
          <div className="about-content-info">
            {activeTab === 'mission' ? <Mission /> : <Fragment></Fragment>}
            {activeTab === 'approach' ? <Approach /> : <Fragment></Fragment>}
            {activeTab === 'joinus' ? <JoinUs /> : <Fragment></Fragment>}
            {activeTab === 'philosophy' ? (
              <Philosophy />
            ) : (
              <Fragment></Fragment>
            )}
            {activeTab === 'Contact' ? <Contact /> : <Fragment></Fragment>}
            {activeTab === 'press' ? <Press /> : <Fragment></Fragment>}
          </div>
        </div>
      </section>
      {id ? <LoggedInFooter /> : <Footer />}
    </Fragment>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;

  return {
    id,
    error: state.auth.authError,
  };
};

export default connect(mapStateToProps)(About);
