/* eslint-disable react/prop-types */
import React, {useState, Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import {userSignUpWithEmailAndPassword} from '../store/actions/authActions';
import i18n from '../i18n/index';
import MeetTrilingo from '../components/MeetTrilingo';
import Footer from '../layout/Footer';

const Homepage = props => {
  const [fullName, setFullName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitClicked, setSubmitClicked] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [terms, setTerms] = useState(true);
  const [maxDate, setMaxDate] = useState('');

  useEffect(() => {
    const d = new Date();
    const maxY = d.getFullYear() - 13;
    console.log(
      `${maxY}-${d.getDate() > 9 ? d.getDate() : '0' + d.getDate()}-${
        d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)
      }`
    );
    setMaxDate(
      `${maxY}-${
        d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)
      }-${d.getDate() > 9 ? d.getDate() : '0' + d.getDate()}`
    );
  }, []);

  /**
   * @author Kn0wn-Un <naikdarsh175@gmail.com>
   * @description used for password validation
   * @returns {boolean} returns boolean
   */
  const validatePasswords = () => {
    setErrorMsg('');
    if (password.trim().length < 8) {
      return false;
    }
    if (password.trim().length > 128) {
      setErrorMsg('Passwords should be less than 128 characters');
      return false;
    }
    if (!/\d/.test(password)) {
      return false;
    }
    if (!/[A-Z]/.test(password)) {
      return false;
    }
    if (!/[a-z]/.test(password)) {
      return false;
    }
    if (/\s/.test(password)) {
      setErrorMsg('Password should not contain spaces');
      return false;
    }
    if (!/\W/.test(password)) {
      return false;
    }
    return true;
  };

  const validateInputs = () => {
    setErrorMsg('');
    if (!(fullName.trim() && password.trim())) {
      setErrorMsg('Please fill all fields');
      return false;
    }
    return validatePasswords();
  };
  const handleSignup = e => {
    e.preventDefault();
    setErrorMsg('');
    if (!validateInputs()) {
      return;
    }
    setSubmitClicked(true);
    props.userSignUpWithEmailAndPassword({
      fullName: fullName.trim(),
      dateOfBirth,
      email: email.trim(),
      password,
    });
  };
  const handleInput = e => {
    switch (e.currentTarget.name) {
      case 'fullName':
        setFullName(e.currentTarget.value);
        break;
      case 'dateOfBirth':
        setDateOfBirth(e.currentTarget.value);
        break;
      case 'email':
        setEmail(e.currentTarget.value);
        break;
      case 'password':
        setPassword(e.currentTarget.value);
        break;
      case 'terms':
        setTerms(!terms);
        break;
      default:
        break;
    }
  };
  const {id, error} = props;
  if (id) {
    return <Redirect to="/learn" />;
  }
  return (
    <Fragment>
      <main className="homepage">
        <section className="homepage__landing">
          <div className="homepage__landing__content">
            <img
              src="/assets/landing-header.jpg"
              className="homepage__landing__content__image"
              alt="landing header"
            />
            <div className="homepage__landing__content__header">
              <h1 className="heading__primary--main homepage__landing__content__header__heading">
                {i18n.t('Learn Tribal Languages')}
              </h1>
              <h3 className="heading__primary--sub homepage__landing__content__header__subheading">
                {i18n.t(
                  'On a mission to organize world indigenous languages and make it universally accessible and useable.'
                )}
              </h3>
              <br />
              <br />
              <a
                href="/signin"
                className="homepage__landing__content__header__link"
              >
                <button className="btn btn-md homepage__landing__content__header__button">
                  {i18n.t('Have an account?')}
                </button>
              </a>
            </div>
          </div>
        </section>
        <section className="homepage__auth">
          <div className="homepage__auth__content">
            <form
              className="homepage__auth__content__form"
              autoComplete="false"
              onSubmit={handleSignup}
            >
              <input
                type="text"
                className="homepage__auth__content__form__input"
                name="fullName"
                placeholder={i18n.t('Full Name')}
                onChange={handleInput}
                autoComplete="false"
                required
              />
              <input
                type="date"
                id="date"
                className="homepage__auth__content__form__input"
                name="dateOfBirth"
                onChange={handleInput}
                autoComplete="false"
                min="1920-01-01"
                max={maxDate}
                required
              />
              <input
                type="email"
                className="homepage__auth__content__form__input"
                name="email"
                placeholder={i18n.t('Enter Email')}
                onChange={handleInput}
                autoComplete="false"
                required
              />
              <input
                type="password"
                className="homepage__auth__content__form__input"
                name="password"
                placeholder={i18n.t('Enter Password')}
                onChange={handleInput}
                autoComplete="false"
                required
              />
              <div className="password-conditions">
                Password should have atleast:
                <div
                  style={
                    password.trim().length >= 8
                      ? {color: 'green'}
                      : {color: 'red'}
                  }
                >
                  8 characters
                </div>
                <div
                  style={
                    /[A-Z]/.test(password) ? {color: 'green'} : {color: 'red'}
                  }
                >
                  one uppercase character
                </div>
                <div
                  style={
                    /[a-z]/.test(password) ? {color: 'green'} : {color: 'red'}
                  }
                >
                  one lowercase character
                </div>
                <div
                  style={
                    /\d/.test(password) ? {color: 'green'} : {color: 'red'}
                  }
                >
                  one digit
                </div>
                <div
                  style={
                    /\W/.test(password) && !/\s/.test(password)
                      ? {color: 'green'}
                      : {color: 'red'}
                  }
                >
                  one special character
                </div>
              </div>
              <div className="accept-terms">
                <input
                  id="terms"
                  type="checkbox"
                  name="terms"
                  checked={terms}
                  onChange={handleInput}
                  autoComplete="false"
                  required
                />
                <label>
                  By signing up I agree that I have read and understood the{' '}
                  <Link
                    to="/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms and conditions
                  </Link>{' '}
                  and the{' '}
                  <Link
                    to="/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    privacy policy.
                  </Link>
                </label>
              </div>
              <button
                className="btn btn-md homepage__auth__content__form__submit"
                disabled={!terms}
              >
                {submitClicked && !error
                  ? `${i18n.t('Signing up')}`
                  : `${i18n.t('Sign up')}`}
              </button>
            </form>
            <h1 className="homepage__auth__content__form__error">
              {!errorMsg ? (!error ? ' ' : i18n.t(error)) : {errorMsg}}
            </h1>
          </div>
        </section>
      </main>
      <MeetTrilingo />
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;
  return {
    id,
    error: state.auth.authError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userSignUpWithEmailAndPassword: credentials =>
      dispatch(userSignUpWithEmailAndPassword(credentials)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
