/* eslint-disable react/prop-types */
import React from 'react';
import axios from 'axios';
import config from '../config';
import {GrClose} from 'react-icons/gr';

/**
 * @author NevetsKuro <stevenfernz96@gmail.com>
 * @description Report Modal
 * @param {{toggleShowModal: Function, currentWord: string}} toggleShowModal - to toggle modal show/hide value and word id
 * @returns {import('react').ReactElement} -
 */
const ReportModal = ({toggleShowModal, currentWord}) => {
  /**
   * @author NevetsKuro <stevenfernz96@gmail.com>
   * @function sendReport
   * @param {string} reportedWord - current word
   * @description to send a report of the problem cause while answering the current question
   */
  const sendReport = reportedWord => {
    const currLang = localStorage.getItem('languageData').split('-')[1];
    const reasons = [];
    let textBoxCheck = true;
    document.querySelectorAll('input.report-radio').forEach(cb => {
      if (cb.checked) {
        reasons.push(cb.getAttribute('data-reason'));
      }
    });
    if (document.getElementById('report-radio-textbox-radio').checked) {
      if (document.getElementById('report-input').value) {
        textBoxCheck = true;
      } else {
        alert(
          'Kindly add the other reason or uncheck "My problem is not listed"'
        );
        textBoxCheck = false;
      }
    }
    if (textBoxCheck) {
      if (currLang && reportedWord && reasons.length) {
        axios({
          method: 'post',
          url: `${config.REACT_APP_NODE_API_URL}/api/user/reports/create/${
            JSON.parse(localStorage.getItem('currentUser'))._id
          }`,
          data: {
            wordID: reportedWord,
            reason: reasons.join(','),
            languageCode: currLang,
            comment: document.getElementById('report-input').value,
          },
        })
          .then(res => {
            // console.log(res.data.msg);
            alert(res.data.msg);
            toggleShowModal();
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        alert('Kindly check/state at least one reason.');
      }
    }
  };
  const toggleTextBox = () => {
    document.getElementById('report-input').disabled =
      !document.getElementById('report-input').disabled;
  };
  return (
    <div className="modal-container">
      <div className="backdrop">
        <div className="modal-body-report">
          <div className="close" onClick={() => toggleShowModal()}>
            <GrClose />
          </div>
          <div className="modal-header">Report a bug</div>
          <div className="report-container">
            <div className="report-row">
              <input
                type="checkbox"
                className="report-radio"
                data-reason="Traslation is wrong"
              />
              <div className="report-radio-label">
                <b>Translation</b> is wrong.
              </div>
            </div>
            <div className="report-row">
              <input
                type="checkbox"
                className="report-radio"
                data-reason="Spelling is wrong"
              />
              <div className="report-radio-label">
                <b>Spelling</b> is wrong.
              </div>
            </div>
            <div className="report-row">
              <input
                type="checkbox"
                className="report-radio"
                data-reason="Meaning is wrong"
              />
              <div className="report-radio-label">
                <b>Meaning </b>is wrong.
              </div>
            </div>
            <div className="report-row">
              <input
                type="checkbox"
                className="report-radio"
                data-reason="same word different meaning"
              />
              <div className="report-radio-label">
                I know a <b>synonym</b>.
              </div>
            </div>
            <div className="report-row">
              <input
                type="checkbox"
                id="report-radio-textbox-radio"
                className="report-radio"
                data-reason="My problem is not listed"
                onClick={() => {
                  toggleTextBox();
                }}
              />
              <div className="report-radio-label">
                <b>My problem</b> is not listed.
              </div>
            </div>
            <div className="report-column">
              <textarea
                type="text"
                id="report-input"
                className="report-input"
                rows="3"
                maxLength="60"
                disabled
              ></textarea>
            </div>
            <div className="report-row">
              <input
                type="button"
                className="report-button"
                value="SUBMIT"
                onClick={() => sendReport(currentWord)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportModal;
