/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

const AddTF = ({match, location}) => {
  const [values, setValues] = useState({
    question: '',
    right_ans: true,
    error: '',
    success: '',
  });
  // eslint-disable-next-line camelcase
  const {question, right_ans, error, success} = values;
  const word = match.params.word;
  const modLang = localStorage.getItem('modLang')
    ? localStorage.getItem('modLang')
    : null;
  const handleChange = name => event => {
    const val = event.target.value;
    setValues({...values, error: '', success: '', [name]: val});
  };
  const handleSubmit = e => {
    e.preventDefault();
    axios
      .post(`${config.REACT_APP_NODE_API_URL}/api/quiz/add/t-f`, {
        question,
        right_ans,
        question_type: 2,
        language: `hin-${modLang}`,
        question_level: location.state.category,
        word: match.params.word,
      })
      .then(res => {
        setValues({...values, success: res.data.message, error: ''});
      })
      .catch(err => {
        setValues({...values, error: err.response.data.message});
        console.log(err);
      });
  };
  const language =
    modLang === 'mun'
      ? ' Mundari'
      : modLang === 'kur'
      ? ' Kuruk'
      : modLang === 'san'
      ? ' Santhali'
      : modLang === 'hoc'
      ? ' Ho'
      : modLang === 'khr'
      ? ' Kharia'
      : modLang === 'bhb'
      ? ' Bhili'
      : modLang === 'gon'
      ? ' Gondi'
      : modLang === 'tcy'
      ? ' Tulu'
      : modLang;
  return (
    <div
      style={{marginTop: '11.4rem', textAlign: 'center', fontSize: '1.5rem'}}
    >
      <h1>
        Language:
        {language}
      </h1>
      <h2>Word: {word}</h2>
      <h2>Category: {location.state.category}</h2>
      <form className="add_question__form" onSubmit={handleSubmit}>
        <div>
          <label className="add_question__form__label">Question: </label>
          <input
            type="text"
            onChange={handleChange('question')}
            className="add_question__form__input"
            value={question}
          />
        </div>
        <div>
          <input
            type="radio"
            name="right_ans"
            value={true}
            defaultChecked
            id="true"
            onChange={handleChange('right_ans')}
          />
          <label htmlFor="true">True</label>
          <input
            type="radio"
            name="right_ans"
            value={false}
            id="false"
            onChange={handleChange('right_ans')}
          />
          <label htmlFor="false">False</label>
          <br />
        </div>
        <br />
        {success && <h2 style={{color: 'green'}}>{success}</h2>}
        {error && <h2 style={{color: 'red'}}>{error}</h2>}
        <br />
        <div>
          <button className="btn btn-sm" type="submit">
            Submit
          </button>
          <Link
            to="/moderator/manage-quiz"
            className="btn btn-sm"
            style={{
              marginLeft: '0.4rem',
            }}
          >
            Back
          </Link>
        </div>
      </form>
    </div>
  );
};

export default AddTF;
