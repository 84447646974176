import React, {Fragment, useState, useEffect} from 'react';
import axios from 'axios';
import config from '../config';

const AchievementsIcon = () => {
  const [vocabPoints, setVocabPoints] = useState(0);
  // const [totalWords, setTotalWords] = useState(0);
  const currentUser = localStorage.getItem('currentUser')
    ? localStorage.getItem('currentUser')
    : null;
  const id = JSON.parse(currentUser)._id;
  useEffect(() => {
    axios
      .get(
        `${config.REACT_APP_NODE_API_URL}/api/user/get-points/${id}/${
          localStorage.getItem('languageData').split('-')[1]
        }`
      )
      .then(res => {
        setVocabPoints(res.data.data.userPoints.soil);
        // setTotalWords(res.data.data.totalWords);
      })
      .catch(err => console.log(err));
  }, [id]);
  const gemSize = 50;
  return (
    <Fragment>
      <div style={{marginBottom: '2rem'}}>
        {/* Heading */}
        <div style={{display: 'flex', marginBottom: '3rem'}}>
          <h5 style={{flexGrow: '1', fontSize: '2.8rem'}}>Statistics</h5>
        </div>
        {/* Heading End */}
        {/* Icons */}
        <div className="achievements-icons">
          {/* 1 Vocabulary */}
          <div className="achievements-icons-dropdown">
            <div className="achievements-icons-item">
              <span className="achievements-icons-item-logo">
                <img
                  src="/assets/Gems/Earth.png"
                  alt="earth"
                  width={gemSize}
                  height={gemSize}
                />
              </span>{' '}
              <span
                className="achievements-icons-item-text"
                style={{
                  color: '#ba6828',
                }}
              >
                {`${vocabPoints}`}
                {/* {`${vocabPoints}/${totalWords}`} */}
              </span>
            </div>
            <div className="achievements-icons-dropdown-content drop-down-vocabulary">
              <p>
                <span>
                  <img
                    src="/assets/Gems/Earth.png"
                    alt="earth"
                    width={gemSize}
                    height={gemSize}
                  />
                </span>{' '}
                {/* Vocabulary {`${vocabPoints}/${totalWords}`} words */}
                <strong>Vocabulary</strong> <br />
                &nbsp;&nbsp;&nbsp;basic words from the language
              </p>
            </div>
          </div>
          {/* 2 Listening */}
          <div className="achievements-icons-dropdown">
            <div className="achievements-icons-item">
              <span className="achievements-icons-item-logo">
                <img
                  src="/assets/Gems/Leaf.png"
                  alt="leaf"
                  width={gemSize}
                  height={gemSize}
                />
              </span>{' '}
              <span
                className="achievements-icons-item-text"
                style={{
                  color: '#4b8f62',
                }}
              >
                0
              </span>
            </div>
            <div className="achievements-icons-dropdown-content drop-down-listening">
              <p>
                <span>
                  <img
                    src="/assets/Gems/Leaf.png"
                    alt="leaf"
                    width={gemSize}
                    height={gemSize}
                  />
                </span>{' '}
                {/* You have completed 0/0 words */}
                <strong>Listening (coming soon)</strong> <br />
                &nbsp;&nbsp;&nbsp;how efficiently and effectively you hear
              </p>
            </div>
          </div>
          {/* 3 Speaking */}
          <div className="achievements-icons-dropdown">
            <div className="achievements-icons-item">
              <span className="achievements-icons-item-logo">
                <img
                  src="/assets/Gems/Fire.png"
                  alt="fire"
                  width={gemSize}
                  height={gemSize}
                />
              </span>{' '}
              <span
                className="achievements-icons-item-text"
                style={{
                  color: '#ea5425',
                }}
              >
                0
              </span>
            </div>
            <div className="achievements-icons-dropdown-content drop-down-speaking">
              <p>
                <span>
                  <img
                    src="/assets/Gems/Fire.png"
                    alt="fire"
                    width={gemSize}
                    height={gemSize}
                  />
                </span>{' '}
                {/* You have completed 0/0 words */}
                <strong>Speaking (coming soon)</strong> <br />
                &nbsp;&nbsp;&nbsp;how efficiently and effectively you speak
              </p>
            </div>
          </div>
          {/* 4 Reading */}
          <div className="achievements-icons-dropdown">
            <div className="achievements-icons-item">
              <span className="achievements-icons-item-logo top14">
                <img
                  src="/assets/Gems/Water.png"
                  alt="water"
                  width={gemSize}
                  height={gemSize}
                />
              </span>{' '}
              <span
                className="achievements-icons-item-text"
                style={{
                  color: '#3480c3',
                }}
              >
                0
              </span>
            </div>
            <div className="achievements-icons-dropdown-content drop-down-reading">
              <p>
                <span>
                  <img
                    src="/assets/Gems/Water.png"
                    alt="water"
                    width={gemSize}
                    height={gemSize}
                  />
                </span>{' '}
                {/* You have completed 0/0 words */}
                <strong>Reading (coming soon)</strong> <br />
                &nbsp;&nbsp;&nbsp;how efficiently and effectively you read
              </p>
            </div>
          </div>
          {/* 5 Writing */}
          <div className="achievements-icons-dropdown">
            <div className="achievements-icons-item">
              <span className="achievements-icons-item-logo">
                <img
                  src="/assets/Gems/Sky.png"
                  alt="sky"
                  width={gemSize}
                  height={gemSize}
                />
              </span>{' '}
              <span
                className="achievements-icons-item-text"
                style={{
                  color: '#748ab8',
                }}
              >
                0
              </span>
            </div>
            <div className="achievements-icons-dropdown-content drop-down-writing">
              <p>
                <span>
                  <img
                    src="/assets/Gems/Sky.png"
                    alt="sky"
                    width={gemSize}
                    height={gemSize}
                  />
                </span>{' '}
                {/* You have completed 0/0 words */}
                <strong>Writing (coming soon)</strong> <br />
                &nbsp;&nbsp;&nbsp;how efficiently and effectively you write
              </p>
            </div>
          </div>
        </div>
        {/* Icons End */}
      </div>
    </Fragment>
  );
};

export default AchievementsIcon;
