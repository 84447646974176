/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, {Component} from 'react';
import Timer from './Timer';
import Progress from './QuizProcessbar';

/**
 * @class QuizCard
 * @augments Component
 */
class QuizCard extends Component {
  /**
   * @class QuizCard
   * @param {any} props - Props passed to the component
   */
  constructor(props) {
    super(props);
    this.state = {
      userOption: '',
    };
  }
  /**
   * @function render
   * @returns {import('react').JSXElementConstructor} JSX Body
   */
  render() {
    const {
      question,
      duration,
      options,
      calTimeFn,
      checkAnswerFn,
      right_ans,
      index,
      questionLevel,
      totalQuestions,
    } = this.props;
    const progressvalue = Math.floor((index / totalQuestions) * 100);
    return (
      <div style={{marginTop: '100px'}} className="quiz">
        <div className="level-section">
          <div className="level-name">{questionLevel}</div>
          <div className="timer">
            <Timer
              duration={duration}
              timeoutFn={() => checkAnswerFn(this.state.userOption, right_ans)}
              calTimeFn={() => calTimeFn(1)}
            />
          </div>
          <div className="question-count">
            Question: {index + 1} / {totalQuestions}
          </div>
        </div>
        <div className="level-processbar">
          <Progress done={progressvalue} />
        </div>
        <header></header>
        <div className="question-section">
          <div className="question-text">{question}</div>
          <div className="answer-options">
            {options.map((opt, idx) => {
              return (
                <React.Fragment key={idx}>
                  <label className="answer-button">
                    <input
                      type="radio"
                      name="option"
                      value={opt}
                      onChange={e =>
                        this.setState({userOption: e.target.value})
                      }
                    />{' '}
                    {opt}
                  </label>
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <button
          onClick={() => checkAnswerFn(this.state.userOption, right_ans)}
          className="next-button"
        >
          Next Question
        </button>
      </div>
    );
  }
}

export default QuizCard;
