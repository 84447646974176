/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import axios from 'axios';
import config from '../../config';

const EditQuestion = ({match}) => {
  const [question, setQuestion] = useState('');
  const [opt_1, setOption1] = useState('');
  const [opt_2, setOption2] = useState('');
  const [opt_3, setOption3] = useState('');
  const [opt_4, setOption4] = useState('');
  const [message, setMessage] = useState('');
  const questionId = match.params.questionId;
  useEffect(() => {
    axios
      .get(`${config.REACT_APP_NODE_API_URL}/api/quiz/question/${questionId}`)
      .then(res => {
        setQuestion(res.data.data.question);
        setOption1(res.data.data.options[0]);
        setOption2(res.data.data.options[1]);
        setOption3(res.data.data.options[2]);
        setOption4(res.data.data.options[3]);
      })
      .catch(err => console.log(err));
  }, [questionId]);
  const handleSubmit = e => {
    e.preventDefault();
    setMessage('');
    axios
      .put(`${config.REACT_APP_NODE_API_URL}/api/quiz/update/${questionId}`, {
        question,
        options: [opt_1, opt_2, opt_3, opt_4],
      })
      .then(() => {
        setMessage('Question updated sucessfully..');
        setTimeout(() => {
          setMessage('');
        }, 1000);
      })
      .catch(err => {
        setMessage(
          err.response.data
            ? err.response.data.message
            : 'Something went wrong, Try again'
        );
      });
  };

  return (
    <div
      style={{marginTop: '11.4rem', textAlign: 'center', fontSize: '1.5rem'}}
    >
      <h1>Edit Question</h1>
      <form className="add_question__form" onSubmit={handleSubmit}>
        <div>
          <label className="add_question__form__label">Question: </label>
          <input
            type="text"
            onChange={e => setQuestion(e.target.value)}
            className="add_question__form__input"
            value={question}
          />
        </div>
        <div>
          <label className="add_question__form__label">
            Option 1 (Right Answer)
          </label>
          <input
            type="text"
            onChange={e => setOption1(e.target.value)}
            value={opt_1}
            className="add_question__form__input"
          />
        </div>
        <div>
          <label className="add_question__form__label">Option 2</label>
          <input
            type="text"
            onChange={e => setOption2(e.target.value)}
            value={opt_2}
            className="add_question__form__input"
          />
        </div>
        <div>
          <label className="add_question__form__label">Option 3</label>
          <input
            type="text"
            className="add_question__form__input"
            onChange={e => setOption3(e.target.value)}
            value={opt_3}
          />
        </div>
        <div>
          <label className="add_question__form__label">Option 4</label>
          <input
            type="text"
            onChange={e => setOption4(e.target.value)}
            value={opt_4}
            className="add_question__form__input"
          />
        </div>
        {message && <h2>{message}</h2>}
        <div>
          <button className="btn btn-sm" type="submit">
            Submit
          </button>

          <Link
            to="/moderator/manage-quiz"
            className="btn btn-sm"
            style={{
              marginLeft: '.4rem',
            }}
          >
            Back
          </Link>
        </div>
      </form>
    </div>
  );
};

export default EditQuestion;
