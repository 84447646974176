/* eslint-disable react/prop-types */
import React from 'react';

const Progress = ({done}) => {
  return (
    <div>
      <div className="progress">
        <div
          className="progress__done"
          style={{opacity: 1, width: `${done}%`}}
        ></div>
      </div>
    </div>
  );
};

export default Progress;
