import React, {Fragment, useState} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import Footer from '../layout/Footer';
import LoggedInFooter from '../layout/LoggedInFooter';
import Navbar from '../layout/Navbar';

const HearAboutUs = props => {
  // eslint-disable-next-line react/prop-types
  const {id} = props;
  const [source, setSource] = useState('');
  const [alert, setAlert] = useState(false);
  const [btnClick, setBtnClick] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    if (source) {
      setAlert(false);
      localStorage.setItem('source', source);
      setBtnClick(true);
    } else {
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 2000);
    }
  };
  if (btnClick) {
    return <Redirect to="/why-learning-language" />;
  }
  return (
    <Fragment>
      <Navbar />
      <section className="hear-about-us">
        {alert ? (
          <h1 className="why-learning-language-alert">
            * Please select a reason for learning a language
          </h1>
        ) : (
          <></>
        )}
        <h1>How did you hear about us</h1>
        <div className="hear-about-us-content">
          <div className="hear-about-us-content-card">
            {/* 1 */}
            <div
              onClick={() => setSource('web-search')}
              className={`${
                source === 'web-search'
                  ? 'hear-about-us-content-card-content active-source'
                  : 'hear-about-us-content-card-content'
              } `}
            >
              <h5>Web Search</h5>
            </div>
            <hr />
            {/* 2 */}
            <div
              onClick={() => setSource('news')}
              className={`${
                source === 'news'
                  ? 'hear-about-us-content-card-content active-source'
                  : 'hear-about-us-content-card-content'
              } `}
            >
              <h5>News, article or blog</h5>
            </div>
            <hr />
            {/* 3 */}
            <div
              onClick={() => setSource('ads')}
              className={`${
                source === 'ads'
                  ? 'hear-about-us-content-card-content active-source'
                  : 'hear-about-us-content-card-content'
              } `}
            >
              <h5>Online ads </h5>
            </div>
            <hr />
            {/* 4 */}
            <div
              onClick={() => setSource('family')}
              className={`${
                source === 'family'
                  ? 'hear-about-us-content-card-content active-source'
                  : 'hear-about-us-content-card-content'
              } `}
            >
              <h5>Friends or Family</h5>
            </div>
            <hr />
            {/* 5 */}
            <div
              onClick={() => setSource('social-media')}
              className={`${
                source === 'social-media'
                  ? 'hear-about-us-content-card-content active-source'
                  : 'hear-about-us-content-card-content'
              } `}
            >
              <h5>Social Media</h5>
            </div>
            <hr />
            {/* 6 */}
            <div
              onClick={() => setSource('radio')}
              className={`${
                source === 'radio'
                  ? 'hear-about-us-content-card-content active-source'
                  : 'hear-about-us-content-card-content'
              } `}
            >
              <h5>Radio</h5>
            </div>
            <hr />
            {/* 7 */}
            <div
              onClick={() => setSource('tv')}
              className={`${
                source === 'tv'
                  ? 'hear-about-us-content-card-content active-source'
                  : 'hear-about-us-content-card-content'
              } `}
            >
              <h5>Tv</h5>
            </div>
            <hr />
            {/* 8 */}
            <div
              onClick={() => setSource('other')}
              className={`${
                source === 'other'
                  ? 'hear-about-us-content-card-content active-source'
                  : 'hear-about-us-content-card-content'
              } `}
            >
              <h5>Other</h5>
            </div>
          </div>
          <button onClick={handleSubmit}>Continue</button>
        </div>
      </section>
      {id ? <LoggedInFooter /> : <Footer />}
    </Fragment>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;

  return {
    id,
    error: state.auth.authError,
  };
};

export default connect(mapStateToProps)(HearAboutUs);
