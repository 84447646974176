import React, {useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../config';

const EditCourses = () => {
  const [languages, setLanguages] = useState([]);
  const [langCodes, setLangCodes] = useState([]);
  const [edit, setEdit] = useState('');
  const [add, setAdd] = useState(false);
  const [ref, setRef] = useState(false);
  const token = localStorage.getItem('adminToken');
  useEffect(() => {
    axios
      .get(`${config.REACT_APP_NODE_API_URL}/api/vocab/get/languages`)
      .then(res => {
        console.log(res.data.data);
        setLangCodes(res.data.data);
      })
      .catch(console.error);
  }, []);
  useEffect(() => {
    axios
      .get(`${config.REACT_APP_NODE_API_URL}/api/vocab/public/language-data`)
      .then(x => {
        setLanguages(x.data.data);
        console.log(x);
      })
      .catch(console.error);
  }, [ref]);
  return (
    <section className="courses">
      <h1>Trilingo Courses</h1>
      <div className="courses-content">
        {languages.map((x, i) => (
          <div
            suppressContentEditableWarning={true}
            className="courses-content-card"
            key={i}
            style={{
              overflow: 'auto',
            }}
          >
            <h2>
              <span contentEditable={edit === x._id} id={`${x._id}_name`}>
                {x.name}
              </span>{' '}
              (
              <span contentEditable={edit === x._id} id={`${x._id}_status`}>
                {x.status}
              </span>
              )
            </h2>
            <p style={{height: '173px'}}>
              <span contentEditable={edit === x._id} id={`${x._id}_details`}>
                {x.details}
              </span>
              <br />
              <span
                style={{fontWeight: 'bold'}}
                contentEditable={edit === x._id}
                id={`${x._id}_tagLine`}
              >
                {x.tagLine}
              </span>
            </p>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <select
                name="Language Code"
                id={`${x._id}_languageCode`}
                disabled={edit !== x._id}
                required
                style={{
                  textAlign: 'center',
                  marginBottom: '10px',
                }}
              >
                <option>Select a language code</option>
                {langCodes.map((lang, langIndex) => (
                  <option
                    key={langIndex}
                    value={lang}
                    selected={lang === x.languageCode}
                  >
                    {lang}
                  </option>
                ))}
              </select>
            </div>
            <span
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                display: 'flex',
                marginBottom: '10px',
              }}
            >
              <button
                type="button"
                className="btn btn-sm"
                onClick={() => {
                  if (!edit) {
                    return setEdit(x._id);
                  } else if (edit !== x._id) {
                    return alert('Save edited data before updating data!');
                  }
                  if (
                    document.getElementById(`${x._id}_languageCode`).value ===
                    'Select a language code'
                  ) {
                    return alert('No language code specified!');
                  }
                  axios
                    .post(
                      `${config.REACT_APP_NODE_API_URL}/api/admin/update-public-language-data`,
                      {
                        _id: x._id,
                        name: document.getElementById(`${x._id}_name`)
                          .textContent,
                        status: document.getElementById(`${x._id}_status`)
                          .textContent,
                        details: document.getElementById(`${x._id}_details`)
                          .textContent,
                        tagLine: document.getElementById(`${x._id}_tagLine`)
                          .textContent,
                        languageCode: document.getElementById(
                          `${x._id}_languageCode`
                        ).value,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    )
                    .then(res => {
                      console.log(res);
                      setRef(z => !z);
                      setEdit(null);
                    })
                    .catch(console.error);
                }}
              >
                {edit === x._id ? 'Save' : 'Edit'}
              </button>
              <button
                type="button"
                className="btn btn-sm"
                style={{
                  marginLeft: '2px',
                }}
                onClick={() => {
                  if (
                    window.confirm(
                      'Are you sure you want to delete this language course? This action is not reversible!'
                    )
                  ) {
                    axios
                      .post(
                        `${config.REACT_APP_NODE_API_URL}/api/admin/update-public-language-data`,
                        {
                          _id: x._id,
                          delete: true,
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        }
                      )
                      .then(res => {
                        console.log(res);
                        setRef(z => !z);
                        setEdit(null);
                      })
                      .catch(console.error);
                  }
                }}
              >
                Delete
              </button>
              <button
                type="button"
                style={{
                  display: edit === x._id ? 'inline' : 'none',
                  marginLeft: '2px',
                }}
                className="btn btn-sm"
                onClick={() => {
                  if (
                    edit === x._id &&
                    window.confirm(
                      'Are you sure you want to cancel changes made to this language course? This action is not reversible!'
                    )
                  ) {
                    document.getElementById(`${x._id}_name`).textContent =
                      x.name;
                    document.getElementById(`${x._id}_status`).textContent =
                      x.status;
                    document.getElementById(`${x._id}_details`).textContent =
                      x.details;
                    document.getElementById(`${x._id}_tagLine`).textContent =
                      x.tagLine;
                    document.getElementById(`${x._id}_languageCode`).value =
                      'Select a language code';
                    setEdit(null);
                  }
                }}
              >
                Cancel
              </button>
            </span>
          </div>
        ))}
        <div
          className="courses-content-card"
          suppressContentEditableWarning={true}
        >
          <h2>
            <span contentEditable={add} id="new_content_name">
              Name
            </span>{' '}
            (
            <span contentEditable={add} id="new_content_status">
              Status
            </span>
            )
          </h2>
          <p style={{height: '173px'}}>
            <span contentEditable={add} id="new_content_details">
              Details Here
            </span>
            <br />
            <span
              style={{fontWeight: 'bold'}}
              contentEditable={add}
              id="new_content_tagLine"
            >
              Tag Line Here
            </span>
          </p>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <select
              name="Language Code"
              id={`new_content_languageCode`}
              disabled={!add}
              required
              style={{
                textAlign: 'center',
                marginBottom: '10px',
              }}
            >
              <option>Select a language code</option>
              {langCodes.map((lang, langIndex) => (
                <option key={langIndex} value={lang}>
                  {lang}
                </option>
              ))}
            </select>
          </div>
          <span
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              display: 'flex',
              marginBottom: '10px',
            }}
          >
            <button
              type={add ? 'submit' : 'button'}
              className="btn btn-sm"
              onClick={() => {
                if (!add) {
                  if (edit) {
                    return alert('Save edited data before creating new data!');
                  }
                  return setAdd(true);
                }
                if (
                  document.getElementById('new_content_languageCode').value ===
                  'Select a language code'
                ) {
                  return alert('No language code specified!');
                }
                axios
                  .post(
                    `${config.REACT_APP_NODE_API_URL}/api/admin/update-public-language-data`,
                    {
                      name: document.getElementById('new_content_name')
                        .textContent,
                      status:
                        document.getElementById('new_content_status')
                          .textContent,
                      details: document.getElementById('new_content_details')
                        .textContent,
                      tagLine: document.getElementById('new_content_tagLine')
                        .textContent,
                      languageCode: document.getElementById(
                        'new_content_languageCode'
                      ).value,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then(res => {
                    console.log(res);
                    setRef(z => !z);
                    setEdit(null);
                    document.getElementById('new_content_name').textContent =
                      'Name';
                    document.getElementById('new_content_status').textContent =
                      'Status';
                    document.getElementById('new_content_details').textContent =
                      'Details Here';
                    document.getElementById('new_content_tagLine').textContent =
                      'Tag Line Here';
                    setAdd(false);
                  })
                  .catch(console.error);
              }}
            >
              {add ? 'Save' : 'Add'}
            </button>
            <button
              type="button"
              style={{
                display: add ? 'inline' : 'none',
                marginLeft: '2px',
              }}
              className="btn btn-sm"
              onClick={() => {
                if (add) {
                  if (
                    window.confirm(
                      'Are you sure you want to cancel adding this language course? This action is not reversible!'
                    )
                  ) {
                    document.getElementById('new_content_name').textContent =
                      'Name';
                    document.getElementById('new_content_status').textContent =
                      'Status';
                    document.getElementById('new_content_details').textContent =
                      'Details Here';
                    document.getElementById('new_content_tagLine').textContent =
                      'Tag Line Here';
                    document.getElementById('new_content_languageCode').value =
                      'Select a language code';
                    setAdd(false);
                  }
                }
              }}
            >
              Cancel
            </button>
          </span>
        </div>
      </div>
    </section>
  );
};

export default EditCourses;
