/* eslint-disable react/prop-types */
import React, {useState, useEffect, Fragment} from 'react';
import {connect} from 'react-redux';
import {Redirect, Link} from 'react-router-dom';
import axios from 'axios';
import Navbar from '../layout/Navbar';
import CategoryCard from '../components/CategoryCard';
import Loader from '../utils/Loader';
import config from '../config';
import ProgressGraph from '../components/ProgressGraph';
import AchievementsIcon from '../components/AchievementsIcon';
import LoggedInFooter from '../layout/LoggedInFooter';
import Modal from '../components/Modal';
import GreetUser from '../components/GreetUser';

const Learn = props => {
  const [categories, setCategories] = useState([]);
  const [progressData, setProgressData] = useState([]);
  const [isActivated, setActivated] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const {id} = props;
  useEffect(() => {
    axios({
      method: 'get',
      url: `${config.REACT_APP_NODE_API_URL}/api/user/${id}`,
    })
      .then(res => {
        console.log(res.data.data);
        setActivated(res.data.data?.isAccountActive);
        if (!res.data.data?.languages.length) {
          return window.location.replace('/courses');
        }
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser !== 'null') {
          if (!currentUser.newUser || currentUser.newUser === 'Yes') {
            setShowModal(true);
            currentUser.newUser = 'No';
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
          } else {
            setShowModal(false);
          }
        }
        const lang = res.data.data?.languages[0].split('-')[1];
        return axios({
          method: 'get',
          url: `${config.REACT_APP_NODE_API_URL}/api/vocab/get-category/${lang}`,
        });
      })
      .then(res => {
        setCategories(res.data.data.categories.map(el => el.categoryName));
        return axios({
          method: 'get',
          url: `${
            config.REACT_APP_NODE_API_URL
          }/api/algo/get-progress-data/${id}/${
            localStorage.getItem('languageData').split('-')[1]
          }`,
        });
      })
      .then(res => {
        console.log(res.data.data);
        setProgressData(res.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [id]);
  let previous = null;
  if (!id) {
    return <Redirect to="/" />;
  }
  const closeModal = () => {
    console.log('toggle!');
    setShowModal(false);
  };
  return (
    <Fragment>
      <Navbar />
      <>
        {showModal && (
          <Modal>
            <GreetUser closeModal={closeModal} />
          </Modal>
        )}
      </>
      <section className="learn">
        {isActivated ? (
          <></>
        ) : (
          <div className="activate-prompt">
            You have not verified your email, you will have limited access to
            the lessons, <Link to="/verify">click here</Link> to verify your
            email
          </div>
        )}
        {/* Hidden in large device */}
        <div className="user_progress_small">
          <div className="user_progress_card">
            <AchievementsIcon />
          </div>
          {/* <div className="user_progress_card">
          <Achievements />
        </div> */}
          <div className="user_progress_card">
            <ProgressGraph />
          </div>
        </div>

        <div className="learn-row">
          {/* 1 */}
          <div className="learn__content">
            {categories.length > 0 ? (
              categories.map((el, key, array) => {
                const progress = progressData?.find(
                  level => level?.levelName === el
                )?.levelProgress;
                if (!key) {
                  previous = progress;
                } else {
                  previous = progressData?.find(
                    level => level?.levelName === array[key - 1]
                  )?.levelProgress;
                }
                return (
                  <div
                    className={`${
                      previous >= 80 || !key
                        ? 'learn__content__card'
                        : 'learn__content__cardblocked'
                    }`}
                    key={key}
                  >
                    <Link
                      to={`/vocab/category/${el}`}
                      className="cardlink"
                      key={key}
                    >
                      <CategoryCard title={el} progress={progress || 0} />
                      <input
                        className={`${
                          progress > 0 || previous >= 80
                            ? 'takequizbtn'
                            : 'takequizbtnlocked'
                        }`}
                        type="submit"
                        value={`${
                          progress > 0 || previous >= 80 ? 'Unlocked' : 'Locked'
                        }`}
                      />
                    </Link>
                  </div>
                );
              })
            ) : (
              <Loader />
            )}
          </div>
          <div className="user_progress_large">
            <div className="user_progress_card">
              <AchievementsIcon />
            </div>
            <div className="user_progress_card">
              <ProgressGraph width={400} height={400} />
            </div>
          </div>
        </div>
      </section>
      <LoggedInFooter />
    </Fragment>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;

  return {
    id,
  };
};

export default connect(mapStateToProps, null)(Learn);
