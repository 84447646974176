import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import config from '../../config';

const ManageReports = () => {
  const [data, setData] = useState([]);
  const [message, setMessage] = useState([]);
  const [token] = useState(
    localStorage.getItem('adminToken')
      ? localStorage.getItem('adminToken')
      : null
  );
  const [modLang] = useState(
    localStorage.getItem('modLang') ? localStorage.getItem('modLang') : null
  );
  useEffect(() => {
    axios
      .get(
        `${config.REACT_APP_NODE_API_URL}/api/admin/mod/reports/${modLang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(res => {
        console.log(res.data.data);
        setData(res.data.data);
      })
      .catch(console.error);
  }, [modLang, token]);
  let language;
  if (modLang) {
    language =
      modLang === 'mun'
        ? ' Mundari'
        : modLang === 'kur'
        ? ' Kuruk'
        : modLang === 'san'
        ? ' Santhali'
        : modLang === 'hoc'
        ? ' Ho'
        : modLang === 'khr'
        ? ' Kharia'
        : modLang === 'bhb'
        ? ' Bhili'
        : modLang === 'gon'
        ? ' Gondi'
        : modLang === 'tcy'
        ? ' Tulu'
        : 'Error';
  }
  return (
    <div style={{padding: '20rem', fontSize: '1.5rem'}}>
      <Link to="/moderator/dashboard" className="btn btn-sm">
        Back
      </Link>
      <h1 style={{textAlign: 'center'}}>Language: {language}</h1>
      <h2 style={{textAlign: 'center'}}>{message ? message : ''}</h2>
      <div>
        <table className="managewords__table">
          <thead>
            <tr className="managewords__tr">
              <th className="managewords__th">#</th>
              <th className="managewords__th">English</th>
              <th className="managewords__th">Hindi</th>
              <th className="managewords__th">{language}(English)</th>
              <th className="managewords__th">{language}(Hindi)</th>
              <th className="managewords__th">Category</th>
              <th className="managewords__th">Moderator</th>
              <th className="managewords__th">Report Reason</th>
              <th className="managewords__th">Report User</th>
              <th className="managewords__th">Report Comments</th>
              <th className="managewords__th">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((word, idx) => (
                <tr key={idx} className="managewords__tr">
                  <td className="managewords__td">{idx + 1}</td>
                  <td className="managewords__td">
                    {word.word.englishInEnglish
                      ? word.word.englishInEnglish
                      : 'null'}
                  </td>
                  <td className="managewords__td">
                    {word.word.hindiInHindi ? word.word.hindiInHindi : 'null'}
                  </td>
                  <td className="managewords__td">
                    {modLang === 'mun'
                      ? word.word.mundariInEnglish
                      : modLang === 'kur'
                      ? word.word.kurukInEnglish
                      : modLang === 'san'
                      ? word.word.santhaliInEnglish
                      : modLang === 'hoc'
                      ? word.word.hoInEnglish
                      : modLang === 'khr'
                      ? word.word.khariaInEnglish
                      : modLang === 'bhb'
                      ? word.word.bhiliInEnglish
                      : modLang === 'gon'
                      ? word.word.gondiInEnglish
                      : modLang === 'tcy'
                      ? word.word.tuluInEnglish
                      : 'null'}
                  </td>
                  <td className="managewords__td">
                    {modLang === 'mun'
                      ? word.word.mundariInHindi
                      : modLang === 'kur'
                      ? word.word.kurukInHindi
                      : modLang === 'san'
                      ? word.word.santhaliInHindi
                      : modLang === 'hoc'
                      ? word.word.hoInHindi
                      : modLang === 'khr'
                      ? word.word.khariaInHindi
                      : modLang === 'bhb'
                      ? word.word.bhiliInHindi
                      : modLang === 'gon'
                      ? word.word.gondiInHindi
                      : modLang === 'tcy'
                      ? word.word.tuluInHindi
                      : 'null'}
                  </td>
                  <td className="managewords__td">{word.word.category}</td>
                  <td className="managewords__td">
                    {word.word.moderator ? word.word.moderator.email : 'null'}
                  </td>
                  <td className="managewords__td">{word.reason}</td>
                  <td className="managewords__td">{word.user.email}</td>
                  <td className="managewords__td">{word.comment}</td>
                  <td
                    className="managewords__td"
                    style={{
                      flexDirection: 'column',
                    }}
                  >
                    <Link to={`/moderator/edit-word/${word.word._id}`}>
                      <button
                        style={{
                          backgroundColor: '#fffff',
                        }}
                        type="button"
                      >
                        Edit
                      </button>
                    </Link>
                    <button
                      style={{
                        backgroundColor: '#fffff',
                      }}
                      type="button"
                      onClick={() => {
                        setMessage('');
                        axios
                          .post(
                            `${config.REACT_APP_NODE_API_URL}/api/admin/mod/reports/delete`,
                            {
                              id: word._id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${token}`,
                              },
                            }
                          )
                          .then(res => {
                            console.log(res.data.msg);
                            setMessage(res.data.msg);
                          })
                          .catch(console.error);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>No reports (yet)...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageReports;
