import React, {Fragment, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import axios from 'axios';
import config from '../../config';
import ProgressGraph from '../../components/ProgressGraph';
import Achievements from '../../components/Achievements';
import LoggedInFooter from '../../layout/LoggedInFooter';
import {MdEdit, MdCheckCircle} from 'react-icons/md';

const Profile = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [lang, setLang] = useState('');
  const [created, setCreated] = useState('');
  const [isVerified, setVerified] = useState(false);
  useEffect(() => {
    const id = JSON.parse(localStorage.getItem('currentUser'))._id;
    axios({
      method: 'get',
      url: `${config.REACT_APP_NODE_API_URL}/api/user/${id}`,
    })
      .then(res => {
        setName(res.data.data.name);
        setEmail(res.data.data.email);
        setLang(res.data.data.languages[0].split('-')[1]);
        setVerified(res.data.data.isAccountActive);
        const c = new Date(res.data.data.createdAt);

        setCreated(
          `Joined: ${c.toLocaleString('default', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}`
        );
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <Fragment>
      <section className="profile">
        <div className="profile-settings">
          <div className="profile-form">
            <div className="profile-form-heading">
              <div className="profile-heading-content">
                <div className="profile-form-icon">
                  <img
                    src="https://media.giphy.com/media/3o7WINACqswANkAaZi/giphy.gif"
                    alt="your profile"
                  />
                </div>
              </div>
              <div className="profile-form-content">
                <h1>
                  {name}
                  {isVerified ? (
                    <span className="verified-icon">
                      <MdCheckCircle />
                    </span>
                  ) : null}
                </h1>
                <h2>{email}</h2>
                <h2>Learning: {lang === 'mun' ? 'Mundari' : ''}</h2>
                <h2>{created}</h2>
                <Link to="/account">
                  <div className="edit">
                    <span className="edit-icon">
                      {' '}
                      <MdEdit />
                    </span>
                    <span>Edit profile</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className="statistics">
              <div className="statistics-show">
                <div className="statistics-card">
                  <Achievements />
                </div>
                <div className="statistics-card">
                  <ProgressGraph width={50} height={30} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LoggedInFooter />
    </Fragment>
  );
};

const mapStateToProps = state => {
  const data = JSON.parse(localStorage.getItem('currentUser'));
  const id = data && data._id;

  return {
    id,
    dataSavedMessage: state.user.message,
  };
};

export default connect(mapStateToProps)(Profile);
