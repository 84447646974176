import React, {Fragment, useState} from 'react';
import {GoPrimitiveDot} from 'react-icons/go';
import {GrInstagram} from 'react-icons/gr';
import {Link} from 'react-router-dom';

const Footer = () => {
  const [email, setEmail] = useState('');
  return (
    <Fragment>
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-info">
            <h1>Trilingo</h1>
            <p>
              At Trilingo, we are creating easy to use, and effective
              mother-tongue based learning solutions to help tribal student
              overcome language barrier. Our platform bridges the gap between
              tribal communities and the modern economic infrastructure.
              <br />
              We are on a mission to organize world{"'"}s indigenous language
              and make it universally accessible and usable.
              <br />
              <br />
              &copy; {new Date().getFullYear()} Trilingo
              <br />
              All Rights Reserved {new Date().getFullYear()}
            </p>
          </div>
          <div className="footer-links">
            <div className="footer-links-container">
              <div className="important-links">
                <h2>IMPORTANT LINKS</h2>
                {/* 1 */}
                <div className="important-links-progress">
                  <div
                    style={{width: '30%', marginRight: '1rem'}}
                    className="progress"
                  >
                    <div style={{width: '100%'}} className="progress-bar"></div>
                  </div>

                  {/* 2 */}
                  <div className="progress">
                    <div style={{width: '10%'}} className="progress-bar"></div>
                  </div>
                </div>
                <Link to="/about">
                  <p>
                    <span className="link-dot-icon">
                      <GoPrimitiveDot />
                    </span>
                    About
                  </p>
                </Link>
                <Link to="/privacy-policy">
                  <p>
                    <span className="link-dot-icon">
                      <GoPrimitiveDot />
                    </span>
                    Privacy Policy
                  </p>
                </Link>
                <Link to="/terms-and-conditions">
                  <p>
                    <span className="link-dot-icon">
                      <GoPrimitiveDot />
                    </span>
                    Terms and Conditions
                  </p>
                </Link>
                <Link to="/contact">
                  <p>
                    <span className="link-dot-icon">
                      <GoPrimitiveDot />
                    </span>
                    Contact
                  </p>
                </Link>
              </div>
              <div className="newsletter">
                <h2>NEWS LETTER</h2>
                <div className="newsletter-progress">
                  <div
                    style={{width: '15%', marginRight: '1rem'}}
                    className="progress"
                  >
                    <div style={{width: '100%'}} className="progress-bar"></div>
                  </div>

                  {/* 2 */}
                  <div style={{width: '100%'}} className="progress">
                    <div style={{width: '10%'}} className="progress-bar"></div>
                  </div>
                </div>
                <p>Enter your email address for our mailing list</p>
                <h3>ENTER YOUR EMAIL</h3>
                <form>
                  <input
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="Ex: example@gmail.com"
                  />
                  <br />
                  <button className="subscribe-btn">SUBSCRIBE</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-social-media">
          <div className="footer-social-media-content">
            <a
              href="https://alfredtechnologies.in/"
              style={{color: '#f99529'}}
              target="_blank"
              rel="noreferrer"
            >
              Alfred Technologies (OPC) Pvt. Ltd.
            </a>
            <div className="social-media-links">
              <a
                href="https://www.instagram.com/trilingo.in/"
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  <GrInstagram />
                </span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
